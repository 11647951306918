const JSONAPISerializer = require('jsonapi-serializer').Serializer;

module.exports.VariantSerializer = new JSONAPISerializer('variant', {

    attributes: ['language', 'activity', 'type', 'limitedVacancy', 'name', 'description', 'duration', 'maxParticipants',
        'cutoffPeriod', 'directBooking', 'location', 'priceCategories', 'translations', 'published'],
    transform: (record) => {
        record.activity = record.activity.id;
        return record;
    }
})