import React from 'react';
import { Grid, Typography, Button, IconButton } from '@material-ui/core';
import Paginator from './Paginator';
import { makeStyles, useTheme } from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';
import { formStyles } from "../../Styles/forms";

const useStyles = makeStyles(theme => ({
  ...formStyles(theme),
  reloadButton: {
    marginLeft: 6,
    marginRight: 6
  },
  checked: {}
}));

const ListHeader = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Grid container style={{ marginTop: 10, marginBottom: 10 }}>
      <Grid item xs={6}>
        <Grid container alignItems="center" style={{ width: '100%', height: '100%' }} >
          <Typography variant="h6">{props.title}</Typography>
        </Grid>
      </Grid>

      <Grid item xs={6}>
        <Grid container alignItems="center" justify="flex-end" style={{ width: '100%' }}>
          {
            props.onReload ?
              <IconButton
                // className={classes.reloadButton}
                // classes={{ root: classes.reloadButton }}
                style={{ marginLeft: 6, marginRight: 6 }}
                size="small"
                onClick={props.onReload}
              >
                <ReplayIcon color="secondary" fontSize="large" />
              </IconButton> : undefined
          }
          {
            props.showPaginator ?
              <Paginator
                page={props.page}
                maxPage={props.maxPage}
                onBack={() => { props.setPage(props.page - 1) }}
                onForward={() => { props.setPage(props.page + 1) }}
              />
              : <React.Fragment />
          }
          {props.hideNew ?
            <React.Fragment /> :
            <Button
              onClick={props.onNew}
              classes={{ root: classes.button, disabled: classes.disabledButton }}
            // classes={{ disabled: classes.disabledButton }}
            >
              {props.newButtonLabel}
            </Button>}

        </Grid>
      </Grid>
      {/* <Grid item xs={9}>

            </Grid>
            <Grid item xs={3} >
                {
                    props.showPaginator ?
                        <Paginator
                            page={page}
                            maxPage={5}
                            onBack={() => { setPage(page - 1) }}
                            onForward={() => { setPage(page + 1) }}
                        />
                        : <React.Fragment />
                }
            </Grid> */}
    </Grid>
  )
}

export default ListHeader;