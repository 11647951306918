import React from "react";

import GOFormikTextInput from "../../Modules/Forms/GOFormikTextInput";
import { Field } from "formik";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import "moment/locale/de";
import ContactFormUI from "../Partials/ContactFormUI";
import InfoTag from "../../Modules/InfoTag";
import { Prompt } from "react-router-dom";
import { validationConstants } from './validation';
import PartnerState from '../../Modules/PartnerState';
import GOFormikPhoneInput from '../../Modules/Forms/GOFormikPhoneInput';

//TODO: Fix locale
moment.locale("de");



const ProviderFormUI = props => {
  const { t } = useTranslation();

  const countryIso =
    props.values.contact.country !== ""
      ? String(props.values.contact.country).toLowerCase()
      : "de";
  return (
    <React.Fragment>
      <Prompt
        when={props.dirty}
        message={location => t('forms.generic.messages.unsavedData')}
      />
      <Grid container>

        <Grid item xs={12}>
          <Field
            name="name"
            as={GOFormikTextInput}
            labelText={t("forms.generic.name")}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name={`translations.${props.contentLanguage}.description`}
            as={GOFormikTextInput}
            labelText={`${t("generic.description")}`}
            flag={props.contentLanguage}
            variant="outlined"
            multiline
            rowsMax={4}
            rows={4}
            maxChars={validationConstants.description.max}
          />
        </Grid>

        <ContactFormUI />
        <Grid item xs={12}>
          <Field
            name="contact.web"
            as={GOFormikTextInput}
            labelText={t("forms.contact.web")}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">
            {t("forms.provider.messaging")}

            <InfoTag title="Info" message={t('forms.provider.info.messaging')} />

          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="messaging.email"
            as={GOFormikTextInput}
            labelText={t("forms.contact.email")}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name="messaging.sms"
            as={GOFormikPhoneInput}
            labelText={t("forms.contact.sms")}
            countryCode={countryIso}
          />
        </Grid>

        {/* <Typography>{props.values.bookable === true ? 'true' : 'false'}</Typography>
        <Typography>{props.values.conditions.percentage} %</Typography>
        <Typography>{(props.values.conditions.fixed / 100).toFixed(2)} EUR</Typography> */}


      </Grid>
    </React.Fragment>
  );
};

export default ProviderFormUI;
