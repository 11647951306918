import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import ProviderFormUI from "./ProviderFormUI";
import { DataContext } from "../../Context/DataContext";
import { Typography, Container, Grid, LinearProgress } from "@material-ui/core";

import { useTheme, makeStyles } from "@material-ui/core/styles";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import { formStyles } from "../../Styles/forms";
import BreadCrumbs from "../../Modules/Nav/BreadCrumbs";
import GOSnackBar from "../../Modules/Forms/GOSnackBar";
import config from '../../Config/config';
import { makeLanguageFields } from '../../Modules/Forms/FormHelper';
import FormHeader from '../Partials/FormHeader';
import FormFooter from '../Partials/FormFooter';
import AlertDialog from '../../Modules/AlertDialog';
import { ActyvystAPIError } from '../../API/APIError';

import providerValidationSchema from './validation';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  ...formStyles(theme),
}))

const ProviderForm = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();
  // const [language, setLanguage] = useState(config.defaultContentLanguage);

  const { Provider } = useContext(DataContext);
  const initialData = {
    id: "new",
    name: "",
    description: "",
    published: false,
    contact: {
      firstname: "",
      lastname: "",
      country: "",
      zip: "",
      phone: "",
      web: "",
      email: "",
      street: ""
    },
    bookable: false,
    conditions: {
      percentage: config.defaultConditions.percentage,
      fixed: config.defaultConditions.fixed
    },
    messaging: {
      email: "",
      sms: ""
    },
    translations: {
      ...makeLanguageFields({ description: '', published: false })
    }
  };

  const createFieldObject = (object, val) => {
    const fields = Object.entries(object).reduce((acc, [key, value]) => {
      if (typeof value === 'object') {
        // if (Array.isArray(value)) {
        //   return { ...acc, [key]: val }
        // } else {
        return { ...acc, [key]: createFieldObject(value, val) }
        // }
      } else {
        return { ...acc, [key]: val }
      }
    }, {});
    return fields;
  }


  const [showSaveStatus, setShowSaveStatus] = useState({
    open: false,
    message: "",
    variant: ""
  });

  const [providerData, setProviderData] = useState({ ...initialData });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState({
    title: t('forms.generic.messages.defaultAlertTitle'),
    message: '',
    buttons: [{ label: 'generic.ok', value: true, default: true }],
    callback: (value) => { alert(value); setAlertOpen(false) }
  });

  const validationSchema = providerValidationSchema(props.language || 'de', t);

  useEffect(() => {
    setIsLoading(false);
    // debugger;
    if (props.id === "new") {
      setIsLoading(false);
      setProviderData({ ...initialData });
    } else {
      const fetchData = async () => {
        try {
          setIsLoading(true);
          const p = await Provider.fetchItem(props.id);
          const newProvider = {
            ...initialData,
            ...p
          };
          setProviderData(newProvider);
          setError(null);
        } catch (err) {
          setError(err.message);
        } finally {
          setIsLoading(false);
        }
      };
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id, props.language]);

  const dismissSaveMessage = () => {
    setShowSaveStatus({ open: false, message: "", variant: "" });
  };
  const handleSubmit = (values, formikActions) => {
    const createItem = async () => {
      try {
        setIsLoading(true);
        const newProvider = await Provider.createItem({
          ...values
        });
        setProviderData(newProvider);
        setError(null);
        setShowSaveStatus({
          open: true,
          message: t("forms.generic.messages.success"),
          variant: "success"
        });
      } catch (err) {
        console.log(err);
        setShowSaveStatus({
          open: true,
          message: t("forms.generic.messages.error") + err,
          variant: "error"
        });
      } finally {
        setIsLoading(false);
      }
    };
    const updateItem = async () => {
      try {
        setIsLoading(true);
        const providerUpdate = await Provider.updateItem({
          ...values
        });
        setProviderData(providerUpdate);
        setError(null);

        setShowSaveStatus({
          open: true,
          message: t("forms.generic.messages.success"),
          variant: "success"
        });
      } catch (err) {
        console.log('HERE WE GO');
        console.log(err.code);

        setAlertContent({
          ...alertContent,
          message: `${t('forms.generic.messages.saveFailed')}\n
          HTTP-Status: ${err.status}\n
          Error-Code: ${err.code}`,
          title: t('forms.generic.messages.saveFailedTitle'),
          callback: (value) => { setAlertOpen(false); }
        });
        setAlertOpen(true);
        // setShowSaveStatus({
        //   open: true,
        //   message: t("forms.generic.messages.error") + err,
        //   variant: "error"
        // });
      } finally {

        setIsLoading(false);
      }
    };
    if (values.id === "new") {
      createItem();
    } else {
      updateItem();
    }
  };

  return (
    <Container>

      <Grid container
        justify="flex-start"
        alignItems="flex-start"
        className={classes.formContainer}
      >
        <Grid item xs={12}>
          {isLoading === true ? (
            <React.Fragment>
              <Typography>
                <LinearProgress color="secondary" style={{ width: "50%" }} />
              </Typography>
            </React.Fragment>

          ) : error ? (
            <Typography>{error}</Typography>
          ) : (
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    ...providerData
                  }}
                  onSubmit={handleSubmit}
                  validationSchema={validationSchema}
                  validateOnBlur={true}
                  validateOnChange={false}
                  render={(formikProps) => {
                    const onLanguageChange = (newLanguage) => {
                      if (props.id === 'new') {
                        setAlertContent({
                          ...alertContent,
                          message: t('forms.generic.messages.initialSaveBeforeLanguageChange'),
                          title: t('forms.generic.messages.initialSaveBeforeLanguageChangeTitle'),
                          callback: (value) => { setAlertOpen(false); }
                        });
                        setAlertOpen(true);
                      } else {
                        history.push({ pathname: `/providers/${props.id}`, search: `language=${newLanguage}` });
                      }
                    }
                    const onSave = () => {
                      formikProps.validateForm()
                        .then(() => {
                          if (!formikProps.isValid) {
                            formikProps.setTouched(createFieldObject(initialData, true), true);
                            setShowSaveStatus({
                              open: true,
                              message: t("forms.generic.messages.validationError"),
                              variant: "error"
                            });
                          } else {
                            formikProps.handleSubmit();
                          }
                        }).catch((err) => {
                          console.log('onSave ERROR');
                          formikProps.setTouched(createFieldObject(initialData, true), true);
                          setShowSaveStatus({
                            open: true,
                            message: t("forms.generic.messages.saveError", { error: err.message }),
                            variant: "error"
                          });
                        })
                      // formikProps.handleSubmit();
                    }
                    return (
                      <Form>
                        <FormHeader
                          {...formikProps}
                          title={props.id === "new"
                            ? t("forms.provider.new")
                            : t("forms.provider.edit")}
                          language={props.language}
                          onLanguageChange={onLanguageChange}
                          onSave={onSave}
                        />
                        <ProviderFormUI
                          {...formikProps}
                          contentLanguage={props.language}
                        />
                        <Grid item xs={12}>
                          <FormFooter
                            {...formikProps}
                            onSave={onSave}
                          />
                        </Grid>
                        <AlertDialog
                          open={alertOpen}
                          title={alertContent.title}
                          message={alertContent.message}
                          buttons={alertContent.buttons}
                          callback={alertContent.callback}
                        />
                      </Form>
                    )
                  }}

                />
              )}

          <GOSnackBar
            open={showSaveStatus.open}
            messagetext={showSaveStatus.message}
            closeHandle={dismissSaveMessage}
            variant={showSaveStatus.variant}
          />
        </Grid>

      </Grid>
    </Container >
  );
};

ProviderForm.propTypes = {
  id: PropTypes.string,
  language: PropTypes.string
};

ProviderForm.defaultProps = {
  id: "new",
  language: config.defaultContentLanguage
};

export default ProviderForm;
