export const userReducer = (state, action) => {
    switch (action.type) {
        case 'SET_IS_INITIALIZED':
            return {
                ...state,
                isInitialized: action.payload
            }
        case 'LOGOUT':
            return {
                ...state,
                isLoggingIn: false,
                loginError: null,
                token: null,
                refreshToken: null,
            }
        case 'SET_TOKENS':
            return {
                ...state,
                loginError: null,
                isLoggingIn: false,
                token: action.payload.token,
                refreshToken: action.payload.refreshToken,
            }

        case 'SET_IS_LOGGING_IN':
            return {
                ...state,
                loginError: null,
                isLoggingIn: action.payload
            }
        case 'SET_LOGIN_ERROR':
            return {
                ...state,
                isLoggingIn: false,
                token: null,
                refreshToken: null,
                loginError: action.payload
            }
        case 'SET_LAST_ACTIVITY_TIMESTAMP':
            return {
                ...state,
                lastActivityTimestamp: action.payload
            }
        case 'SET_AXIOS_INTERCEPTOR':
            {
                return {
                    ...state,
                    axiosInterceptor: action.payload
                }
            }
        case 'SET_TEST':
            {
                return {
                    ...state,
                    test: action.payload
                }
            }
        default:
            return { ...state }
    }
}