import gtc from './gtc/de';
const de = {
    translation: {
        generic: {
            yes: 'Ja',
            no: 'Nein',
            ok: 'Ok',
            name: "Name",
            firstName: "Vorname",
            lastName: "Nachname",
            email: "E-Mail",
            sms: "SMS",
            pushNotification: "Push-Nachrichten",
            password: "Passwort",
            activity: "Aktivität",
            activity_plural: "Aktivitäten",
            // activityWithCount: "{{count}} Aktivität",
            // activityWithCount_plural: "{{count}} Aktivitäten",
            variant: "Angebot",
            variant_plural: "Angebote",
            activities: "Aktivitäten",
            variants: "Angebote",
            category: "Kategorien/Tags",
            provider: "Anbieter",
            provider_plural: "Anbieter",
            user: "Benutzer",
            user_plural: "Benutzer",
            booking: "Buchung",
            event: "Event",
            event_plural: "Events",
            booking_plural: "Buchungen",
            location: "Adresse",
            contact: "Kontakt/Ansprechpartner",
            price: "Preis",
            currency: "Währung",
            slot: "Termin",
            date: "Datum",
            edit: "Bearbeiten",
            save: "Speichern",
            cancel: "Abbrechen",
            delete: "Löschen",
            close: "Schließen",
            description: "Beschreibung",
            web: 'Website',
            country: "Land",
            countries: {
                de: "Deutschland",
                fr: "Frankreich",
                nl: "Niederlande",
                gb: "Großbritannien",
                it: "Italien",
                es: "Spanien",
                be: "Belgien",
                ch: "Schweiz",
                pt: "Portugal",
                lu: "Luxemburg",
                at: "Österreich"
            },
            languages: {
                de: "Deutsch",
                en: "Englisch",
                fr: "Französisch",
                es: "Spanisch"
            },
            error: 'Leider ist ein unerwarteter Fehler aufgetreten.'
        },
        navbar: {
            home: 'Home',
            profile: 'Profil',
            login: 'Login',
            events: 'Events & News',
            categories: 'Kategorien',
            providers: 'Anbieter',
            activities: 'Aktivitäten',
            campaigns: 'Kampagnen',
            variants: 'Angebote & Preise',
            calendar: 'Kalender',
            bookings: 'Buchungen',
            notLoggedIn: 'Nicht eingeloggt',
            providerApplications: 'Registrierungen',
            callcenter:'Callcenter'
        },
        email: "E-Mail",
        password: "Passwort",
        activity: "Aktivität",
        variant: "Angebot",
        startDate: "Startdatum",
        date: "Datum",
        endDate: "Enddatum",
        dayRange: "mehrtägig",
        dailyStartTime: "Tägl. Anfangszeit",
        startTime: "Anfangszeit",
        dailyEndTime: "Tägl. Ende",
        endTime: "Ende",
        published: "Öffentlich",
        save: "Speichern",
        pickerDateFormat: "DD.MM.YY",
        tables: {
            generic: {
                actions: 'Aktionen',
                state: 'Status'
            }
        },
        forms: {
            generic: {
                name: "Name",
                published: "Veröffentlichen",
                languageActive: "Sprache in App verwenden",
                validations: {
                    name: "Bitte geben Sie eine Bezeichnung ein."
                },
                messages: {
                    success: "Daten wurden erfolgreich gespeichert.",
                    saveError: "Fehler beim Speichern: {{error}}",
                    deleteErrorTitle: 'Fehler beim Löschen',
                    saveFailedTitle: "Fehler beim Speichern",
                    saveFailed: `Beim Speichern ist leider ein technischer Fehler aufgetreten. Bitte prüfen Sie Ihre Internetverbindung und versuchen es erneut. 
          Sollte der Fehler weiterhin auftreten, wenden Sie sich bitte an den actyvyst Support.`,
                    validationError: "Fehler beim Speichern: Bitte prüfen Sie die markierten Felder.",
                    unsavedData: "Sie haben Ihre Änderungen noch nicht gespeichert. Wollen Sie dieses Formular wirklich verlassen, ohne die Daten zu speichern?",
                    unsavedDataTitle: "Formular verlassen?",
                    confirmDeleteTitle: "Löschen bestätigen",
                    defaultAlertTitle: "Meldung",
                    stateChangedWarningTitle: "Statusänderung bestätigen",
                    changePublishedStateToTrue: `Wenn Sie den Status auf "Veröffentlichen" setzen, werden Ihre Daten in der actyvyst App für Kunden sichtbar.
          Stellen Sie sicher, dass Ihre Eingaben in allen von Ihnen als "aktiv" markierten Sprachen vollständig sind, bevor Sie diese Änderung vornehmen.
          Möchten Sie fortfahren und Ihre Daten veröffentlichen?`,
                    changePublishedStateToFalse: `Wenn Sie den Status auf "Nicht veröffentlichen" setzen, sind Ihre Daten nicht mehr in der actyvyst App sichtbar.
          Möchten Sie den Veröffentlichungsstatus wirklich ändern?`,
                    changeLanguagePublishedStateToTrue: `Wenn Sie den Status für die gewählte Sprache auf "In App verwenden" setzen, 
          werden Ihre Daten den Endkunden in der actyvyst App in dieser Sprache angezeigt. Aktivieren Sie den Status nur,
          wenn Ihre Daten für die ausgewählte Sprache vollständig sind.
          Möchten Sie den Status wirklich auf "In App verwenden" setzen?`,
                    changeLanguagePublishedStateToFalse: `Wenn Sie den Status "In App verwenden" für die ausgewählte Sprache deaktivieren, wird diese Sprache Ihren Kunden
          in der actyvyst App nicht mehr angezeigt. Sind Sie sicher, dass Sie den Status für die ausgewählte Sprache deakivieren möchten?`,
                    initialSaveBeforeLanguageChangeTitle: `Datensatz speichern`,
                    initialSaveBeforeLanguageChange: `Bitte speichern Sie Ihre Daten initial für die aktuell eingestellte Sprache, bevor Sie eine andere Sprache wählen.`
                },
                info: {
                    published: `Wählen Sie "Veröffentlichen", um Ihre Daten in der actyvyst App für Ihre Kunden sichtbar zu machen.`,
                    languagePublished: `Wählen Sie "Sprache in App verwenden", wenn die ausgewählte Sprache in der actyvyst App angezeigt werden soll.`
                }
            },
            profile: {
                actions: {
                    change: 'Ändern',
                    logout: 'Abmelden'
                },
                role: "Rolle",
                adminRole: "Administrator",
                providerRole: "Anbieter",
                salutation: "Hallo {{firstname}} {{lastname}}.",
                password: "Passwort",
            },
            category: {
                new: 'Neue Kategorie',
                edit: 'Kategorie bearbeiten'
            },
            location: {
                country: "Land",
                city: "Stadt",
                zip: "PLZ",
                street: "Straße und Hausnr."
            },
            booking: {
                actions: {
                    confirm: 'Bestätigen',
                    reject: 'Ablehnen'
                },
                validation: {
                    required: 'Eingabe erforderlich.',
                    max: 'Dieses Feld darf maximal {{max}} Zeichen enthalten.'
                },
                confirmMessageTitle: 'Buchung bestätigen',
                confirmMessage: 'Wollen Sie die Buchung bestätigen? Der Kunde wird per Mail und App benachrichtigt und erhält ein elektronisches Ticket.',
                cancelMessageTitle: 'Anfrage ablehnen',
                cancelMessage: 'Wollen Sie die Anfrage wirklich ablehnen? Der Kunde wird per Mail und App benachrichtigt, seine Zahlung wird storniert.',
                rejectReason: 'Bitte nennen Sie einen Grund für die Ablehnung',
                request: 'Buchungsanfrage',
                booker: 'Kunde',
                participants: 'Teilnehmer',
                price: 'Preis',
                status: 'Status',
                created: 'Wartet auf Zahlung',
                confirmed: 'Bestätigt',
                confirmationRequired: 'Bestätigung erforderlich',
                reservation_only: 'Reservierung',
                rejected: 'Abgelehnt',
                cancelled: 'Storniert',
                timestamp: 'Buchungszeitpunkt',
                slotStart: 'Gebuchter Termin',
                numParticipants: 'Anzahl Personen'
            },
            contact: {
                country: "Land",
                city: "Stadt",
                zip: "PLZ",
                street: "Straße und Hausnr.",
                firstName: "Vorname",
                lastName: "Nachname",
                email: "E-Mail",
                phone: "Telefonnummer",
                web: "Website",
                sms: "SMS",
                validations: {
                    country: {
                        req: "Bitte geben Sie ein Land ein."
                    },
                    city: {
                        req: "Bitte geben Sie Ihren Wohnort ein.",
                        min: "Bitte geben Sie mindestens 2 Zeichen ein.",
                        max: "Bitte geben Sie max. 50 Zeichen ein."
                    },
                    zip: {
                        req: "Bitte geben Sie eine gültige Postleitzahl ein.",
                        min: "Die Postleitzahl muss mindestens 4 Ziffern beinhalten.",
                        max: "Die Postleitzahl darf 4-5 Ziffern enthalten."
                    },
                    street: {
                        req: "Bitte geben Sie den Straßennamen und die Hausnummer ein",
                        min:
                            "Ihr Straßenname und die Hausnr. müssen mindestens 5 Zeichen enthalten.",
                        max:
                            "Ihr Straßenname und die Hausnr. dürfen max. 50 Zeichen enthalten."
                    },
                    firstname: {
                        req: "Bitte geben Sie Ihren Vornamen ein.",
                        min: "Der Vorname muss mindestens 2 Zeichen enthalten.",
                        max: "Der Vorname darf max. 50 Zeichen enthalten."
                    },
                    lastname: {
                        req: "Bitte geben Sie Ihren Nachnamen ein.",
                        min: "Der Nachname muss mindestens 2 Zeichen enthalten.",
                        max: "Der Nachname darf max. 50 Zeichen enthalten."
                    },
                    email: {
                        req: "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
                        format: "Bitte geben Sie eine gültige E-Mail-Adresse ein."
                    }
                    // phone: {
                    //   req: "Bitte geben Sie eine gültige Telefonnummer ein.",
                    //   min: "Die Telefonnummer muss mindestens 5 Zahlen enthalten.",
                    //   max: "Die Telefonnummer darf 5-15 Zahlen enthalten."
                    // }
                }
            },
            activity: {
                teaser: "Teaser",
                hygiene: "Hygienekonzept",
                info: "Info",
                tags: "Tags",
                new: "Neue Aktivität",
                edit: "Aktivität bearbeiten",
                newTag: "Tags hinzufügen",
                newInfo: "Infos hinzufügen",
                deleteImageMessage: {
                    title: 'Bild löschen?',
                    message: 'Möchten Sie das ausgewählte Bild wirklich löschen?'
                },
                deleteMessage: {
                    title: 'Aktivität löschen?',
                    message: `Möchten Sie die ausgewählte Aktivität wirklich löschen? 
          Angebote und Termine, die sich auf diese Aktivität beziehen, werden ebenfalls gelöscht.
          Dieser Vorgang kann nicht rückgängig gemacht werden.`
                },
                deleteError: {
                    title: 'Aktivität konnte nicht gelöscht werden.',
                    message: `Die Aktivität konnte nicht gelöscht werden, weil bereits Buchungen auf diese Aktivität erfolgt sind.
          Wenn die Aktivität in der App nicht mehr erscheinen soll, können Sie das Feld "In App sichtbar" für diese Aktivität deaktivieren.`
                },
                images: 'Bilder',
                deleteImageButton: 'Bild löschen',
                changeImageButton: 'Bild ändern',
                imageMoveRightButton: 'Nach rechts',
                imageMoveLeftButton: 'Nach links',
                uploadImageButton: 'Bild hochladen',
                validation: {
                    min: 'Bitte geben Sie mindestens {{min}} Zeichen ein.',
                    max: 'Dieses Feld darf maximal {{max}} Zeichen enthalten.',
                    required: 'Eingabe erforderlich.',
                    minTags: 'Bitte wählen Sie mindestens {{minTags}} Kategorie aus.',
                    maxTags: 'Sie können dem Angebot maximal {{maxTags}} Kategorien zuordnen',
                    maxInfos: 'Sie können maximal {{maxInfos}} Infozeilen eingeben'
                }
            },
            variant: {
                actions: {
                    new: "Neues Angebot",
                    edit: "Angebot bearbeiten",
                },
                messages: {
                    confirmDelete: `Möchten Sie das ausgewählte Angebot wirklich löschen? 
          Termine, die sich auf dieses Angebot beziehen, werden ebenfalls gelöscht.
          Diese Aktion kann nicht rückgängig gemacht werden!`
                },
                errors: {
                    deleteFailed: `Das Angebot konnte nicht gelöscht werden, weil bereits Buchungen auf das Angebot erfolgt sind.
          Wenn das Angebot in der App nicht mehr erscheinen soll, können Sie das Feld "In App sichtbar" für dieses Angebot deaktivieren.`
                },
                validation: {
                    price: `Bitte geben Sie einen Preis ein`,
                    allowedParticipants: `Bitte geben Sie mindestens eine Person ein.`,
                    duration: `Bitte geben Sie eine Dauer ein.`,
                    maxParticipants: `Bitte geben Sie eine maximale Teilnehmerzahl ein.`,
                    name: `Bitte geben Sie einen Namen für dieses Angebot ein`,
                    description: `Bitte geben Sie eine Kurzbeschreibung ein`,
                    categoryName: `Bitte geben Sie einen Namen ein`,
                    categoryDescription: `Bitte geben Sie eine Kurzbeschreibung ein`
                },
                type: 'Angebotstyp',
                details: 'Details',
                fixedSlot: 'Feste Termine',
                variableSlot: 'Termine auf Anfrage',
                resource: 'Ressource / Vermietung',
                limitedVacancy: 'Vakanz prüfen',
                bookingOnRequest: 'Buchung auf Anfrage',
                duration: "Dauer (Minuten)",
                maxParticipants: "Max Teilnehmer",
                cutoffPeriod: "Buchungsstopp ab (Minuten)",
                newPriceCategory: "Eintrag hinzufügen",
                priceList: "Preisliste",
                allowedParticipants: "Anzahl Teilnehmer",
                deeplink: "Deeplink",
                queryFromDate: "Query-Parameter Startdatum",
                jscode: "JavaScript"
            },
            slot: {
                actions: {
                    new: "Neuen Termin anlegen",
                    edit: "Termin bearbeiten",
                    deleteBooking: "Buchung wirklich löschen?"
                },
                messages: {
                    confirmDelete: "Möchten Sie den ausgewählten Termin wirklich löschen?",
                    emptyList: "Noch keine Buchungen vorhanden"
                },
                errors: {
                    deleteFailed: "Nur Termine ohne Buchungen können gelöscht werden"
                }
            },
            provider: {
                new: "Neuer Anbieter",
                edit: "Anbieter bearbeiten",
                actions: {
                    new: "Neuer Anbieter",
                    list: "Alle Anbieter"
                },
                info: {
                    messaging: `Geben Sie hier an, auf welchen Kanälen Sie von der actyvyst App über neue Buchungen oder Anfragen Ihrer Kunden informiert werden möchten.
          Die hier eingegebenen Kontaktdaten werden den Kunden nicht angezeigt, sondern nur zur Kommunikation zwischen der actyvyst App und Ihnen verwendet.`
                },
                address: "Adresse",
                contact: "Kontakt",
                messaging: "Systemnachrichten",
                devices: "Geräte",
                partnerStatus: "Partnerstatus"
            },
            event: {
                actions: {
                    new: "Neues Event",
                    edit: "Event bearbeiten"
                },
                startTime: "Beginn"
            },
            reservation: {
                actions: {
                    new: "Neue Reservierung",
                    edit: "Reservierung bearbeiten",
                    delete: "Reservierung löschen"
                },
                error: {
                    save: "Die Reservierung konnte leider nicht gespeichert werden."
                }
            },
            providerApplications: {
                title: 'Registrierungen',
                details: {
                    title: 'Anbieter-Anfrage',
                    name: 'Name',
                    description: 'Angebot',
                    web: 'Website',
                    address: 'Adresse',
                    contact: 'Kontakt',
                    connectWithProvider: 'Verknüpfen mit',
                    newProvider: 'Neuer Anbieter'

                },
                actions: {
                    confirm: 'Annehmen',
                    reject: 'Ablehnen'
                },
                confirmMessageTitle: 'Anfrage annehmen',
                rejectMessageTitle: 'Anfrage ablehnen',
                confirmMessage: 'Wollen Sie die Anfrage annehmen?',
                rejectMessage: 'Wollen Sie die Anfrage ablehnen?'
            },
            register: {
                title: 'Passwort festlegen',
                description: `<p><b>Herzlich willkommen beim actyvyst Anbieter Cockpit!</b></p><p>Bitte legen Sie nun das Passwort
        fest, mit dem Sie sich hier einloggen möchten. Verwenden Sie bitte mindestens 5 Zeichen und wiederholen Sie das Passwort
        zur Sicherheit noch einmal im unteren Feld.</p>
        Viel Erfolg wünscht Ihnen Ihr actyvyst Team.`,
                repeatPassword: 'Passwort wiederholen',
                actions: {
                    submit: 'Passwort festlegen'
                },
                validation: {
                    required: 'Dieses Feld ist erforderlich',
                    max: 'Bitte geben Sie maximal {{max}} Zeichen ein',
                    min: 'Bitte geben Sie mindestens {{min}} Zeichen ein',
                    confirmPassword: 'Die Passwörter stimmen nicht überein'
                },
                userIsProvider: {
                    title: 'Anbieterregistrierung',
                    description: `<p>Sie sind mit der E-Mail-Adresse <b>{{email}}</b> bereits als Anbieter bei actyvyst registriert.</p>
          <p>Um einen weiteren Anbieter zu registrieren, klicken Sie auf <b>Zur Anbieterregistrierung</b> und verwenden Sie bitte bei der Anmeldung eine andere E-Mail-Adresse.</p>
          <p>Um sich für den existierenden Anbieter anzumelden, klicken Sie bitte <b>Zum Login</b> - sollten Sie Ihr Passwort vergessen haben, können Sie es dort auch zurücksetzen.</p>
          `,
                    actions: {
                        login: 'Zum Login',
                        register: 'Zur Anbieterregistrierung'
                    }
                },
                success: {
                    title: 'Vielen Dank für Ihre Registrierung!',
                    description: `<p>Sie sind nun als Anbieter für actyvyst freigeschaltet - wählen Sie <b>Zum Login</b>
          und melden Sie sich mit Ihrer E-Mail-Adresse und Ihrem Passwort an.</p>
          <p>Viel Erfolg wünscht Ihnen Ihr actyvyst Team.</p>`
                }
            },
            login: {
                title: 'Login',
                error: 'Login fehlgeschlagen - bitte versuchen Sie es noch einmal.',
                actions: {
                    login: 'Anmelden'
                }
            },
            welcome: {
                title: 'Willkommen beim actyvyst Anbieter Cockpit',
                firstSteps: 'Erste Schritte',
                activities: {
                    title: 'Aktivitäten anlegen',
                    description: `<p>Legen Sie bis zu <b>fünf verschiedene Aktivitäten</b> an, die den actyvyst Nutzern in der App
          vorgestellt werden. Hinterlegen Sie Bilder und ordnen Sie Ihre Aktivitäten bis zu drei Kategorien zu, nach denen die
          App-Nutzer filtern können.</p>
          <p>Optional können Sie die <b>Hygiene-Maßnahmen</b> schildern, die Sie im Rahmen der Corona-Auflagen umsetzen. So können Sie Ihren
          Gästen schon im Vorfeld Sicherheit geben.</p>
          <p>Wenn Sie alle Einstellungen vorgenommen haben, <b>veröffentlichen Sie Ihre Aktivitäten</b> durch Aktivierung des Feldes
          "In App sichtbar".</p>
          <p><a href="/activities"><b>Zu den Aktivitäten...</b></a></p>`
                },
                variants: {
                    title: 'Angebote & Preise festlegen',
                    description: `<p>Als <b>buchbarer Anbieter</b> können Sie zu jeder Aktivität Angebote und Preise definieren,
          die die Kunden in der App buchen können. Wenn Ihre Aktivität zum Beispiel Wattwandern ist, können Sie hier
          verschiedene Touren anbieten und diese mit unterschiedlichen Preisen versehen.</p>
          <p>Hier können Sie auch festlegen, ob Kunden Ihre Aktivität <b>direkt buchen</b> können, oder ob Sie die
          <b>Buchung auf Anfrage</b> bevorzugen.</p>
          <a href="/variants"><b>Zur Angebotsverwaltung...</b></a>`
                },
                slots: {
                    title: 'Termine erstellen',
                    description: `<p>In der <b>Kalenderansicht</b> erstellen Sie Termine für Ihre verschiedenen Aktivitäten und Angebote.
          Den Kunden werden die Termine nach Auswahl eines Angebots zur Buchung angeboten.</p>
          <a href="/calendar"><b>Zum Kalender...</b></a>`
                },
                bookings: {
                    title: 'Buchungen verwalten',
                    description: `<p>Hier behalten Sie den Überblick über die Buchungen, die Ihre Kunden in der App vornehmen.</p>
          <p>Haben Sie <b>Buchung auf Anfrage</b> eingestellt, können Sie hier Buchungsanfragen Ihrer Kunden mit einem Klick bestätigen.</p>
          <a href="/bookings"><b>Zu den Buchungen...</b></a>
          `
                }
            },
            upgrade: {
                title: 'Machen Sie Ihr Angebot jetzt buchbar!',
                teaser: 'Generieren Sie Buchungen direkt aus der App',
                description: `<p>Sie möchten Ihre Angebote direkt aus der App heraus buchbar machen?</p>
        <p>Ihre Kunden bezahlen online und erhalten ein <b>digitales Ticket</b>, das Sie mit der
        <b>actyvyst Anbieter-App</b> bequem prüfen und entwerten können.</p>
        Bei <b>Buchungen auf Anfrage</b> reservieren wir den zu zahlenden Betrag zunächst auf der Kreditkarte
        des Kunden und ziehen ihn ein, sobald Sie die Anfrage bestätigen.`,
                button: 'Jetzt buchbar werden'
            },
            makeBookable: {
                title: 'Machen Sie Ihr Angebot jetzt buchbar!',
                intro: `Die <b>actyvyst App</b> ermöglicht es Ihren Kunden, Ihr Angebot direkt zu buchen und kontaktlos per <b>Kreditkarte,
        Apple Pay oder Google Pay</b> zu bezahlen. Im Folgenden erläutern wir Ihnen, wie das genau funktioniert und was Sie tun müssen,
        um als Anbieter von diesem Vertriebsweg zu profitieren. Bitte lesen Sie die Erläuterung aufmerksam durch und wählen dann
        <b>Jetzt buchbar werden</b> am Ende dieser Seite.
        `,
                actions: {
                    apply: 'Jetzt buchbar werden'
                },
                topics: {
                    slotsAndVariants: {
                        title: 'Termine und Preise',
                        description: `<p style="margin-top:0;margin-bottom:0.5em">Als buchbarer Anbieter können Sie zu jeder Aktivität mehrere <b>Angebote</b> mit Preisen hinterlegen.
            Für jedes Angebot können Sie außerdem verschiedene <b>Preiskategorien</b> (z.B. Erwachsene und Kinder) definieren.</p>
            <p style="margin-top:0;margin-bottom:0.5em">Im <b>Kalender</b> legen Sie im Anschluss Termine für Ihre Angebote fest, die von den Kunden gebucht werden können.</p>`
                    },
                    direct: {
                        title: 'Direkte Buchung',
                        description: `<p style="margin-top:0;margin-bottom:0.5em">Um Ihren Kunden die <b>direkte Buchung</b> Ihrer Angebote zu ermöglichen,
            ist es wichtig, dass Sie die Verfügbarkeiten Ihrer Angebote im Blick behalten, z.B. wenn Sie auch Buchungen auf anderen Kanälen (z.B. E-Mail, Telefon)
            entgegennehmen.</p>
            <p style="margin-top:0;margin-bottom:0.5em">Buchungen über die <b>actyvyst App</b> reduzieren die Verfügbarkeit automatisch,
            wenn ein Termin über einen anderen Kanal gebucht wird, können Sie dies ebenfalls über das Anbieter-Cockpit verwalten und manuell Buchungen aus anderen Kanälen im Kalender hinzufügen.<p>
            <p style="margin-top:0;margin-bottom:0.5em">Kunden bevorzugen die <b>direkte Buchung</b>, der Aufwand lohnt sich also!</p>`
                    },
                    onRequest: {
                        title: 'Buchung auf Anfrage',
                        description: `<p style="margin-top:0;margin-bottom:0.5em">Wenn Sie Ihre Verfügbarkeiten nicht pflegen möchten, können Sie für Ihre Angebote die Option <b>Buchung auf Anfrage</b> wählen.
            Sie erhalten dann eine Buchungsanfrage per Mail und können diese hier im Anbieter-Cockpit in der Buchungsansicht freigeben.</p>
            <p style="margin-top:0;margin-bottom:0.5em">In diesem Fall blockieren wir den Zahlungsbetrag zunächst auf der Kreditkarte des Kunden und ziehen
            ihn erst ein, wenn Sie die Buchung bestätigen.</p>`,
                    },
                    cancellation: {
                        title: 'Stornierungen',
                        description: `<p style="margin-top:0;margin-bottom:0.5em">Kunden legen großen Wert auf flexible Stornierungsbedingungen,
            in vielen Hotels können Zimmer z.B. bis 18:00 am selben Tag storniert werden.<p>
            <p style="margin-top:0;margin-bottom:0.5em">Buchungen in der <b>actyvyst App</b> können standardmäßig bis 24 h vor
            Veranstaltungsbeginn kostenfrei storniert werden - wir erstatten dem Kunden dann automatisch den gezahlten Betrag zurück
            und schicken Ihnen eine entsprechende Nachricht. Wenn Sie individuelle Stornierungsbedingungen wünschen,
            kontaktieren Sie uns bitte: <a href="mailto:contact@actyvyst.com">Mail an actyvyst Support</a></p>
            `
                    },
                    tickets: {
                        title: 'Digitale Tickets',
                        description: `<p style="margin-top:0;margin-bottom:0.5em">
            Wenn Kunden Ihre Angebote in der <b>actyvyst App</b> buchen, erhalten sie ein <b>digitales Ticket</b>, das sie
            Ihnen zeigen werden. Aus dem Ticket geht der gebuchte Termin und die gebuchte Leistung hervor. Sie können dieses
            Ticket mit der <b>actyvyst Anbieter App</b> (kostenloser Download im Apple App Store oder bei Google Play) scannen und prüfen.`
                    },
                    payment: {
                        title: 'Auszahlung',
                        description: `<p style="margin-top:0;margin-bottom:0.5em">
            Wir überweisen Ihnen jeweils zum Monatsende die Einnahmen aus allen Buchungen, deren zugehöriger Termin bereits stattgefunden hat.
            Unsere Buchungsprovision ziehen wir von diesem Betrag ab.
            Ebenfalls zum Monatsende erhalten Sie von uns per Mail eine Übersicht über alle getätigten Buchungen.
            </p>`
                    },
                    conditions: {
                        title: 'Buchungsgebühren',
                        description: `<p style="margin-top:0;margin-bottom:0.5em">
            Wir erheben eine variable Buchungsgebühr i.H.v. <b>{{percentage}}% des Buchungsvolumens</b>, die wir von Ihrem Auszahlungsbetrag abziehen.</p>`,
                        fixedFee: `<p>Zusätzlich fällt für jede Buchung eine fixe Buchungsgebühr i.H.v. <b>{{fixed}} {{currency}} an</b>.</p>`,
                    }
                },
                individualConditions: 'individuell ausgehandelt',
                toolTips: {
                    individualConditions: 'Sie sehen hier die individuell mit Ihnen verhandelten Konditionen. Unsere regulären Konditionen betragen {{percentage}}% des Buchungsvolumens.'
                },
                gtcConfirmation: `Ich erkläre mich mit den obigen Bedingungen und den <a href="/gtc" target="_blank">AGB</a> der actyvyst GmbH einverstanden`,
                confirmation: {
                    title: 'Glückwunsch - Sie sind nun über actyvyst buchbar!',
                    details: `Sie sind nun über die actyvyst Plattform buchbar - legen Sie Angebote & Termine zu Ihren Aktivitäten an,
          um von Ihren Kunden in der actyvyst App gefunden zu werden.`,
                    gotoCalendar: 'Zum Kalender',
                    gotoVariants: 'Zur Angebotsverwaltung'
                }
            }
        },
        errors: {
            genericMessage: "Es ist ein unerwarteter Fehler aufgetreten - bitte prüfen Sie Ihre Internetverbindung."
        },
        gtc
    },

};

export default de;
