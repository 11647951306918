import * as Yup from "yup";

export const ValidationConstants = {
    name: {
        min: 3,
        max: 50
    },
    description: {
        min: 0,
        max: 200
    }
}

export const ValidationSchema = (t) => {
    const v = ValidationConstants;
    return Yup.object().shape({
        name: Yup.string()
            .min(v.name.min)
            .max(v.name.max),
        description: Yup.string()
            .min(v.description.min)
            .max(v.description.max)
    });
};

