import React, { useContext, useState } from 'react'
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { LinearProgress, Box, Grid, Link, Divider, FormControl, Button, Table, TableRow, TableCell, TableBody, Tabs, Tab } from '@material-ui/core';
import ListHeader from '../Partials/ListHeader';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { ProviderContext } from '../../Context/ProviderContext';
import { UserContext } from '../../Context/UserContext';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PartnerState from '../../Modules/PartnerState';

const useStyles = makeStyles((theme) => ({
  firstCol: { width: '10%' },
  nestedFirstCol: { width: '20%' },
  checked: {}
}))

const PartnerTab = () => {
  const provider = useContext(ProviderContext);
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    provider?.loading === true ?
      <LinearProgress color="secondary" />
      : provider?.id === null ?
        <Typography>Kein Anbieter ausgewählt</Typography>
        :
        <Grid item xs={12}>
          <PartnerState bookable={provider.bookable} />
          {/* <PartnerState bookable={false} /> */}
        </Grid>
  )
}

export default PartnerTab
