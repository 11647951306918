import React, { useContext, useState, useEffect } from 'react';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { ProviderContext } from '../../Context/ProviderContext';
import { DataContext } from "../../Context/DataContext";
import { colors } from '../../theme'
import { divide } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    height: 40,
    width: 200
  },
  selected: {
    backgroundColor: "turquoise",
    color: "white",
    fontWeight: 600
  }
}));

export default function ProviderSelect({ ...props }) {
  const { Provider } = useContext(
    DataContext
  );
  const providerContext = useContext(
    ProviderContext
  );

  const classes = useStyles();

  useEffect(() => {
    if (Provider.shouldUpdate) {
      Provider.fetchList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Provider.shouldUpdate]);

  const providerList = Provider.data
    .sort((a, b) => {
      return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
    })
    .map((provider) => {
      return (
        <MenuItem
          key={provider.id}
          value={provider.id}
        // selected={provider.id === providerContext?.id}
        >
          {provider.name}
        </MenuItem>
      )
    })
  return (
    < Select
      {...props}
      className={classes.root}
      value={providerContext?.id === null ? 'all' : providerContext.id}
      variant="outlined"
      onChange={(event) => {
        console.log(event.target.value)
        providerContext.setProvider(event.target.value === 'all' ? null : event.target.value)
      }}
    // className={classes.root}
    // classes={{ root: classes.root, selected: classes.selected }}

    >
      <MenuItem
        key="all"
        value="all"
        selected={providerContext.id === null}
      >
        Alle
      </MenuItem>
      { providerList}
    </Select >

  )
}
