import React from 'react';
import IdleTimer from 'react-idle-timer';

const IdleHandler = () => {
  const onIdle = () => {
  }
  const onAction = () => {
  }
  return (
    <IdleTimer
      element={document}
      onIdle={onIdle}
      onAction={onAction}
      debounce={250}
      timeout={1000 * 10 * 1}
    />
  )
}
export default IdleHandler;
