import React, { useContext, useEffect, useState } from 'react';
import { LinearProgress, Container, Table, TableRow, TableCell, TableBody, TableHead, IconButton } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core';
import AlertDialog from '../Modules/AlertDialog';
import { DataContext } from '../Context/DataContext';
import ListHeader from './Partials/ListHeader';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import EditIcon from '@material-ui/icons/Edit';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { ActyvystAPIError } from '../API/APIError';
import { colors } from '../theme';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
  iconButtonHover: {
    "&:hover, &.Mui-focusVisible": { color: theme.palette.secondary.main }
  },
  deleteButtonHover: {
    "&:hover, &.Mui-focusVisible": { color: theme.palette.error.main }
  },
  sortIconActive: {
    color: theme.palette.secondary.main
  },
  sortIcon: {
    color: 'lightgray'
  }
}));

const cutText = (text, limit) => {
  if (text.length > limit) {
    for (let i = limit; i > 0; i--) {
      if (text.charAt(i) === ' ' && (text.charAt(i - 1) !== ',' || text.charAt(i - 1) !== '.' || text.charAt(i - 1) !== ';')) {
        return text.substring(0, i) + '...';
      }
    }
    return text.substring(0, limit) + '...';
  }
  else
    return text;
};

const ActivityScreen = (props) => {
  const { Activity } = useContext(DataContext);
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles(theme);
  const [sort, setSort] = useState({ field: 'name', direction: 1 });
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState({
    title: t('forms.activity.deleteMessage.title'),
    message: t('forms.activity.deleteMessage.message'),
    buttons: [{ label: 'generic.yes', value: true }, { label: 'generic.no', value: false }],
    callback: (value) => { alert(value); setAlertOpen(false) }
  })

  useEffect(() => {
    if (Activity.shouldUpdate) {
      Activity.fetchList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Activity.shouldUpdate]);

  const activityTable = Activity.data.sort((item1, item2) => {
    const i1 = _.get(item1, sort.field, '').toLowerCase();
    const i2 = _.get(item2, sort.field, '').toLowerCase();
    if (i1 < i2) { return -sort.direction } else { return sort.direction }
  }).map(a =>
    <TableRow key={a.id} style={a.published === false ? { backgroundColor: colors.actyLightGray } : {}}>
      <TableCell>
        <IconButton
          size="small"
          className={classes.deleteButtonHover}
          onClick={() => {
            setAlertContent({
              title: t('forms.activity.deleteMessage.title'),
              message: t('forms.activity.deleteMessage.message'),
              buttons: [{ label: 'generic.yes', value: true }, { label: 'generic.no', value: false }],
              callback: (value) => {
                const deleteItem = async () => {
                  try {
                    await Activity.deleteItem(a.id);
                  } catch (err) {
                    // eslint-disable-next-line no-unused-vars
                    const error = ActyvystAPIError.createFromAxiosError(err);
                    setAlertContent({
                      title: t('forms.activity.deleteError.title'),
                      message: t('forms.activity.deleteError.message'),
                      buttons: [{ label: 'ok', value: true }],
                      callback: (value) => setAlertOpen(false)
                    });
                    setAlertOpen(true);
                  }
                }
                if (value) {
                  deleteItem();
                }
                setAlertOpen(false)
              }
            });
            setAlertOpen(true);
          }}
        >
          <HighlightOffIcon />
        </IconButton>
        <IconButton
          size="small"
          className={classes.iconButtonHover}
          onClick={() => { history.push(`activities/${a.id}`) }}
        >
          <EditIcon />
        </IconButton>

      </TableCell>
      <TableCell> {a.name}</TableCell>
      <TableCell> {a.provider.name}</TableCell>
      <TableCell>{a.teaser}</TableCell>
      <TableCell>{a.description ? cutText(a.description, 100) : ''}</TableCell>
    </TableRow>)
  return (
    <Container>
      <ListHeader
        title={t('generic.activities')}
        newButtonLabel={t('forms.activity.new')}
        onNew={() => history.push('/activities/new')}
        onReload={() => { Activity.setShouldUpdate() }}
      />
      {/* <Typography>shouldUpdate:{Activity.shouldUpdate ? 'true' : 'false'}</Typography> */}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('tables.generic.actions')}</TableCell>
            <TableCell>
              {t('generic.name')}
              <IconButton
                className={sort.field === 'name' ? classes.sortIconActive : classes.sortIcon}
                onClick={() => {
                  const direction = sort.field === 'name' ? -sort.direction : 1;
                  setSort({ field: 'name', direction: direction })
                }}
              >
                {sort.field === 'name' && sort.direction === -1 ?
                  <KeyboardArrowUpIcon /> :
                  <KeyboardArrowDownIcon />
                }
              </IconButton>
            </TableCell>
            <TableCell>
              {t('generic.provider')}
              <IconButton
                className={sort.field === 'provider.name' ? classes.sortIconActive : classes.sortIcon}
                onClick={() => {
                  const direction = sort.field === 'provider.name' ? -sort.direction : 1;
                  setSort({ field: 'provider.name', direction: direction })
                }}
              >
                {sort.field === 'provider.name' && sort.direction === -1 ?
                  <KeyboardArrowUpIcon /> :
                  <KeyboardArrowDownIcon />
                }
              </IconButton>
            </TableCell>
            <TableCell>
              {t('forms.activity.teaser')}
            </TableCell>
            <TableCell>
              {t('generic.description')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Activity.loading ?
            <TableRow>
              <TableCell colSpan={2}>
                <LinearProgress color="secondary" />
              </TableCell>
            </TableRow>
            : activityTable
          }

        </TableBody>
      </Table>
      <AlertDialog
        open={alertOpen}
        title={alertContent.title}
        message={alertContent.message}
        buttons={alertContent.buttons}
        callback={alertContent.callback}
      />

    </Container>
  )
}

export default ActivityScreen;