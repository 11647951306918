import React from 'react'
import { Typography, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'


const useStyles = makeStyles(theme => ({
  root: {
    fontWeight: 'normal',
    '&:hover': {
      color: theme.palette.secondary.main,
      cursor: 'pointer'
    }
  }
}));

const InfoTag = ({ title, message }) => {
  const classes = useStyles();
  return (
    <sup>
      <Tooltip
        aria-label="InfoTag"
        title={message}
      >
        <span aria-label="InfoTag">
          &#xa0;
          <Typography variant="caption" className={classes.root}>{title}</Typography>
        </span>
      </Tooltip>
    </sup>
  )
}

InfoTag.defaultProps = {
  title: "Info",
};
export default InfoTag
