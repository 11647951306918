import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { formStyles } from "../../Styles/forms";
import { Field, Formik, useFormikContext } from "formik";
import PropTypes from "prop-types";
import { Button, Divider, Grid, Select, Typography, Input, MenuItem, Box, FormControlLabel, Tooltip, useTheme } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import HelpIcon from '@material-ui/icons/Help';
import CloseIcon from "@material-ui/icons/Close";
import config from '../../Config/config';
import GOFormikCheckBox from "../../Modules/Forms/GOFormikCheckBox";
import AlertDialog from '../../Modules/AlertDialog';


const useStyles = makeStyles(theme => ({
  ...formStyles(theme),

  languageSelect: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: "0.7em",
  },
  publishedCheck: {
    margin: 0,
    padding: 0,
    color: theme.palette.primary.main,
    "&$checked": {
      color: theme.palette.primary.light
    }
  },
  publishedCheckLabel: {
    fontSize: 10
  },
  checked: {},
  checkBoxLabel: {
    marginTop: '-8px',
  },
  languageCheckBox: {
    paddingTop: 0,
    marginTop: 0
  },
  infoIcon: {
    color: theme.palette.secondary.main,

  }
}));

const FormHeader = ({
  showTitle,
  showLanguage,
  showActive,
  showLanguageActive,
  showCloseIcon,
  showSave,
  title,
  language,
  dirty,
  onSave,
  onClose,
  handleChange,
  setFieldValue,
  onLanguageChange
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formik = useFormikContext();
  const theme = useTheme();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState({
    title: t('forms.generic.messages.stateChangedWarningTitle'),
    message: 'no message set',
    buttons: [{ label: 'generic.yes', value: true }, { label: 'generic.no', value: false, default: true }],
    callback: (value) => { alert(value); setAlertOpen(false) }
  });

  const onChangePublishedState = (type, state, callback) => {
    const message = (() => {
      switch (type) {
        case 'main':
          return (state === true ? 'changePublishedStateToTrue' : 'changePublishedStateToFalse')
        case 'language':
          return (state === true ? 'changeLanguagePublishedStateToTrue' : 'changeLanguagePublishedStateToFalse')
        default:
          return 'unknown';
      }
    })();
    setAlertContent({
      ...alertContent,
      message: t(`forms.generic.messages.${message}`),
      callback: (value) => { setAlertOpen(false); callback(value); }
    });
    setAlertOpen(true);
  }

  return (
    <Grid container style={{ marginTop: 10, marginBottom: 10 }}>
      {
        showCloseIcon ?
          <CloseIcon className={classes.closeicon} onClick={onClose} /> :
          <React.Fragment />
      }
      {/* <Grid item xs={12}>
        {JSON.stringify(formik.values)}
      </Grid> */}
      <Grid item xs={6}>
        <Grid container
          alignItems="flex-start"
          alignContent="flex-start"
          justify="flex-start"
          style={{ width: '100%', height: '100%', minHeight: '100%' }}>
          <Grid item xs={12} container
            alignItems="flex-start"
            alignContent="flex-start"
            justify="flex-start"
            style={{ width: '100%', height: '60%', minHeight: '60%' }}>
            {showTitle ?
              <Typography variant="h6">
                {title}
              </Typography> :
              <React.Fragment />
            }
          </Grid>
          <Grid item xs={12}
            alignItems="flex-start"
            alignContent="flex-start"
            justify="flex-start"
            container
            style={{ width: '100%', height: '40%', minHeight: '40%' }}>
            {showActive ?
              <Tooltip
                aria-label="publish-item"
                title={t('forms.generic.info.published')}
              >
                <div
                  aria-label="publish-item"
                >
                  <Field
                    name="published"
                    // style={{ padding: 0, margin: 0 }}
                    style={{ paddingTop: 0, marginTop: 0 }}
                    classes={{ label: classes.checkBoxLabel }}
                    as={GOFormikCheckBox}
                    labelText={t('forms.generic.published')}
                    size="small"
                    onChange={(e) => {
                      const value = e.target.checked;
                      onChangePublishedState('main', value, (result) => {
                        if (result === true) {
                          setFieldValue('published', value);
                        }
                      })
                    }}
                  />
                </div>

              </Tooltip>
              :
              <React.Fragment />}



          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={6} >
        <Grid container justify="center" >
          <Grid item xs={8} >
            <Grid container
              alignItems="flex-start"
              alignContent="flex-start"
              justify="flex-start"
              style={{ width: '100%', height: '100%', minHeight: '100%' }}>
              <Grid item xs={12} container
                alignItems="flex-start"
                alignContent="flex-start"
                justify="flex-start"
                style={{ width: '100%', height: '60%', minHeight: '60%', maxHeight: '60%' }}>
                {showLanguage ?
                  <Select
                    disableUnderline
                    // style={{ padding: 0, margin: 0 }}
                    input={<Input style={{ fontFamily: "'Open Sans', sans-serif", fontSize: '1em' }} />}
                    SelectDisplayProps={{ style: { minWidth: 70, width: '100%', maxWidth: '100%', paddingBottom: 0 } }}
                    className={classes.languageSelect}
                    variant="standard"
                    value={language}
                    onChange={(event) => { onLanguageChange(event.target.value); }}
                  >
                    {config.contentLanguages.map(item => {
                      return (
                        <MenuItem
                          className={classes.mItem}
                          style={{ fontFamily: "'Open Sans', sans-serif", fontSize: "1em", display: 'flex', }}
                          value={item}
                          key={item}
                        >
                          <img alt="" src={`/flags/${item}.svg`} style={{ width: 16, marginLeft: 0, marginRight: 5 }} />
                          {t(`generic.languages.${item}`)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  : <React.Fragment />
                }
              </Grid>
              <Grid item xs={12} alignItems="flex-start" alignContent="flex-start" justify="flex-start" container style={{ width: '100%', height: '40%', maxHeight: '40%', minHeight: '40%' }}>
                {showLanguageActive ?
                  <Tooltip
                    aria-label="publish-language"
                    arrows
                    title={t('forms.generic.info.languagePublished')}
                  // className={classes.infoIcon}
                  >
                    <div
                      aria-label="publish-item"
                    >
                      <Field
                        name={`translations.${language}.published`}
                        style={{ paddingTop: 0 }}
                        classes={{ label: classes.checkBoxLabel }}
                        as={GOFormikCheckBox}
                        labelText={t('forms.generic.languageActive')}
                        onChange={(e) => {
                          const value = e.target.checked;
                          onChangePublishedState('language', value, (result) => {
                            if (result === true) {
                              setFieldValue(`translations.${language}.published`, value)
                            }
                          })
                        }}
                        size="small"
                      />
                    </div>
                  </Tooltip>
                  : <React.Fragment />
                }
              </Grid>
            </Grid>
          </Grid>

          {/* <Grid item xs={4} >

          </Grid> */}
          <Grid item xs={4}>
            <Grid container justify="flex-end" style={{ width: '100%' }}>
              {showSave ?
                <Button
                  disabled={!dirty}
                  classes={{ root: classes.button, disabled: classes.disabledButton }}
                  onClick={onSave}
                >
                  {t('generic.save')}
                </Button> :
                <React.Fragment />
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <AlertDialog
        open={alertOpen}
        title={alertContent.title}
        message={alertContent.message}
        buttons={alertContent.buttons}
        callback={alertContent.callback}
      />
    </Grid>
  )
}
FormHeader.propTypes = {
  showTitle: PropTypes.bool,
  showLanguage: PropTypes.bool,
  showActive: PropTypes.bool,
  showLanguageActive: PropTypes.bool,
  showSave: PropTypes.bool,
  showCloseIcon: PropTypes.bool,
  language: PropTypes.string,
  dirty: PropTypes.bool,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  onLanguageChange: PropTypes.func
};

FormHeader.defaultProps = {
  showTitle: true,
  showLanguage: true,
  showLanguageActive: true,
  showActive: true,
  showSave: true,
  showCloseIcon: false,
  language: config.defaultContentLanguage,
  dirty: false,
  onSave: () => { alert('onSave not implemented') },
  onClose: () => { alert('onClose not implemented') },
  onLanguageChange: (value) => { alert('onLanguageChange not implemented') },
};

export default FormHeader;
