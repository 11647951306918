import React from "react";
import {
  TextField,
  FormControl,
  FormHelperText,
  useTheme
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { formStyles } from "../../Styles/forms";
import { useField, useFormikContext } from "formik";
import { parsePhoneNumberFromString, ParseError } from "libphonenumber-js";

const useStyles = makeStyles(theme => ({
  ...formStyles(theme),
}))


const GOFormikPhoneInput = props => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  const { countryCode } = props;
  // debugger;
  const formatPhoneNumber = e => {
    const typedNum = e.currentTarget.value;
    try {
      const phoneNumber = parsePhoneNumberFromString(typedNum, countryCode);

      setFieldValue("contact.phone", phoneNumber.formatInternational(), false);
    } catch (error) {
      if (error instanceof ParseError) {
        // Not a phone number, non-existent country, etc.
        console.log("ROOOORORRR: " + error.message);
      } else {
        console.log(error);
      }
    }
  };
  const { labelText } = props;
  return (
    <FormControl className={classes.textInput}>
      <TextField
        label={labelText}
        {...props}
        InputLabelProps={{
          shrink: true
        }}
        onBlur={formatPhoneNumber}
      />
      <FormHelperText
        id={`${field.name}-helper-text`}
        className={classes.formHelper}
        error={meta.error && meta.touched}
        component="p"
      >
        {meta.error && meta.touched ? meta.error : ""}
      </FormHelperText>
    </FormControl>
  );
};

export default GOFormikPhoneInput;
