import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { Formik } from 'formik';
import { ValidationSchema } from './validation';
import { useTranslation } from "react-i18next";

const initialData = {
  name: 'Initial Name',
  description: 'Initial Description',
  published: false
}

const GenericFormController = (props) => {
  const { id, render } = props;
  const [formData, setFormData] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (id === 'new') {
      setFormData(initialData);
      setTitle('New Item')
    } else {
      setTimeout(() => {
        setTitle('Edit Item');
        setLoading(true);
        setFormData({
          name: `Sample name`,
          description: 'Sample description',
          published: true
        });
        setLoading(false);
      }, 2000);
    }
  }, [id]);

  const handleSubmit = (values, formikActions) => {
    alert(JSON.stringify(values));
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        ...formData
      }}
      validationSchema={ValidationSchema(t)}
      validateOnBlur={true}
      validateOnChange={false}
      onSubmit={handleSubmit}
      render={(formikProps) => {
        return (
          <React.Fragment>
            {render({ ...formikProps, title, loading })}
          </React.Fragment>
        )
      }}
    >
    </Formik>
  )
}

GenericFormController.propTypes = {
  id: PropTypes.string
}
GenericFormController.defaultProps = {
  id: 'new'
}

export default GenericFormController;