import React, { useContext, useState, useRef } from "react";
import GOFormikSelect from '../../Modules/Forms/GOFormikSelect';
import GOFormikDatePicker from '../../Modules/Forms/GOFormikDatePicker';
import GOFormikCheckBox from '../../Modules/Forms/GOFormikCheckBox';
import GOFormikTimeInput from '../../Modules/Forms/GOFormikTimeInput';
import { Form, Field } from 'formik';
import { useTranslation } from "react-i18next";
import {
  Grid,
  Button,
  Typography,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import moment from "moment";
import "moment/locale/de";
import { DataContext } from "../../Context/DataContext";
import { useFormikContext } from 'formik';
import { formStyles } from '../../Styles/forms';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';

import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditableBookingTable from './EditableBookingTable';
import ReservationForm from "../ReservationForm";

moment.locale("de");

const ExpansionPanelSummary = withStyles({
  root: {
    minHeight: 48,
    marginBottom: -1,
    '&$expanded': {
      minHeight: 48,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);


const useStyles = makeStyles(theme => ({
  ...formStyles(theme),
  tableHead: {
    fontSize: 10
  },
  tableBody: {
    fontSize: 10
  },
  inlineTable: {
    borderBottom: 'none',
    padding: 5
  },
  testred: {
    color: 'red'
  }
}));

const SlotFormUI = props => {
  const { setFieldValue } = useFormikContext();
  const { t } = useTranslation();
  const classes = useStyles();
  const { Variant, Activity } = useContext(DataContext);
  const [expansionOpen, setExpansionOpen] = useState(false);
  const [openReservationDialog, setOpenReservationDialog] = useState(false);

  const expandableRef = useRef();
  const bookingTableRef = useRef();

  return (
    <Form>
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item xs={6}>
          <Field
            name="activity"
            as={GOFormikSelect}
            dataSet={Activity}
            onChange={(e) => { setFieldValue('activity', e.target.value, true); setFieldValue('variant', '', true); }}
            inputLabelId="activity-select-label"
            labelText={t("activity")}
            datafilters={[]}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name="variant"
            as={GOFormikSelect}
            dataSet={Variant}
            inputLabelId="variant-select-label"
            labelText={t("variant")}
            datafilters={[
              { key: "activity.id", value: props.values.activity }
            ]}
          />
        </Grid>
        <Grid item xs={3} style={{ paddingBottom: 0 }}>
          <Field
            name="startDate"
            as={GOFormikDatePicker}
            labelText={`${props.values.isMultiDay ? t("startDate") : t("date")
              }`}
            locale="de"
            format={t("pickerDateFormat")}
          />

        </Grid>
        {
          props.values.isMultiDay ?
            <Grid item xs={3} style={{ paddingBottom: 0 }}>
              <Field
                name="endDate"
                as={GOFormikDatePicker}
                labelText={`${props.values.isMultiDay ? t("endDate") : t("date")
                  }`}
                locale="de"
                format={t("pickerDateFormat")}
                customStyle={{
                  marginLeft: 6,
                  visibility: props.values.isMultiDay ? "visible" : "hidden"
                }}
              />
            </Grid> :
            <React.Fragment />
        }

        <Grid item xs={3} style={{ paddingBottom: 0 }}>
          <Field
            name="startTime"
            as={GOFormikTimeInput}
            labelText={`${props.values.isMultiDay ? t("dailyStartTime") : t("startTime")
              }`}
          />
        </Grid>
        <Grid item xs={3} style={{ paddingBottom: 0 }}>
          <Field
            name="endTime"
            as={GOFormikTimeInput}
            labelText={`${props.values.isMultiDay ? t("dailyEndTime") : t("endTime")
              }`}
          />
        </Grid>
        {
          !props.values.isMultiDay ?
            <Grid item xs={3}></Grid> :
            <React.Fragment />
        }

        <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Field
            name="isMultiDay"
            as={GOFormikCheckBox}
            labelText={t("dayRange")}
            size="small"
          />
        </Grid>

        <Grid item xs={12}>

          <ExpansionPanel
            expanded={expansionOpen}
            onChange={() => { setExpansionOpen(expansionOpen ? false : true) }}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="bookings-panel"
              id="bookings-panel"
            >
              <Grid container>
                <Grid item xs={9} >
                  <Typography>{props.values.maxParticipants - props.values.availableSlots} / {props.values.maxParticipants} Plätze gebucht</Typography>
                </Grid>
                <Grid item xs={3} >
                  {
                    expansionOpen && props.values.id !== "new" ?
                      <Grid container justify="flex-end">
                        <Button className={classes.button}
                          onFocus={(event) => event.stopPropagation()}
                          onClick={(event) => { event.stopPropagation(); setOpenReservationDialog(true) }}

                        >
                          Buchung hinzufügen
                        </Button>
                      </Grid> :
                      <React.Fragment />
                  }
                </Grid>
              </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails ref={expandableRef} style={{ maxHeight: 300, overflowY: 'auto', overflowX: 'hidden', padding: 12, paddingTop: 0 }}>

              <EditableBookingTable tableRef={bookingTableRef} id={props.values.id} />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
        <ReservationForm
          type="dialog"
          open={openReservationDialog}
          onDataChanged={() => {
            // bookingTableRef.current.onQueryChange();
          }}
          slot={props.values.id}
          onClose={() => { setOpenReservationDialog(false); bookingTableRef.current.onQueryChange(); }}
        />


        <Grid item xs={6}>
          <Field
            name="published"
            as={GOFormikCheckBox}
            labelText={t("forms.generic.published")}
            size="small"
          />
        </Grid>
        <Grid item xs={6}>
          <Grid container justify="flex-end">
            {
              props.values.id !== "new" ?
                <Button className={classes.button}
                  onClick={props.onDelete}
                >
                  {t('generic.delete')}
                </Button>
                : undefined
            }

            <Button className={classes.button} onClick={props.onClose}>
              {t('generic.cancel')}
            </Button>
            <Button type="submit" className={classes.button} >
              {t('generic.save')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Form >
  );
};

export default SlotFormUI;
