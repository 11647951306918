import React from 'react'
import { Container, Grid, Typography, Card, CardContent, CardHeader, CardActions, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { formStyles } from '../../Styles/forms';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  ...formStyles(theme),
  textField: {
    width: '100%',
    minWidth: '100%'
  },
  card: {
    marginTop: 10,
    marginBottom: 10
  },
  cardTitle: theme.typography.h6
}));

const UserIsProvider = (props) => {
  const { email } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  return (
    <Container>
      <Grid container style={{ marginTop: 10, marginBottom: 10 }}>
        <Grid item xs={12} >
          <Grid item lg={7} md={9} xs={12}>
            <Card className={classes.card}>
              <CardHeader
                title={t('forms.register.userIsProvider.title')}
                classes={{ title: classes.cardTitle }}
              >
                <Typography variant='h6'>{t('forms.register.userIsProvider.title')}</Typography>
              </CardHeader>
              <CardContent>
                <Typography variant="body2" component="p" style={{ textAlign: 'center' }}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('forms.register.userIsProvider.description', { 'interpolation': { 'escapeValue': false }, 'email': email })
                    }}
                  />
                </Typography>
              </CardContent>
              <CardActions >
                <Grid container justify="flex-end">
                  <Button
                    className={classes.button}
                    variant="contained"
                    // onClick={() => { history.push(`${process.env.REACT_APP_ACTYVYST_COM}/sylt/anbieter`) }}
                    onClick={() => { window.open(`http://localhost:4200/sylt/anbieter`) }}
                  >
                    {t('forms.register.userIsProvider.actions.register')}
                  </Button>
                  <Button
                    className={classes.button}
                    variant="contained"
                    onClick={() => { history.push('/login') }}
                  >
                    {t('forms.register.userIsProvider.actions.login')}
                  </Button>
                </Grid>

              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )

}

export default UserIsProvider