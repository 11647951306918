import React from "react";
import { TextField, FormControl, FormHelperText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useField } from 'formik';

import "moment/locale/de";
const useStyles = makeStyles(theme => ({
    typography: {
        padding: theme.spacing(2)
    },
    textInput: {
        fontFamily: "'Open Sans', sans-serif",
        minWidth: "95%"
    },

    formControl: {
        minWidth: 120,
        maxWidth: 300,
        marginBottom: 10,
        marginLeft: 6
    },
    root: {
        fontFamily: "'Open Sans', sans-serif",
        fontSize: "1em"
    }
}));
const GOFormikTimeInput = props => {
    const classes = useStyles();
    const [field, meta] = useField(props);

    const { labelText } = props;
    return (
        <FormControl className={classes.textInput}>
            <TextField
                label={labelText}
                type="time"
                InputLabelProps={{
                    shrink: true
                }}
                inputProps={{
                    step: 600
                }}
                {...props}
            />
            <FormHelperText
                id={`${field.name}-helper-text`}
                error={meta.error && meta.touched}
                component="p"
            >
                {meta.error && meta.touched
                    ? meta.error
                    : ""}
            </FormHelperText>
        </FormControl>
    );
};

export default GOFormikTimeInput;
