import React from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import IconButton from "@material-ui/core/IconButton";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: theme.palette.success.dark
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.light
  },
  warning: {
    backgroundColor: theme.palette.warning.dark
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
}));

function GOSnackBar(props) {
  const { open, variant, closeHandle, messagetext } = props;
  const theme = useTheme();
  const classes = useStyles(theme);

  const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon
  };
  const Icon = variantIcon[variant];

  return (
    <React.Fragment>
      {open === true ? (
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          open={open}
          autoHideDuration={3000}
          onClose={closeHandle}
        >
          <SnackbarContent
            className={classes[variant]} // style={{ ...classes.success }}
            aria-describedby="client-snackbar"
            message={
              <span className={classes.message}>
                <Icon className={classes.iconVariant} />
                {messagetext}
              </span>
            }
            action={[
              <IconButton
                key="close"
                aria-label="close"
                color="inherit"
                onClick={closeHandle}
              >
                <CloseIcon className={classes.icon} />
              </IconButton>
            ]}
          />
        </Snackbar>
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  );
}

export default GOSnackBar;
