import React, { useState, useContext } from 'react';
import { Grid, GridList, GridListTile, IconButton, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SwapVerticalCircleIcon from '@material-ui/icons/SwapVerticalCircle';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import PanoramaIcon from '@material-ui/icons/Panorama';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { formStyles } from '../../Styles/forms';
import config from "../../Config/config";
import { DataContext } from '../../Context/DataContext';
import { useFormikContext, Field } from 'formik';
import GOFormikTextInput from '../../Modules/Forms/GOFormikTextInput';
import CircularProgress from '@material-ui/core/CircularProgress';
import AlertDialog from "../../Modules/AlertDialog";

const useStyles = makeStyles(theme => ({
  ...formStyles(theme),
  imageIcon: {
    color: 'white'
  },
  buttonDisabled: {
    color: 'yellow'
  },
  gridList: {
    marginTop: 20,
    marginBottom: 20,
    width: '100%',
  },
  icon: {
    "&:hover": {
      color: theme.palette.warning.main
    }
  },
  checked: {}
}));

const maxImages = 5;

const ProgressOverlay = (props) => {
  return (
    <div
      style={{
        width: '100%', height: '100%',
        backgroundColor: 'rgba(0,0,0,0.7)',
        color: 'white',
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center'
      }} >
      <CircularProgress color="inherit" />
    </div>
  )
}

const ActionOverlay = (props) => {
  const { index, uploadFile, move, visible, disableUpload, onDelete } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  return (

    <Grid container style={{

      display: visible ? 'flex' : 'none',
      width: '100%', height: '100%',
      backgroundColor: 'rgba(0,0,0,0.7)',
      color: 'white'
    }}  >
      <Grid item xs={12} >
        <input
          key={`fileInput${index}`}
          accept="image/*"
          style={{ display: 'none' }}
          id={`hidden-input-${index}`}
          type="file"
          onChange={e => {
            uploadFile(e.target.files[0], index)
          }}
        />
        <label htmlFor={`hidden-input-${index}`}>
          <IconButton
            color="inherit"
            disabled={disableUpload}
            component="span"
            classes={{ disabled: classes.buttonDisabled }}
          >
            <SwapVerticalCircleIcon
              color="inherit"
              fontSize="small"
            />
            <Typography>{t('forms.activity.changeImageButton')}</Typography>

          </IconButton>
        </label>

      </Grid>
      {
        props.displayDelete ?
          <Grid item xs={12}>
            <IconButton color="inherit"
              onClick={() => {
                onDelete(index)
              }}>
              <DeleteIcon fontSize="small" />
              <Typography>{t('forms.activity.deleteImageButton')}</Typography>

            </IconButton>
          </Grid> :
          <React.Fragment />
      }
      <Grid item xs={2} style={{ width: '100%' }}>
        {
          props.displayBack ?

            <IconButton color="inherit"
              onClick={() => { move(index, props.displayBack ? index - 1 : index) }}
            >
              <ArrowBackIcon fontSize="small" />
              {/* <Typography>{t('forms.activity.imageMoveLeftButton')}</Typography> */}

            </IconButton>
            :
            <React.Fragment />
        }
      </Grid>
      <Grid item xs={8} style={{ width: '100%' }}></Grid>
      {
        props.displayForward ?
          <Grid item xs={2}>
            <IconButton color="inherit"
              onClick={() => { move(index, props.displayForward ? index + 1 : index) }}
            >
              <ArrowForwardIcon fontSize="small" />
              {/* <Typography>{t('forms.activity.imageMoveRightButton')}</Typography> */}

            </IconButton>
          </Grid> :
          <React.Fragment />
      }
    </Grid >

  )
}

const ImageUpload = (props) => {
  const { remove, form } = props;
  const { setFieldValue } = useFormikContext();
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const { images } = form.values;
  const [activeTile, setActiveTile] = useState(-1);
  const [uploadingIndex, setUploadingIndex] = useState(-1);
  const { Activity } = useContext(DataContext);
  const [alertOpen, setAlertOpen] = useState(false);
  const [selectedForDeletion, setSelectedForDeletion] = useState(-1);

  const tiles = [...images];
  for (let i = images.length; i < maxImages; i++) {
    tiles[i] = null;
  }

  const uploadFile = (file, index) => {
    const upload = async (file, index) => {
      try {
        const imgFile = await Activity.uploadImage(file);
        setFieldValue(`images[${index}]`, { url: imgFile, description: '' });
      } catch (err) {
        alert(err);
      } finally {
        setUploadingIndex(-1);
      }
    }

    if (uploadingIndex >= 0) { return }
    setUploadingIndex(index);
    upload(file, index);
  }

  return (
    <div style={{ paddingTop: 20, paddingBottom: 20 }}>
      <GridList cellHeight={240} className={classes.gridList} cols={3}>
        {tiles.map((image, index) => {
          return (
            <GridListTile key={`imagelist-${index}`}>
              <div
                onMouseOver={() => {
                  setActiveTile(index);
                }}
                onMouseOut={() => {
                  setActiveTile(-1);
                }}
                style={image ? {
                  width: '100%', height: '75%',
                  backgroundImage: `url(${config.contentImagePath + image.url})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '50% 50%'
                } : {
                  display: 'flex',
                  width: '100%', height: '75%',
                  borderStyle: 'dotted', borderColor: 'lightgrey',
                  borderWidth: 'thin',
                  borderLeftStyle: 'dotted',
                  borderLeftWidth: 'thin',
                  borderRightStyle: 'dotted',
                  borderRightWidth: 'thin',
                  alignContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                {uploadingIndex === index ?
                  <ProgressOverlay /> :
                  index < images.length ?
                    <ActionOverlay
                      visible={activeTile === index}
                      displayDelete={index < images.length}
                      displayBack={image && index > 0}
                      displayForward={image && index < images.length - 1}
                      index={index}
                      onDelete={(index) => {
                        setSelectedForDeletion(index);
                        setAlertOpen(true);
                      }}
                      disableUpload={uploadingIndex >= 0}
                      uploadFile={uploadFile}
                      {...props}
                    /> :
                    <React.Fragment />
                }
                {index === images.length ?
                  <React.Fragment>
                    <input
                      key={`fileInput${index}`}
                      accept="image/*"
                      style={{ display: 'none' }}
                      id={`hidden-input-${index}`}
                      type="file"
                      onChange={e => uploadFile(e.target.files[0], index)}
                    />
                    <label htmlFor={`hidden-input-${index}`}>
                      <IconButton
                        component="span"

                      >
                        <AddCircleIcon color="secondary" fontSize="large" />
                      </IconButton>
                    </label>

                  </React.Fragment>
                  :
                  // <Button className={classes.button}>Bild hochladen</Button> :
                  <React.Fragment />
                }
                {index > images.length ?
                  <span style={{ color: 'lightgray', fontSize: "48px" }}>
                    <PanoramaIcon fontSize="inherit" color="inherit" />
                  </span> :
                  <React.Fragment />
                }

              </div>
              <div style={{ display: 'flex', width: '100%', height: '25%' }}>
                {image ?
                  <Field
                    as={GOFormikTextInput}
                    // flag={props.contentLanguage}
                    name={`images[${index}].description`}
                    label={'Copyright'}
                  /> :
                  <React.Fragment />
                }

              </div>
            </GridListTile>
          )

        })}

        <AlertDialog
          open={alertOpen}
          title={t('forms.activity.deleteImageMessage.title')}
          callback={(result) => {
            setAlertOpen(alertOpen ? false : true);
            if (result && selectedForDeletion >= 0) {
              remove(selectedForDeletion);
            }
            setSelectedForDeletion(-1);
          }}
          message={t('forms.activity.deleteImageMessage.message')}
        />
      </GridList >
    </div>
  )
}

export default ImageUpload;