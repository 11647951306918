import React, { useContext, useState } from "react";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Typography from "@material-ui/core/Typography";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { useTheme, makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import { Divider, Collapse } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { UserContext } from '../../Context/UserContext';
import { ProviderContext } from '../../Context/ProviderContext';

const useStyles = makeStyles(theme => ({
  disabledIcon: {
    color: "lightgrey",
    "&$disabled": {
      color: "yellow"
    }
  },
  disabled: {},
  menuIcon: {
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  activeMenuIcon: {
    color: theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  menuText: {
    fontWeight: 'normal'
  },
  activeMenuText: {
    fontWeight: 'bold',
  },
  navHeader: {
    fontSize: "1em"
  }
}));


function ListItemRouter(props) {
  const history = useHistory();
  const { linkTo, ...innerProps } = props;

  return (
    <ListItem
      button
      {...innerProps}
      onClick={() => {
        history.push(props.linkTo);
      }}
    />
  );
}

const MenuItems = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const { user } = useContext(UserContext);
  const provider = useContext(ProviderContext);
  const [collapseOpen, setCollapseOpen] = useState(props.menuData.map((item, index) => {
    return false
  }));
  const items = props.menuData.map((item, index) => {
    const selected = item.exactMatch ? location.pathname === item.link : location.pathname.startsWith(item.link);
    if (item.requiresLogin && !user) { return <React.Fragment key={Math.random()} /> }
    if (item.requiresBookable && (!provider || !provider.bookable)) { return <React.Fragment key={Math.random()} /> }
    if (item.requiresAdmin && (!user || !user.isAdmin)) { return <React.Fragment key={Math.random()} /> }
    if (item.publicOnly && user) { return <React.Fragment key={Math.random()} /> }
    else {
      if (item.children && item.children.length > 0) {
        return (
          <React.Fragment key={Math.random()}>
            <ListItem
              key={Math.random()}
              // linkTo={item.link}
              button
              onClick={() => {
                const temp = collapseOpen.map((value, i) => { return i === index ? !value : value })
                setCollapseOpen(temp)
              }}
            >
              <ListItemText
                primary={t(item.title)}
                classes={{ primary: selected ? classes.activeMenuText : classes.menuText }}
              />
              {collapseOpen[index] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={collapseOpen[index]} timeout="auto" unmountOnExit>
              <List>
                <MenuItems menuData={item.children} />
              </List>

            </Collapse>
          </React.Fragment>
        )
      } else {
        return (
          <ListItemRouter selected={selected} key={Math.random()} linkTo={item.link}>
            <ListItemIcon className={selected ? classes.activeMenuIcon : classes.menuIcon}>{item.icon}</ListItemIcon>
            <ListItemText primary={t(item.title)} classes={{ primary: selected ? classes.activeMenuText : classes.menuText }} />
          </ListItemRouter>
        );
      }
    }
  });
  return items;
}

const Menu = props => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <List style={{ paddingTop: 0 }}>
      <ListSubheader
        disableGutters={true}
        style={{
          height: 50,
          margin: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center"
        }}
      >
        <List>
          <ListItem style={{ marginLeft: 0, marginRight: 0 }}>
            <Typography variant="h1" className={classes.navHeader}>
              {"actyvyst"}{" "}
              <span style={{ color: theme.palette.secondary.main }}>
                {"Anbieter Cockpit"}
              </span>
            </Typography>
          </ListItem>
        </List>

      </ListSubheader>
      <Divider />
      <List>
        <MenuItems menuData={props.menuData} />
      </List>
    </List>
  );
};

export default Menu;