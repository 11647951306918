import React from 'react';
import GOFormikSelect from '../../Modules/Forms/GOFormikSelect';
import GOFormikSimpleSelect from '../../Modules/Forms/GOFormikSimpleSelect';
import GOFormikTextInput from '../../Modules/Forms/GOFormikTextInput';
import GOFormikCheckBox from '../../Modules/Forms/GOFormikCheckBox';
import { validationConstants } from './validation';
import {
  Typography,
  Grid,
  Button,
  Icon,
  IconButton,
  Divider
} from "@material-ui/core";
import { Form, Field, FieldArray } from 'formik';
import { useTranslation } from "react-i18next";

const PriceCategoryListPartial = (props) => {
  const { move, remove, push, form } = props;
  const { t } = useTranslation();
  const maxItems = 5;

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4">{t('forms.variant.priceList')}</Typography>
        </Grid>
        {form.values.priceCategories.map((item, index) => {
          return (
            <React.Fragment key={`priceCategories${index}`}>
              <Grid item xs={12} style={{ marginBottom: 8, marginTop: 4 }}>
                <Divider />
              </Grid>

              <Grid item xs={4}>
                <Field
                  as={GOFormikTextInput}
                  key={`priceCategories[${index}].translations[${props.contentLanguage}].name`}
                  name={`priceCategories[${index}]translations[${props.contentLanguage}].name`}
                  label={t('generic.name')}
                  flag={props.contentLanguage}

                />
              </Grid>
              <Grid item xs={7}>
                <Field
                  as={GOFormikTextInput}
                  key={`priceCategories[${index}]translations[${props.contentLanguage}].description`}
                  name={`priceCategories[${index}]translations[${props.contentLanguage}].description`}
                  label={t('generic.description')}
                  flag={props.contentLanguage}

                />
              </Grid>
              <Grid item xs={1}>
                <Grid container>
                  <Grid item xs={6}>
                    <IconButton
                      size="small"
                      onClick={() => {
                        remove(index);
                      }}
                    >
                      <Icon color="secondary" variant="outlined" fontSize="small">
                        remove_circle
                                                </Icon>
                    </IconButton>
                  </Grid>
                  <Grid item xs={6}>
                    {
                      index < maxItems - 1 ? (
                        <IconButton size="small" onClick={() => push("")}>
                          <Icon color="secondary" fontSize="small">
                            add_circle
                                                        </Icon>
                        </IconButton>
                      ) : (
                          <React.Fragment />
                        )
                    }
                  </Grid>
                  <Grid item xs={6}>
                    {index > 0 ? (
                      <IconButton
                        size="small"
                        onClick={() => {
                          move(index, index - 1);
                        }}
                      >
                        <Icon
                          color="secondary"
                          variant="outlined"
                          fontSize="small"
                        >
                          arrow_upward
                                                    </Icon>
                      </IconButton>
                    ) : (
                        <React.Fragment />
                      )}
                  </Grid>
                  <Grid item xs={6}>
                    {index < form.values.priceCategories.length - 1 ? (
                      <IconButton
                        size="small"
                        onClick={() => move(index, index + 1)}
                      >
                        <Icon color="secondary" fontSize="small">
                          arrow_downward
                                                        </Icon>
                      </IconButton>
                    ) : (
                        <React.Fragment />
                      )}
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid xs={1}>
                                <Button onClick={() => remove(index)}>-</Button>
                            </Grid>
                            <Grid xs={1}>
                                <Button onClick={() => push({ name: '', description: '' })}>+</Button>
                            </Grid> */}
              <Grid item xs={6}></Grid>
              <Grid item xs={2} >
                <Field
                  inputFormat="integer"
                  as={GOFormikTextInput}
                  key={`priceCategories[${index}].allowedParticipants`}
                  name={`priceCategories[${index}].allowedParticipants`}
                  label={t('forms.variant.allowedParticipants')}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  as={GOFormikTextInput}
                  inputFormat="currency"
                  key={`priceCategories[${index}].price`}
                  name={`priceCategories[${index}].price`}
                  label={t('generic.price')}
                />
              </Grid>
              {/* <Grid item xs={2}>
                                <Field
                                    as={GOFormikTextInput}
                                    key={`priceCategories[${index}].currency`}
                                    name={`priceCategories[${index}].currency`}
                                    label={t('generic.currency')}
                                />
                            </Grid> */}
              <Grid item xs={1}></Grid>

            </React.Fragment>
          )
        })}
        {form.values.priceCategories.length < 1 ?
          <Button onClick={() => push({ name: '', description: '' })}>{t('forms.variant.newPriceCategory')}</Button> :
          <React.Fragment />}
      </Grid>
    </React.Fragment >
  )
}

export default PriceCategoryListPartial;