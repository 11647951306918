import React, { useContext, useEffect, useState } from 'react';
import { Container, Typography, Card, Grid, CardContent, Grow } from '@material-ui/core';
import { formStyles } from '../Styles/forms';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import LockIcon from '@material-ui/icons/Lock';
import { ProviderContext } from '../Context/ProviderContext';

const useStyles = makeStyles((theme) => ({
  ...formStyles(theme),
  card: {
    marginTop: 10,
    marginBottom: 10,
  },
  svg: {
    width: 50,
    height: 50,
  },
  circle: {
    fill: '#00ffff',
    stroke: theme.palette.divider,
    strokeWidth: 1,
  },
  media: {
    height: 140,
  },
  checked: {}

}));

const cardData = [
  {
    title: 'forms.welcome.activities.title',
    description: 'forms.welcome.activities.description',
    lock: false
  },
  {
    title: 'forms.welcome.variants.title',
    description: 'forms.welcome.variants.description',
    lock: true
  },
  {
    title: 'forms.welcome.slots.title',
    description: 'forms.welcome.slots.description',
    lock: true
  },
  {
    title: 'forms.welcome.bookings.title',
    description: 'forms.welcome.bookings.description',
    lock: true
  }
]

const Welcome = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [showCards, setShowCards] = useState(false);
  const provider = useContext(ProviderContext)


  useEffect(() => {
    setTimeout(() => {
      setShowCards(true);
    }, 300)
  }, []);

  const cards = cardData.map((item, index) => {
    return (
      <Grow key={`card-${index}`}
        in={showCards}
        style={{ transformOrigin: '0 0 0' }}
        {...(showCards ? { timeout: 500 * (index + 1) } : {})}>
        <Grid item xs={12}>
          <Grid item lg={7} md={9} xs={12}>
            <Card className={classes.card}>
              <CardContent>
                <div style={{ justifyContent: 'spaceBetween' }}>
                  <Grid container>
                    <Grid item xs={11}>
                      <Typography variant="h6">{`${index + 1}) ${t(item.title)}`}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      {item.lock && !provider.bookable ?
                        <LockIcon />
                        : undefined
                      }
                    </Grid>
                  </Grid>

                </div>

                <Typography variant="body2" component="p" >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t(item.description, { 'interpolation': { 'escapeValue': false } })
                    }}
                  />
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grow>
    )
  })

  return (
    <Container >
      <Grid container style={{ marginTop: 10, marginBottom: 10 }}>

        <Typography variant="h6">{t('forms.welcome.title')}</Typography>
        <Grid container style={{ marginTop: 10, marginBottom: 10 }}>
          <Grow in={showCards}>
            <Grid item xs={12}>
              <Grid item lg={7} md={9} xs={12}>
                <Card className={classes.card}>
                  <CardContent>
                    <Typography variant="h6">{t('forms.welcome.firstSteps')}</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={5}></Grid>
            </Grid>
          </Grow>

          {cards}

        </Grid>
      </Grid>
    </Container >
  )
}

export default Welcome;