import React from 'react';
import { Grid, LinearProgress } from '@material-ui/core';
import { Form, Field } from 'formik';
import GOFormikTextInput from '../../Modules/Forms/GOFormikTextInput';

const GenericFormUI = (props) => {
  const { loading } = props;
  const content = loading ?
    <LinearProgress /> :
    <Form>
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item xs={6}>
          <Field
            name="name"
            as={GOFormikTextInput}
            labelText={'Name'}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name="description"
            as={GOFormikTextInput}
            labelText={'Description'}
          />
        </Grid>
      </Grid>
    </Form>
  return (
    <React.Fragment>
      {content}
    </React.Fragment>
  )
}

export default GenericFormUI;