import React, { useState } from 'react';
import { Container, Typography, Button } from '@material-ui/core';
import GenericFormController from '../../Forms/GenericForm/FormController';
import GenericFormUI from '../../Forms/GenericForm/FormUI';
import FormHeader from '../../Forms/Partials/FormHeader';
import FormFooter from '../../Forms/Partials/FormFooter';
import FormDialog from '../../Forms/Container/FormDialog';

const TestForm = (props) => {
    const [open, setOpen] = useState(false);
    return (
        <Container>
            <Typography>Test</Typography>
            <Button onClick={() => { setOpen(true) }}>Dialog</Button>
            <GenericFormController
                id={10}
                render={(formProps) => {
                    return (
                        <FormDialog
                            {...formProps}
                            onClose={() => { setOpen(false) }}
                            open={open}
                            render={(closeDialog) => {
                                return (
                                    <React.Fragment>
                                        <FormHeader
                                            {...formProps}
                                            showCloseIcon={true}
                                            showActive={true}
                                            showLanguage={false}
                                            showLanguageActive={false}
                                            showSave={false}
                                            onClose={closeDialog}
                                        />
                                        <GenericFormUI {...formProps} onClose={closeDialog} />
                                        <FormFooter {...formProps} onClose={closeDialog} />
                                    </React.Fragment>
                                )
                            }}
                        >
                        </FormDialog>
                    )
                }}
            />
        </Container>
    )
}

export default TestForm;