import React from 'react';
import { Container, Typography, Paper, Grid, Button } from '@material-ui/core';



const StyleGuide = () => {
  return (
    <Container>
      <Paper>
        <Grid container>
          <Grid item xs={6}>
            <Grid item xs={12}>
              <Typography variant="h1">Heading H1</Typography>
              <Typography variant="h2">Heading H2</Typography>
              <Typography variant="h3">Heading H3</Typography>
              <Typography variant="h4">Heading H4</Typography>
              <Typography variant="h5">Heading H5</Typography>
              <Typography variant="h6">Heading H6</Typography>
              <Typography variant="subtitle1">Subtitle 1</Typography>
              <Typography variant="subtitle2">Subtitle 2</Typography>
              <Typography variant="body1">Body 1</Typography>
              <Typography variant="body2">Body 2</Typography>
              <Typography variant="button" display="block">Button</Typography>
              <Typography variant="caption" display="block">Caption</Typography>
              <Typography variant="overline" display="block">Overline</Typography>
            </Grid>
            <Grid item xs={12}>
              <Button color="primary">Primary</Button>
              <Button color="secondary">Secondary</Button>
            </Grid>
          </Grid>

        </Grid>

      </Paper>
    </Container>
  )
}

export default StyleGuide;