import React from 'react';
import { Dialog } from '@material-ui/core';
import SlotForm from './';

const SlotDialog = (props) => {
    return (
        <Dialog
            {...props}
        >
            <SlotForm {...props} />
        </Dialog>
    )
}

export default SlotDialog