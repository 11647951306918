import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from '@material-ui/core';
import { formStyles } from "../../Styles/forms";
import {
  Typography,
  Grid,
  Button,
  Divider,
  Container,
  Table,
  TableRow,
  TableCell,
  Link,
  Select,
  MenuItem

} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { GenericAPI } from '../../API/generic';


import AlertDialog from '../../Modules/AlertDialog';
import { ProviderApplicationStates } from "../../Constants/providerApplication";

const useStyles = makeStyles(theme => ({
  ...formStyles(theme),
  titleCol: {
    width: '30%'
  },
  contentCol: {
    width: '70%'
  },
  providerSelect: {
    minWidth: 200,
    maxWidth: 400,
    width: '50%'
  }
}))



const ProviderApplicationDetails = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [alertOpen, setAlertOpen] = useState(false);
  const [providers, setProviders] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [providersLoading, setProvidersLoading] = useState(false);
  const [shouldUpdateProviders, setShouldUpdateProviders] = useState(false);
  const [alertContent, setAlertContent] = useState({
    title: t('forms.providerApplications.confirmMessageTitle'),
    message: t('forms.providerApplications.confirmMessage'),
    callback: (value) => { alert(value); setAlertOpen(false) }
  });
  const [selectedProvider, setSelectedProvider] = useState('new');
  const classes = useStyles(theme);
  const { data } = props;

  useEffect(() => {
    const loadProviders = async () => {
      try {
        setProvidersLoading(true);
        const data = await GenericAPI('/activities/providers').fetchList();
        setProviders(data.sort((a, b) => a.name >= b.name ? 1 : -1));
        setSelectedProvider(data.provider || 'new');
        setProvidersLoading(false);

      } catch (err) {
        setProvidersLoading(false);
        alert(err.message);
      }
    }
    if (shouldUpdateProviders) {
      loadProviders();
      setShouldUpdateProviders(false);
    }
  }, [shouldUpdateProviders]);

  useEffect(() => {
    setShouldUpdateProviders(true);
  }, [])

  return (
    <Container style={{ display: 'flex', minHeight: 180, minWidth: 320, padding: 10 }}>
      <CloseIcon className={classes.closeicon} onClick={props.onClose} />
      <Grid container style={{ padding: 10 }}>
        <Grid item xs={12}>
          <Typography variant="h6">
            {t('forms.providerApplications.details.title')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Table>
            <TableRow>
              <TableCell className={classes.titleCol}>{t('forms.providerApplications.details.name')}</TableCell>
              <TableCell className={classes.contentCol}>{data.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.titleCol}>{t('forms.providerApplications.details.description')}</TableCell>
              <TableCell className={classes.contentCol} >
                {data.description}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.titleCol}>{t('forms.providerApplications.details.web')}</TableCell>
              <TableCell className={classes.contentCol}>
                <Link
                  href={`http://${data.web}`}
                  target='_blank'
                >
                  {data.web}
                </Link>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.titleCol}>{t('forms.providerApplications.details.address')}</TableCell>
              <TableCell className={classes.contentCol} >
                <Typography >{data.street}</Typography>
                <Typography >{data.zip} {data.city}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.titleCol}>{t('forms.providerApplications.details.contact')}</TableCell>
              <TableCell className={classes.contentCol}>
                <Typography>{data.contact.firstName} {data.contact.lastName}</Typography>
                <Typography>{data.contact.phone}</Typography>
                <Typography>
                  <Link
                    href={`mailto:${data.contact.email}`}
                  >
                    {data.contact.email}
                  </Link>
                </Typography>
                <Typography>
                  {data.userExists ? 'user exists' : 'new user'}
                </Typography>
                <Typography>
                  {data.userIsProvider ? 'user is provider' : 'user is not provider'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.titleCol}>{t('generic.provider')}</TableCell>
              <TableCell className={classes.contentCol}>
                {data.status === ProviderApplicationStates.WAITING_FOR_APPROVAL ?
                  <Select
                    className={classes.providerSelect}
                    value={selectedProvider}
                    onChange={(event) => {
                      setSelectedProvider(event.target.value);
                    }}
                  >
                    <MenuItem key="new" value="new">Neuer Anbieter</MenuItem>
                    {providers.map((provider) => {
                      return (
                        <MenuItem
                          key={provider.id}
                          value={provider.id}
                        >
                          {provider.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                  : <Typography>{data.provider}</Typography>
                }

              </TableCell>
            </TableRow>
          </Table>
        </Grid>
        <Divider />
        {data.status === ProviderApplicationStates.WAITING_FOR_APPROVAL ?
          <React.Fragment>
            <Grid item xs={6} align="center" style={{ paddingTop: 20, paddingBottom: 10 }}>
              <Button
                classes={{ root: classes.button, disabled: classes.disabledButton }}
                onClick={() => {
                  setAlertContent({
                    title: t('forms.providerApplications.confirmMessageTitle'),
                    message: t('forms.providerApplications.confirmMessage'),
                    callback: (value) => {
                      if (value === true) {
                        props.onConfirm(selectedProvider);
                      }
                      setAlertOpen(false);
                    }
                  });
                  setAlertOpen(true);

                }}
              >
                {t('forms.providerApplications.actions.confirm')}
              </Button>
            </Grid>
            <Grid item xs={6} align="center" style={{ paddingTop: 20, paddingBottom: 10 }}>
              <Button
                classes={{ root: classes.warningButton, disabled: classes.disabledButton }}
                color='primary'
                onClick={() => {
                  setAlertContent({
                    title: t('forms.providerApplications.cancelMessageTitle'),
                    message: t('forms.providerApplications.cancelMessage'),
                    callback: (value) => {
                      if (value === true) {
                        props.onReject();
                      }
                      setAlertOpen(false);
                    }
                  });
                  setAlertOpen(true);
                }}
              >
                {t('forms.providerApplications.actions.reject')}
              </Button>
            </Grid>
          </React.Fragment>
          : undefined
        }



      </Grid>
      <AlertDialog
        open={alertOpen}
        title={alertContent.title}
        message={alertContent.message}
        callback={alertContent.callback}
      />
    </Container>
  )
}

ProviderApplicationDetails.propTypes = {

};

ProviderApplicationDetails.defaultProps = {

};

export default ProviderApplicationDetails;