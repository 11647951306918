import React, { useEffect, useState, useContext } from 'react';
import { LinearProgress, Popover, Button, Container, Typography, Table, TableRow, TableCell, TableBody, TableHead, IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ListHeader from './Partials/ListHeader';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core';
import { formStyles } from "../Styles/forms";
import { GenericAPI } from '../API/generic';
import moment from 'moment';
import { Constants } from '@actyvyst/common'
import BookingDetails from '../Forms/BookingDetails';
import _ from 'lodash';
import { ActivityAPI } from '../API/activity';
import { ProviderContext } from '../Context/ProviderContext';

const { BookingStates, BookingTypes } = Constants;


const bookingStatusText = {
  [BookingStates.CREATED]: 'forms.booking.created',
  [BookingStates.WAITING_FOR_CONFIRMATION]: 'forms.booking.confirmationRequired',
  [BookingStates.CONFIRMED]: 'forms.booking.confirmed',
  [BookingStates.REJECTED_BY_PROVIDER]: 'forms.booking.rejected',
  [BookingStates.CANCELLED_BY_CUSTOMER]: 'forms.booking.cancelled'
}


const useStyles = makeStyles(theme => ({
  ...formStyles(theme),
  iconButtonHover: {
    "&:hover, &.Mui-focusVisible": { color: theme.palette.secondary.main }
  },
  deleteButtonHover: {
    "&:hover, &.Mui-focusVisible": { color: theme.palette.error.main }
  },
  sortIconActive: {
    color: theme.palette.secondary.main
  },
  sortIcon: {
    color: 'lightgray'
  },
  checked: {}
}));

const BookingList = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles(theme);
  const [shouldUpdate, setShouldUpdate] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [sort, setSort] = useState({ field: 'timestamp', direction: 1 });
  const [bookings, setBookings] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activeBooking, setActiveBooking] = React.useState(null);
  const { id: providerId } = useContext(ProviderContext);


  const handlePopoverClick = (event, selectedBooking) => {
    setActiveBooking(selectedBooking);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const changeBookingStatus = async (id, confirmed, message) => {
    try {
      if (confirmed) {
        await ActivityAPI.confirmBooking(id);
      } else {
        await ActivityAPI.rejectBooking(id, message);
      }

      setShouldUpdate(true);
    } catch (err) {
      console.log(err);
      alert(t('errors.genericMessage'));
      setShouldUpdate(true);
    }
  }

  const open = Boolean(anchorEl);
  const bookingPopoverId = open ? 'simple-popover' : undefined;

  useEffect(() => {
    setShouldUpdate(true);
  }, []);

  useEffect(() => {
    const loadBookings = async () => {
      try {
        setIsLoading(true);
        const b = await GenericAPI(`/activities/bookings`).fetchList();
        setBookings(b);
        setIsLoading(false);
      }
      catch (err) {
        console.log(err);
        alert(err);
      }
    }
    if (shouldUpdate) {
      loadBookings();
      setShouldUpdate(false);
    }
  }, [shouldUpdate]);

  const bookingTable = bookings.sort((item1, item2) => {
    const i1 = _.get(item1, sort.field, '');
    const i2 = _.get(item2, sort.field, '');
    const dateFields = ['timestamp', 'slot.startDate']
    if (dateFields.find((item) => item === sort.field)) {
      if (moment(item1[sort.field]).isBefore(item2[sort.field])) {
        return sort.direction;
      }
      else {
        return -sort.direction;
      }
    }
    if (i1.toLowerCase() < i2.toLowerCase()) { return -sort.direction } else { return sort.direction }
  }).map((booking) => {

    return (
      <TableRow key={booking.id} >
        <TableCell>
          <IconButton
            size="small"
            className={classes.iconButtonHover}
            onClick={(event) => handlePopoverClick(event, booking)}
          // onClick={() => { history.push(`bookings/${booking.id}`) }}
          >
            <InfoIcon />
          </IconButton>

        </TableCell>
        <TableCell >
          {
            booking.status === BookingStates.WAITING_FOR_CONFIRMATION ?
              <React.Fragment>
                <Button
                  aria-describedby={bookingPopoverId}
                  onClick={(event) => handlePopoverClick(event, booking)}
                  classes={{ root: classes.button, disabled: classes.disabledButton }}
                >
                  {`${t('forms.booking.actions.confirm')}/${t('forms.booking.actions.reject')}`}
                </Button>

              </React.Fragment>
              :
              <Typography >
                {booking.bookingType === BookingTypes.RESERVATION_ONLY ?
                  t('forms.booking.reservation_only') :
                  t(bookingStatusText[booking.status])
                }
              </Typography>
          }

        </TableCell>
        <TableCell>{booking.activity.name}</TableCell>
        <TableCell>{booking.variant.name}</TableCell>
        <TableCell>{moment(booking.timestamp).format('DD.MM.YYYY HH:mm')}</TableCell>
        <TableCell> {moment(booking.slot.startDate).format('DD.MM.YYYY HH:mm')}</TableCell>
        <TableCell>
          {booking.bookingType === BookingTypes.RESERVATION_ONLY ?
            '---' :
            `EUR ${Number.parseFloat(booking.totalPrice / 100).toFixed(2)}`
          }
        </TableCell>
        <TableCell> {booking.totalParticipants}</TableCell>
      </TableRow >
    )
  });

  return (
    <Container>
      <ListHeader
        title={t('generic.booking', { count: 2 })}
        newButtonLabel={t('forms.booking.new')}
        hideNew={true}
        onReload={() => { setShouldUpdate(true) }}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('tables.generic.actions')}</TableCell>
            <TableCell>
              {t('forms.booking.status')}
            </TableCell>
            <TableCell>
              {t('generic.activity')}
              <IconButton
                className={sort.field === 'activity.name' ? classes.sortIconActive : classes.sortIcon}
                onClick={() => {
                  const direction = sort.field === 'activity.name' ? -sort.direction : 1;
                  setSort({ field: 'activity.name', direction: direction })
                }}
              >
                {sort.field === 'activity.name' && sort.direction === -1 ?
                  <KeyboardArrowUpIcon /> :
                  <KeyboardArrowDownIcon />
                }
              </IconButton>
            </TableCell>
            <TableCell>
              {t('generic.variant')}
            </TableCell>
            <TableCell>
              {t('forms.booking.timestamp')}
              <IconButton
                className={sort.field === 'timestamp' ? classes.sortIconActive : classes.sortIcon}
                onClick={() => {
                  const direction = sort.field === 'timestamp' ? -sort.direction : 1;
                  setSort({ field: 'timestamp', direction: direction })
                }}
              >
                {sort.field === 'timestamp' && sort.direction === -1 ?
                  <KeyboardArrowUpIcon /> :
                  <KeyboardArrowDownIcon />
                }
              </IconButton>
            </TableCell>
            <TableCell>
              {t('forms.booking.slotStart')}
              <IconButton
                className={sort.field === 'slot.startDate' ? classes.sortIconActive : classes.sortIcon}
                onClick={() => {
                  const direction = sort.field === 'slot.startDate' ? -sort.direction : 1;
                  setSort({ field: 'slot.startDate', direction: direction })
                }}
              >
                {sort.field === 'slot.startDate' && sort.direction === -1 ?
                  <KeyboardArrowUpIcon /> :
                  <KeyboardArrowDownIcon />
                }
              </IconButton>
            </TableCell>
            <TableCell>
              {t('generic.price')}
            </TableCell>
            <TableCell>
              {t('forms.booking.numParticipants')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ?
            <TableRow>
              <TableCell colSpan={2}>
                <LinearProgress color="secondary" />
              </TableCell>
            </TableRow>
            : bookingTable
          }
        </TableBody>
      </Table>
      <Popover
        id={bookingPopoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {activeBooking ?
          <BookingDetails
            activity={activeBooking.activity.name}
            variant={activeBooking.variant.name}
            slot={moment(activeBooking.slot.startDate).format('DD.MM.YYYY HH:mm')}
            // numParticipants={0}
            numParticipants={activeBooking.totalParticipants}
            price={`${Number.parseFloat(activeBooking.totalPrice / 100).toFixed(2).toString()} €`}
            // price={0}
            timestamp={moment(activeBooking.timestamp).format('DD.MM.YYYY HH:mm')}
            contact={activeBooking.booker}
            onConfirm={() => { changeBookingStatus(activeBooking.id, true); handlePopoverClose() }}
            onReject={(message) => { changeBookingStatus(activeBooking.id, false, message); handlePopoverClose() }}
            onClose={() => { handlePopoverClose() }}
          /> : <React.Fragment />
        }

      </Popover>
    </Container>
  )
}

export default BookingList;