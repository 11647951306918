import React, { useContext, useEffect, useState } from "react";
import {
  LinearProgress,
  Container,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  IconButton,
} from "@material-ui/core";
import { makeStyles, useTheme } from '@material-ui/core';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import EditIcon from '@material-ui/icons/Edit';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { DataContext } from "../Context/DataContext";
import { useHistory } from "react-router-dom";
import AlertDialog from "../Modules/AlertDialog";
import ListHeader from './Partials/ListHeader';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  iconButtonHover: {
    "&:hover, &.Mui-focusVisible": { color: theme.palette.secondary.main }
  },
  deleteButtonHover: {
    "&:hover, &.Mui-focusVisible": { color: theme.palette.error.main }
  },
  sortIconActive: {
    color: theme.palette.secondary.main
  },
  sortIcon: {
    color: 'lightgray'
  }
}));

const ProviderScreen = props => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const history = useHistory();
  const [alertOpen, setAlertOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [selectedForDeletion, setSelectedForDeletion] = useState(null);
  const { Provider } = useContext(
    DataContext
  );
  const [sort, setSort] = useState({ field: 'name', direction: 1 })


  useEffect(() => {
    if (Provider.data.length === 0 || Provider.shouldUpdate) {
      Provider.fetchList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Provider.shouldUpdate]);

  const handleDeleteAlert = result => {
    setAlertOpen(alertOpen ? false : true);
    if (result && selectedForDeletion) {
      Provider.deleteItem(selectedForDeletion);
    }
  };

  const providerTable = Provider.data.sort((item1, item2) => {
    const i1 = item1[sort.field] ? item1[sort.field].toLowerCase() : '';
    const i2 = item2[sort.field] ? item2[sort.field].toLowerCase() : '';
    if (i1 < i2) { return -sort.direction } else { return sort.direction }
  }).map(p => (
    <TableRow key={p.id}>
      <TableCell>
        <IconButton
          size="small"
          className={classes.deleteButtonHover}
          onClick={() => { alert(`Delete ${p.id}`) }}
        >
          <HighlightOffIcon />
        </IconButton>
        <IconButton
          size="small"
          className={classes.iconButtonHover}
          // style={{ color: 'red', '&:hover': { color: 'blue' } }}
          onClick={() => { history.push(`providers/${p.id}`) }}
        >
          <EditIcon />
        </IconButton>
      </TableCell>
      {/* <TableCell
        onClick={() => {
          setSelectedProvider(p.id);
        }}
      >
        {selectedProvider === p.id ? "Yes" : "No"}
      </TableCell> */}
      <TableCell>
        {p.name}
      </TableCell>
      <TableCell>{p.description}</TableCell>
    </TableRow>
  ));
  return (
    <Container>
      {/* <BreadCrumbs
        links={[
          { title: t('generic.provider', { count: 2 }), path: "/providers" },
        ]}
      /> */}
      <ListHeader
        title={t('generic.provider', { count: 2 })}
        newButtonLabel={t('forms.provider.new')}
        onNew={() => history.push('/providers/new')}
        onReload={() => { Provider.setShouldUpdate(true) }}
      />

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('tables.generic.actions')}</TableCell>
            {/* <TableCell>Selected</TableCell> */}
            <TableCell>
              Name
              <IconButton
                className={sort.field === 'name' ? classes.sortIconActive : classes.sortIcon}
                onClick={() => {
                  const direction = sort.field === 'name' ? -sort.direction : 1;
                  setSort({ field: 'name', direction: direction })
                }}
              >
                {sort.field === 'name' && sort.direction === -1 ?
                  <KeyboardArrowUpIcon /> :
                  <KeyboardArrowDownIcon />
                }
              </IconButton>
            </TableCell>
            <TableCell>Beschreibung</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Provider.loading ? (
            <TableRow>
              <TableCell colSpan={2}>
                <LinearProgress color="secondary" />
              </TableCell>
            </TableRow>
          ) : (
              providerTable
            )}
        </TableBody>
      </Table>
      <AlertDialog
        open={alertOpen}
        title="Anbieter löschen"
        callback={handleDeleteAlert}
        message="Möchten Sie den ausgewählten Anbieter wirklich löschen?"
      />
    </Container>
  );
};

export default ProviderScreen;
