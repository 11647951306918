const JSONAPISerializer = require('jsonapi-serializer').Serializer;

module.exports.BookingSerializer = new JSONAPISerializer('booking', {
    keyForAttribute: 'camelCase',
    attributes: ['slot', 'booker', 'bookingType', 'reservation', 'priceBreakdown'],
    booker: {
        attributes: ['name', 'email', 'phone']
    },
    priceBreakdown: {
        attributes: ['currency', 'categories'],
        categories: {
            attributes: ['id', 'numTickets', 'name', 'description', 'price']
        }
    }
});

module.exports.BookingConfirmationSerializer = new JSONAPISerializer('bookingConfirmation', {
    keyForAttribute: 'camelCase',
    attributes: ['status'],
})