import React from "react";
import { useParams, useLocation } from "react-router-dom";
import { Typography, Grid, Box, makeStyles } from "@material-ui/core";
import ActivityForm from "../Forms/ActivityForm";
import VariantForm from "../Forms/VariantForm";
import ProviderForm from "../Forms/ProviderForm";
import CategoryForm from "../Forms/CategoryForm";
import queryString from 'query-string';
import config from '../Config/config';
import AppPreview from '../Modules/AppPreview';

const formComponents = [
  { path: "/activities/", component: ActivityForm },
  { path: "/variants/", component: VariantForm },
  { path: "/providers/", component: ProviderForm },
  { path: "/categories/", component: CategoryForm }
];

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    minHeight: '100%'
  },
  headerRow: {
    flex: '0 1 auto'
  },
  boxRow: {
    display: 'flex',
    flexFlow: 'column',
    flex: '1 1 auto',
    overflowY: 'auto',
    backgroundColor: 'green'
  },
  footerRow: {
    flex: '0 1 40px'
  }
}));


const FormScreen = props => {
  const classes = useStyles();
  const { id } = useParams();
  const location = useLocation();
  const match = formComponents.find(item => {
    return location.pathname.startsWith(item.path);
  });
  const q = queryString.parse(location.search);
  const language = q.language || config.defaultContentLanguage;

  const FormComponent = match
    ? match.component
    : () => <Typography>Component not found</Typography>;

  return (
    <div style={{ display: 'flex' }}>
      <div >
        <FormComponent id={id} language={language} />
      </div>
      {/* <div style={{ width: '30%' }}>
        <AppPreview />
      </div> */}
    </div>

  );
};

export default FormScreen;
