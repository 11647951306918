import React, { useContext, useState } from 'react'
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { LinearProgress, Box, Grid, Link, Divider, FormControl, Button, Table, TableRow, TableCell, TableBody, Tabs, Tab } from '@material-ui/core';
import ListHeader from '../Partials/ListHeader';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { ProviderContext } from '../../Context/ProviderContext';
import { UserContext } from '../../Context/UserContext';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PartnerState from '../../Modules/PartnerState';

const useStyles = makeStyles((theme) => ({
  firstCol: { width: '10%' },
  nestedFirstCol: { width: '20%' },
  checked: {}
}))

const UserTab = () => {
  const { user, logout } = useContext(UserContext);
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();

  const role = user.isAdmin === true ? t('forms.profile.adminRole') : t('forms.profile.providerRole')
  return (
    <Grid container>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={9}>
        <Typography variant="h4" color="primary">{`${user.firstName} ${user.lastName}`}</Typography>
      </Grid>
      <Grid item container xs={3} alignItems="center" justify="flex-end" >
        <FormControl >
          <Button
            variant="contained"
            onClick={() => logout()}
          >
            {t('forms.profile.actions.logout')}
          </Button>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12} style={{ paddingTop: theme.spacing(2) }}>
        <Table>
          <TableBody>
            <TableRow><TableCell className={classes.firstCol}>{t('forms.profile.role')}</TableCell><TableCell>{role}</TableCell></TableRow>
            <TableRow><TableCell className={classes.firstCol}>{t('generic.email')}</TableCell><TableCell>{user.email}</TableCell></TableRow>
            <TableRow>
              <TableCell className={classes.firstCol}>{t('forms.profile.password')}</TableCell>
              <TableCell>
                <Grid container>
                  {'*****'}
                  <Link href={`${process.env.REACT_APP_ACTYVYST_COM}/forgotpassword`} style={{ paddingLeft: 15 }} target="_blank"><Typography variant="caption">Ändern</Typography></Link>
                </Grid>
              </TableCell>
            </TableRow>

          </TableBody>
        </Table>
      </Grid>
    </Grid>
  )
}

export default UserTab
