import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DataContext } from "../../Context/DataContext";
import { Typography, Container, Grid, LinearProgress } from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import { formStyles } from "../../Styles/forms";
import { makeLanguageFields } from '../../Modules/Forms/FormHelper';
import FormHeader from '../Partials/FormHeader';
import FormFooter from '../Partials/FormFooter';
import config from '../../Config/config';
import BreadCrumbs from "../../Modules/Nav/BreadCrumbs";
import _ from "lodash";
import { useHistory } from 'react-router-dom';
import GOSnackBar from '../../Modules/Forms/GOSnackBar'
import categoryValidationSchema from './validation';
import CategoryFormUI from './CategoryFormUI';

const useStyles = makeStyles(theme => ({
  ...formStyles(theme),
}))


const CategoryForm = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const { Category } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(true);
  const [statusMessage, setStatusMessage] = useState({
    open: false,
    message: "",
    variant: ""
  });
  const initialData = {
    id: "new",
    translations: makeLanguageFields({ name: '', description: '' }),
    published: false
  };
  const [categoryData, setCategoryData] = useState({ ...initialData });
  const [error, setError] = useState(null);
  const history = useHistory();

  const handleSubmit = (values, formikActions) => {
    const saveData = async (isNew) => {

      try {
        const operation = isNew ? Category.createItem : Category.updateItem;
        const newData = await operation({
          ...values
        });
        setCategoryData(newData);
        formikActions.resetForm(newData);
        formikActions.setStatus({ success: true });
        setStatusMessage({
          open: true,
          message: t("forms.generic.messages.success"),
          variant: "success"
        });
      } catch (err) {
        console.log('handleSubmit ERROR');
        console.log(err.message);

        formikActions.setStatus({ success: false })
        formikActions.setSubmitting(false)
        formikActions.setErrors({ submit: err.message })

        setStatusMessage({
          open: true,
          message: t("forms.generic.messages.saveError", { error: err.message }),
          variant: "error"
        });
      }
    };
    saveData(values.id === "new");
  }

  useEffect(() => {
    setIsLoading(false);
    if (props.id === "new") {
      setIsLoading(false);
      setCategoryData({ ...initialData });
    } else {
      const fetchData = async () => {
        try {
          setIsLoading(true);
          const data = await Category.fetchItem(props.id);
          const newCategory = _.merge({},
            initialData,
            data
          );

          setCategoryData(newCategory);
          setError(null);
          setIsLoading(false);
        } catch (err) {
          setError(err.message);
          setIsLoading(false);
        }
      };
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id, props.language]);

  const validationSchema = categoryValidationSchema(props.language, t);

  return (
    <Container>
      <BreadCrumbs
        links={[
          { title: t("generic.category", { count: 2 }), path: "/categories" },
          { title: t("forms.category.edit") }
        ]}
      />

      <Grid container
        justify="flex-start"
        alignItems="flex-start"
        className={classes.formContainer}
      >
        <Grid item xs={12}>
          {isLoading ? (
            <LinearProgress color="secondary" style={{ width: "100%" }} />
          ) : error ? (
            <Typography>{error}</Typography>
          ) : (
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    ...categoryData
                  }}
                  onSubmit={handleSubmit}
                  validationSchema={validationSchema}
                  validateOnBlur={true}
                  validateOnChange={false}
                  render={(formikProps) => {
                    const onSave = () => {

                      formikProps.validateForm()
                        .then(() => {
                          if (!formikProps.isValid) {
                            setStatusMessage({
                              open: true,
                              message: t("forms.generic.messages.validationError"),
                              variant: "error"
                            });
                          } else {
                            formikProps.handleSubmit();
                          }
                        }).catch((err) => {
                          console.log('onSave ERROR');
                          setStatusMessage({
                            open: true,
                            message: t("forms.generic.messages.saveError", { error: err.message }),
                            variant: "error"
                          });
                        })
                    }
                    const onLanguageChange = (newLanguage) => {
                      history.push({ pathname: `/categories/${props.id}`, search: `language=${newLanguage}` });
                    }

                    return (
                      <Form>
                        <FormHeader
                          {...formikProps}
                          title={props.id === "new"
                            ? t("forms.category.new")
                            : t("forms.category.edit")}
                          language={props.language}
                          onLanguageChange={onLanguageChange}
                          onSave={onSave}
                        />
                        <CategoryFormUI
                          {...formikProps}
                          dirty={formikProps.dirty}
                          contentLanguage={props.language}
                        />
                        <Grid item xs={12}>
                          <FormFooter
                            {...formikProps}
                            onSave={onSave}
                          />
                        </Grid>

                      </Form>
                    )
                  }}
                />
              )}
        </Grid>
      </Grid>
      <GOSnackBar
        open={statusMessage.open}
        messagetext={statusMessage.message}
        closeHandle={() => { setStatusMessage({ ...statusMessage, open: false }) }}
        variant={statusMessage.variant}
      />
    </Container>
  );
}

CategoryForm.propTypes = {
  id: PropTypes.string,
  language: PropTypes.string
};

CategoryForm.defaultProps = {
  id: "new",
  language: config.defaultContentLanguage
};


export default CategoryForm;