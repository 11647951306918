import React, { useEffect, useState } from 'react';
import { LinearProgress, Container, TableRow, TableCell, TableBody, TableHead, IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ListHeader from './Partials/ListHeader';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core';
import { GenericAPI } from '../API/generic';

import moment from 'moment';

const useStyles = makeStyles(theme => ({
  iconButtonHover: {
    "&:hover, &.Mui-focusVisible": { color: theme.palette.secondary.main }
  },
  deleteButtonHover: {
    "&:hover, &.Mui-focusVisible": { color: theme.palette.error.main }
  },
  sortIconActive: {
    color: theme.palette.secondary.main
  },
  sortIcon: {
    color: 'lightgray'
  }
}));

const EventList = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles(theme);
  const [shouldUpdate, setShouldUpdate] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [sort, setSort] = useState({ field: 'startTime', direction: -1 });
  const [events, setEvents] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [filter, setFilter] = useState({ from: '20200101', to: '20200301' });
  const [currentPage, setCurrentPage] = useState(0);
  const [newPage, setNewPage] = useState(1);
  const [maxPage, setMaxPage] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [pageSize, setPageSize] = useState(20);

  useEffect(() => {
    setShouldUpdate(true);
  }, [newPage, sort]);

  useEffect(() => {
    const loadEvents = async () => {
      try {
        setIsLoading(true);
        const { result, meta } = await GenericAPI('/activities/events', { includeMeta: true })
          .fetchList({
            filter: filter,
            page: { number: newPage, size: pageSize },
            sort: `${sort.direction < 0 ? '-' : ''}${sort.field}`
          });
        if (meta) { setMaxPage(meta.maxPage); setCurrentPage(meta.page) }
        console.log(JSON.stringify(result));

        setEvents(result);
        setIsLoading(false);
      }
      catch (err) {
        console.log(err);
        alert(err);
      }
    }
    if (shouldUpdate) {
      loadEvents();
      setShouldUpdate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpdate]);

  const eventTable = events.map((event) => {

    return (
      <TableRow key={event.id}>
        <TableCell>
          <IconButton
            size="small"
            className={classes.iconButtonHover}
            onClick={() => { history.push(`events/${event.id}`) }}
          >
            <InfoIcon />
          </IconButton>
        </TableCell>
        <TableCell>{moment(event.startTime).format('DD.MM.YYYY HH:mm')}</TableCell>
        <TableCell>{event.description}</TableCell>
        <TableCell>{event.address}</TableCell>
      </TableRow >
    )
  });

  return (
    <Container>
      <ListHeader
        title={t('generic.event', { count: 2 })}
        newButtonLabel={t('forms.event.actions.new')}
        hideNew={true}
        showPaginator={true}
        page={currentPage}
        maxPage={maxPage}
        setPage={setNewPage}
        onReload={() => { setShouldUpdate(true) }}
      />

      <TableHead>
        <TableRow>
          <TableCell>{t('tables.generic.actions')}</TableCell>

          <TableCell>
            {t('forms.event.startTime')}
            <IconButton
              className={sort.field === 'startTime' ? classes.sortIconActive : classes.sortIcon}
              onClick={() => {
                const direction = sort.field === 'startTime' ? -sort.direction : 1;
                setSort({ field: 'startTime', direction: direction })
              }}
            >
              {sort.field === 'startTime' && sort.direction === -1 ?
                <KeyboardArrowUpIcon /> :
                <KeyboardArrowDownIcon />
              }
            </IconButton>
          </TableCell>
          <TableCell>
            {t('generic.description')}
          </TableCell>
          <TableCell>
            {t('generic.location')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {isLoading ?
          <TableRow>
            <TableCell colSpan={2}>
              <LinearProgress color="secondary" />
            </TableCell>
          </TableRow>
          : eventTable
        }
      </TableBody>
      {/* <TableFooter>
                <Paginator
                    onForward={() => { if (currentPage < maxPage) setNewPage(currentPage + 1) }}
                    onBack={() => { if (currentPage > 1) setNewPage(currentPage - 1) }}
                    page={currentPage}
                    maxPage={maxPage}
                />
            </TableFooter> */}
    </Container>
  )
}

export default EventList;