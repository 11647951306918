import React from 'react'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

export default function ProviderRequired() {
  return (
    <Container>
      <Typography variant="h1">Bitte wählen Sie einen Anbieter</Typography>
    </Container>
  )
}
