import React, { useContext, useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { UserContext } from '../Context/UserContext';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Grid, FormControl, FormHelperText, TextField, Button, Card, CardContent, CardActions } from '@material-ui/core';
import { formStyles } from '../Styles/forms';

const useStyles = makeStyles((theme) => ({
  ...formStyles(theme),
  textField: {
    width: '100%',
    minWidth: '100%'
  },
  card: {
    marginTop: 10,
    marginBottom: 10
  },

}));

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const LoginScreen = (props) => {
  const query = useQuery();
  const email = query.get("email") || '';
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { user, isLoggingIn, login } = useContext(UserContext);
  const [error, setError] = useState(null)
  const formik = useFormik({
    initialValues: {
      email: email,
      password: ''
    },
    onSubmit: values => {
      const asyncLogin = async () => {
        try {
          await login(values.email, values.password);
        } catch (error) {
          setError(t('forms.login.error'))
        }
      }
      setError(null);
      asyncLogin();
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Keine gültige E-Mail Adresse.')
        .required('Eingabe erforderlich'),
      password: Yup.string()
        .required('Eingabe erforderlich')
    }),
  });

  useEffect(() => {
    if (user) {
      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Container >
      <Grid container style={{ marginTop: 10, marginBottom: 10 }}>

        <Typography variant='h6'>{t('forms.login.title')}</Typography>

        <Grid item xs={12} >
          <Grid item lg={7} md={9} xs={12}>
            <Card className={classes.card}>
              <form onSubmit={formik.handleSubmit} style={{ width: '100%', minWidth: '100%' }}>

                <CardContent>
                  <Grid container>
                    {error ?
                      <Grid item xs={12} style={{ minWidth: '100%' }}>
                        <Typography color="error">{t('forms.login.error')}</Typography>
                      </Grid>
                      : undefined
                    }
                    <Grid item xs={12} style={{ minWidth: '100%' }}>
                      <FormControl className={classes.textField}>
                        <TextField id="email" margin="normal" name="email" variant="outlined" label={t('email')} aria-describedby="email-helper-text"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <FormHelperText id="email-helper-text" error={formik.errors.email && formik.touched.email}>
                          {formik.errors.email && formik.touched.email ? formik.errors.email : ''}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} style={{ minWidth: '100%' }}>
                      <FormControl className={classes.textField}>
                        <TextField id="password" variant="outlined" margin="normal" name="password" label={t('password')} type="password" aria-describedby="password-helper-text"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </FormControl>
                      <FormHelperText id="password-helper-text" error={formik.errors.password && formik.touched.password}>
                        {formik.errors.password && formik.touched.password ? formik.errors.password : ''}
                      </FormHelperText>
                    </Grid>
                    {/* <Grid item xs={12} style={{ minWidth: '100%' }}>
                                            <Grid container justify="flex-end">
                                                <Typography><a href="#">Passwort vergessen?</a></Typography>
                                            </Grid>
                                        </Grid> */}

                  </Grid>

                </CardContent>
                <CardActions>
                  <Grid container justify="space-between">

                    <FormControl>
                      <Button
                        className={classes.button}
                        variant="contained"
                        type="submit"
                        disabled={isLoggingIn}
                      >
                        {isLoggingIn ? 'Loading...' : t('forms.login.actions.login')}
                      </Button>
                    </FormControl>
                    <Typography><a href={`${process.env.REACT_APP_ACTYVYST_COM}/forgotpassword`} target="_blank" rel="noopener noreferrer">Passwort vergessen?</a></Typography>
                  </Grid>

                </CardActions>
              </form>

            </Card>
          </Grid>
          <Grid item xs={5}></Grid>
        </Grid>
      </Grid>

    </Container >
  )
}

export default LoginScreen;