import React from 'react'
import Typography from '@material-ui/core/Typography';

export default function ProviderInfo({ providerName }) {
  return (
    <div>
      <Typography variant="caption" style={{ margin: 0, padding: 0 }}>Angemeldet als:</Typography>
      <Typography style={{ margin: 0, padding: 0, lineHeight: 1 }}>{providerName}</Typography>
    </div>
  )
}

