import React, { useState } from 'react';
import { Dialog, Container } from '@material-ui/core';
import AlertDialog from '../../Modules/AlertDialog';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";

const FormDialog = (props) => {
  const { t } = useTranslation();
  const [alertOpen, setAlertOpen] = useState(false);
  const closeDialog = () => {
    if (props.dirty === true) {
      setAlertOpen(true);
    } else {
      props.onClose();
    }
  }
  return (
    <Dialog
      {...props}
      disableBackdropClick={props.disableBackdropClick}
      open={props.open}
      maxWidth={props.maxWidth}
      fullWidth={props.fullWidth}
      onClose={props.onClose}
    >
      <Container style={{ width: '100%', padding: 16 }}>
        {props.render(closeDialog)}
        <AlertDialog
          open={alertOpen}
          title={t('forms.generic.messages.unsavedDataTitle')}
          message={t('forms.generic.messages.unsavedData')}
          callback={(result) => {
            setAlertOpen(false);
            if (result === true) {
              props.onClose();
            }
          }}
        />
      </Container>
    </Dialog>
  )
}

FormDialog.propTypes = {
  open: PropTypes.bool,
  dirty: PropTypes.bool,
  onClose: PropTypes.func
}

FormDialog.defaultProps = {
  open: false,
  dirty: false,
  maxWidth: 'sm',
  fullWidth: true,
  disableBackdropClick: true,
  onClose: () => { alert('onClose not defined') }
}

export default FormDialog;
