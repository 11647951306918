import * as Yup from "yup";

export const ValidationConstants = {
    booker: {
        name: {
            min: 3,
            max: 50
        },
        phone: {
            min: 0,
            max: 20
        },
        email: {
            min: 0,
            max: 50
        }
    },
    reservation: {
        numParticipants: {
            minVal: 1,
            maxVal: 100
        },
        comment: {
            min: 0,
            max: 200
        }
    }
}

export const ValidationSchema = (t) => {
    const v = ValidationConstants;
    return Yup.object().shape({
        booker: Yup.object().shape({
            name: Yup.string()
                .min(v.booker.name.min)
                .max(v.booker.name.max),
            email: Yup.string()
                .email(t("forms.contact.validations.email")),
            phone: Yup.string()
                .max(v.booker.phone.max)
        }),
        reservation: Yup.object().shape({
            numParticipants: Yup.number()
                .integer()
                .min(v.reservation.numParticipants.minVal)
                .max(v.reservation.numParticipants.maxVal),
            comment: Yup.string()
                .max(v.reservation.comment.max)
        })
    });
};

