import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import SlotFormUI from "./SlotFormUI";
import moment from "moment";
import { DataContext } from "../../Context/DataContext";
import { Typography, Container } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { formStyles } from '../../Styles/forms';
import AlertDialog from '../../Modules/AlertDialog';
import axios from 'axios';
axios.defaults.baseURL = process.env.REACT_APP_API_HOST;


const useStyles = makeStyles((theme) => ({
  ...formStyles(theme),
}));


const SlotForm = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const { Slot } = useContext(DataContext);
  const initialData = {
    id: "new",
    activity: "",
    variant: "",
    startDate: moment(props.selectedDate).toDate(),
    endDate: moment(props.selectedEndDate).toDate(),
    startTime: props.selectedStartTime,
    endTime: props.selectedEndTime,
    maxParticipants: 0,
    availableSlots: 0,
    isMultiDay: !moment(props.selectedDate).isSame(
      props.selectedEndDate,
      "day"
    ),
    published: true
  };
  const [slotData, setSlotData] = useState({ ...initialData });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false)

  useEffect(() => {
    setIsLoading(false);
    if (props.id === "new") {
      setIsLoading(false);
      setSlotData({ ...initialData });
    } else {

      const fetchData = async () => {

        try {
          setIsLoading(true);
          const s = await Slot.fetchItem(props.id);
          // console.log("SLOT: ", JSON.stringify(s));
          const newSlot = {
            id: s.id,
            activity: s.activity.id,
            variant: s.variant.id,
            maxParticipants: s.maxParticipants,
            availableSlots: s.availableSlots,
            startDate: moment(s.startDate).toDate(),
            endDate: moment(s.endDate).toDate(),
            startTime: moment(s.startDate).format("HH:mm"),
            endTime: moment(s.endDate).format("HH:mm"),
            isMultiDay: !moment(s.startDate).isSame(s.endDate, "day"),
            published: s.published
          };
          // console.log(JSON.stringify(newSlot));

          setSlotData(newSlot);
          setError(null);
          setIsLoading(false);
        } catch (err) {
          setError(err.message);
          setIsLoading(false);
        }
      };
      fetchData();
      // fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  useEffect(() => { }, [props.selectedDate]);

  const deleteSlot = async () => {
    try {
      await Slot.deleteItem(props.id);
      props.onClose();
    } catch (err) {
      console.log(err);
      alert(t('forms.slot.errors.deleteFailed'));
    }
  }

  const handleSubmit = (values, formikActions) => {
    const saveData = async (isNew) => {
      setIsLoading(true);
      const operation = isNew ? Slot.createItem : Slot.updateItem;
      const startString = moment(values.startDate).format(`YYYY-MM-DD ${values.startTime}`);
      const endString = moment(values.endDate).format(`YYYY-MM-DD ${values.endTime}`);
      const data = {
        variant: values.variant,
        startDate: moment(startString).toDate(),
        endDate: moment(endString).toDate(),
        published: values.published
      };
      if (!isNew) { data.id = props.id }
      try {
        await operation(data);
        setIsLoading(false);

        props.onClose();

      } catch (err) {
        setIsLoading(false);

        alert(err.message);
      }
    };

    saveData(values.id === "new");

  }

  return (
    <React.Fragment>
      <Container style={{ width: '100%', padding: 16 }}>
        <CloseIcon className={classes.closeicon} onClick={props.onClose} />
        <Typography variant="h6">
          {props.id === "new" ? t("forms.slot.actions.new") : t("forms.slot.actions.edit")}
        </Typography>

        {isLoading ? (
          <Typography>Loading</Typography>
        ) : error ? (
          <Typography>{error}</Typography>
        ) : (
              <Formik
                initialValues={{
                  ...slotData
                }}
                onSubmit={handleSubmit}
                // component={SlotFormUI}
                render={(formikProps) => {
                  return (
                    <React.Fragment>
                      <SlotFormUI {...formikProps}
                        onDelete={() => {
                          setAlertOpen(true);
                          // deleteSlot();
                        }}
                        onClose={() => { Slot.setShouldUpdate(true); props.onClose() }}
                      />
                      <AlertDialog
                        open={alertOpen}
                        title={t('forms.generic.messages.confirmDeleteTitle')}
                        message={t('forms.slot.messages.confirmDelete')}
                        callback={(result) => {
                          if (result === true) {
                            deleteSlot();
                          }
                          setAlertOpen(false);
                        }}
                      />
                    </React.Fragment>
                  )
                }}
              />
            )}
        <Typography>id: {slotData.id}</Typography>
      </Container>
    </React.Fragment>
  );
};


SlotForm.propTypes = {
  id: PropTypes.string,
  selectedDate: PropTypes.instanceOf(Date)
};

SlotForm.defaultProps = {
  id: "new",
  selectedDate: moment().toDate(),
  selectedStartTime: moment()
    .add(1, 'hour')
    .format("HH:00"),
  selectedEndTime: moment()
    .add(2, 'hour')
    .format("HH:00")
};

export default SlotForm;
