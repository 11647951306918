import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { useTranslation } from "react-i18next";


const RegisterError = (props) => {
  const { t } = useTranslation();
  const getErrorMessage = () => {
    switch (props.error) {
      default: return t('generic.error')
    }
  }
  const message = getErrorMessage();
  return (
    <Container>
      <Grid container style={{ marginTop: 10, marginBottom: 10 }}>
        <Typography>{message}</Typography>
      </Grid>
    </Container>
  )
}

export default RegisterError;