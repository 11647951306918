import React, { useEffect, useReducer, createContext } from 'react';
import AuthAPI from '../API/auth';
import { userReducer } from './UserReducer';
import jwtDecode from 'jwt-decode';
import axios from 'axios';

export const UserContext = createContext();

export const UserContextProvider = (props) => {
  const [state, dispatch] = useReducer(
    userReducer,
    {
      lastActivityTimestamp: Date.now(),
      token: null,
      refreshToken: null,
      isLoggingIn: false,
      loginError: null,
      axiosInterceptor: null,
      isInitialized: false,
    });

  useEffect(() => {
    initialize();
    window.addEventListener('storage', onLocalStorageChange);
    return () => {
      window.removeEventListener('storage', onLocalStorageChange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.isInitialized) {
      setInterceptor({ token: state.token, refreshToken: state.refreshToken });
      localStorage.setItem('tokens', JSON.stringify({ token: state.token, refreshToken: state.refreshToken }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.token, state.refreshToken])

  const onLocalStorageChange = () => {
    initialize();
  }

  const initialize = () => {
    const result = localStorage.getItem('tokens');
    const tokens = result ? JSON.parse(result) : { token: null, refreshToken: null };
    if (result) {
      setInterceptor({ token: tokens.token, refreshToken: tokens.refreshToken });
    }
    setTokens(tokens);
    dispatch({ type: 'SET_IS_INITIALIZED', payload: true });
  }

  const refreshAccessToken = async (oldTokens) => {
    try {
      let tokens = await AuthAPI.refreshAccessToken(oldTokens.token, oldTokens.refreshToken);
      setTokens(tokens);
      return tokens;
    } catch (err) {
      setError(err.message);
      throw err;
    }
  }

  const errInterceptor = function (tokens) {
    return function (error) {
      const callId = Math.round(Math.random() * 100);
      console.error(`INTERCEPTOR ERROR(${callId}): ${error.response ? error.response.status : 'No response'}`);
      console.error(`INTERCEPTOR ERROR(${callId}): ${error.config ? error.config.url : 'No config'}`);
      if (error.response &&
        error.response.status &&
        error.response.status === 403 &&
        !error.config.url.match(/accesstoken/g) &&
        !error.config.headers['repeat']) {
        return new Promise(function (resolve, reject) {
          console.error(`INTERCEPTOR REFRESH CALL(${callId}) BEGIN`)
          refreshAccessToken(tokens)
            .then(newTokens => {
              error.config.headers = {
                ...error.config.headers,
                "x-access-token": newTokens.token,
                "repeat": 'true'
              };
              error.config.baseURL = undefined;
              console.error(`INTERCEPTOR REFRESH CALL(${callId}) SUCCEEDED`)
              return resolve(axios.request(error.config));
            })
            .catch(err => {
              console.error(`INTERCEPTOR REFRESH CALL(${callId}) FAILED`)
              setError(err.message);
              return reject(error);
            });
        })
      } else {
        return Promise.reject(error);
      }
    };
  };

  const setInterceptor = (tokens) => {
    axios.defaults.headers.common = {
      ...axios.defaults.headers.common,
      "x-access-token": tokens.token
    };
    if (state.axiosInterceptor != null) {
      axios.interceptors.response.eject(state.axiosInterceptor);
    }
    if (!tokens.token) { return }
    const newInterceptor = axios.interceptors.response.use(function (response) {
      return response;
    }, errInterceptor(tokens));
    dispatch({ type: 'SET_AXIOS_INTERCEPTOR', payload: newInterceptor })
  }

  const setError = (err) => {
    dispatch({ type: 'SET_LOGIN_ERROR', payload: err });
  }

  const setTokens = (tokens) => {
    dispatch({ type: 'SET_TOKENS', payload: tokens });
  }

  const login = async (email, password) => {
    try {
      dispatch({ type: 'SET_IS_LOGGING_IN', payload: true });
      const tokens = await AuthAPI.login(email, password, 'cockpit');
      console.log(tokens.token);
      const decodedUser = jwtDecode(tokens.token);
      if ((decodedUser.acl & 4) !== 4 && (decodedUser.acl & 128) !== 128) {
        throw new Error('Insufficient privileges');
      }
      if ((decodedUser.acl & 128) != 128 && !decodedUser.provider) {
        throw new Error('No provider assigned to user');
      }
      setTokens(tokens);
    } catch (err) {
      dispatch({ type: 'SET_LOGIN_ERROR', payload: err.message });
      throw (err);
    }
  }

  const logout = () => {
    setTokens({ token: null, refreshToken: null });
  }

  const setLastActivityTimestamp = (timestamp) => {
    dispatch({ type: 'SET_LAST_ACTIVITY_TIMESTAMP', payload: timestamp })
  }

  let decodedUser = null;
  try {
    if (state.token) {
      decodedUser = jwtDecode(state.token);
      decodedUser.isAdmin = (decodedUser.acl & 128) === 128 ? true : false;
    } else {
      decodedUser = null;
    }
  } catch (err) {
    decodedUser = null;
  }
  console.log('decodedUser: ', decodedUser)
  console.log('token: ', state.token)

  return (
    <UserContext.Provider value={{
      user: decodedUser,
      login,
      logout,
      setLastActivityTimestamp,
      isLoggingIn: state.isLoggingIn,
      loginError: state.loginError,
      isInitialized: state.isInitialized,
    }}>
      {props.children}
    </UserContext.Provider>
  )
}