import React, { useContext, useEffect, useState } from 'react';
import { LinearProgress, Container, Table, TableRow, TableCell, TableBody, TableHead, IconButton } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import EditIcon from '@material-ui/icons/Edit';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { DataContext } from '../Context/DataContext';
import ListHeader from './Partials/ListHeader';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core';
import AlertDialog from '../Modules/AlertDialog';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
  iconButtonHover: {
    "&:hover, &.Mui-focusVisible": { color: theme.palette.secondary.main }
  },
  deleteButtonHover: {
    "&:hover, &.Mui-focusVisible": { color: theme.palette.error.main }
  },
  sortIconActive: {
    color: theme.palette.secondary.main
  },
  sortIcon: {
    color: 'lightgray'
  },
  tableCell: {
    display: 'inline-block',
    minWidth: '800px'
  }
}));

const VariantList = (props) => {
  const { Variant } = useContext(DataContext);
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles(theme);
  const [sort, setSort] = useState({ field: 'name', direction: 1 });
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState({
    title: t('forms.generic.messages.confirmDeleteTitle'),
    message: t('forms.variant.messages.confirmDelete'),
    buttons: [{ label: 'generic.yes', value: true }, { label: 'generic.no', value: false }],
    callback: (value) => { alert(value); setAlertOpen(false) }
  })
  // const [selectedId, setSelectedId] = useState(null);


  useEffect(() => {
    if (Variant.shouldUpdate) {
      Variant.fetchList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Variant.shouldUpdate]);

  const deleteVariant = async (id) => {
    try {
      await Variant.deleteItem(id);
    } catch (err) {
      console.log(err);
      setAlertContent({
        title: t('forms.generic.messages.deleteErrorTitle'),
        message: t('forms.variant.errors.deleteFailed'),
        buttons: [{ label: 'generic.ok', value: true }],
        callback: (result) => {
          setAlertOpen(false);
        }
      });
      setAlertOpen(true);

      // alert(t('forms.variant.errors.deleteFailed'));
    }
  }

  const variantTable = Variant.data.sort((item1, item2) => {
    const i1 = _.get(item1, sort.field, '').toLowerCase();
    const i2 = _.get(item2, sort.field, '').toLowerCase();
    if (i1 < i2) { return -sort.direction } else { return sort.direction }
  }).map(v =>
    <TableRow key={v.id}>
      <TableCell>
        <IconButton
          size="small"
          className={classes.deleteButtonHover}
          onClick={() => {
            // setSelectedId(v.id);
            setAlertContent({
              title: t('forms.generic.messages.confirmDeleteTitle'),
              message: t('forms.variant.messages.confirmDelete'),
              buttons: [{ label: 'generic.yes', value: true }, { label: 'generic.no', value: false }],
              callback: (result) => {
                if (result === true) {
                  deleteVariant(v.id)
                }
                setAlertOpen(false);
              }
            })
            setAlertOpen(true);
          }}
        >
          <HighlightOffIcon />
        </IconButton>
        <IconButton
          size="small"
          className={classes.iconButtonHover}
          // style={{ color: 'red', '&:hover': { color: 'blue' } }}
          onClick={() => { history.push(`variants/${v.id}`) }}
        >
          <EditIcon />
        </IconButton>
      </TableCell>
      <TableCell >{v.activity.name}</TableCell>
      <TableCell> {v.name}</TableCell>
      <TableCell>{v.maxParticipants}</TableCell>
      <TableCell>{v.cutoffPeriod}</TableCell>
    </TableRow >)
  return (
    <Container>
      <ListHeader
        title={t('generic.variants')}
        newButtonLabel={t('forms.variant.actions.new')}
        onNew={() => history.push('/variants/new')}
        onReload={() => { Variant.setShouldUpdate(true) }}
      />

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('tables.generic.actions')}</TableCell>
            <TableCell >
              {t('generic.activity')}
              <IconButton
                className={sort.field === 'activity.name' ? classes.sortIconActive : classes.sortIcon}
                onClick={() => {
                  const direction = sort.field === 'activity.name' ? -sort.direction : 1;
                  setSort({ field: 'activity.name', direction: direction })
                }}
              >
                {sort.field === 'activity.name' && sort.direction === -1 ?
                  <KeyboardArrowUpIcon /> :
                  <KeyboardArrowDownIcon />
                }
              </IconButton>
            </TableCell>
            <TableCell>
              {t('generic.name')}
              <IconButton
                className={sort.field === 'name' ? classes.sortIconActive : classes.sortIcon}
                onClick={() => {
                  const direction = sort.field === 'name' ? -sort.direction : 1;
                  setSort({ field: 'name', direction: direction })
                }}
              >
                {sort.field === 'name' && sort.direction === -1 ?
                  <KeyboardArrowUpIcon /> :
                  <KeyboardArrowDownIcon />
                }
              </IconButton>
            </TableCell>
            <TableCell>
              {t('forms.variant.maxParticipants')}
            </TableCell>
            <TableCell>
              {t('forms.variant.cutoffPeriod')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Variant.loading ?
            <TableRow>
              <TableCell colSpan={2}>
                <LinearProgress color="secondary" />
              </TableCell>
            </TableRow>
            : variantTable
          }
        </TableBody>
      </Table>
      <AlertDialog
        open={alertOpen}
        title={alertContent.title}
        buttons={alertContent.buttons}
        message={alertContent.message}
        callback={alertContent.callback}
      // callback={(result) => {
      //   if (result === true) {
      //     deleteVariant();
      //   }
      //   setAlertOpen(false);
      // }}
      />


    </Container>

  )
}

export default VariantList;