import * as Yup from "yup";

export const validationConstants = {
    name: {
        min: 3,
        max: 50
    },

    description: {
        min: 3,
        max: 200
    },
}

const categoryValidationSchema = (language, t) => {
    const v = validationConstants;
    return Yup.object().shape({
        translations: Yup.object().shape({
            [language]: Yup.object().shape({
                name: Yup.string()
                    .min(v.name.min, t('forms.activity.validation.min', { min: v.name.min }))
                    .max(v.name.max, t('forms.activity.validation.max', { max: v.name.max }))
                    .required(t('forms.activity.validation.required')),
                description: Yup.string()
                    .min(v.description.min, t('forms.activity.validation.min', { min: v.description.min }))
                    .max(v.description.max, t('forms.activity.validation.max', { max: v.description.max }))
                    .required(t('forms.activity.validation.required')),
            })
        })
    })
};

export default categoryValidationSchema;