import React from "react";
import GOFormikTextInput from "../../Modules/Forms/GOFormikTextInput";
import { Field } from "formik";
import { useTranslation } from "react-i18next";
import {
    Grid,
} from "@material-ui/core";
import { Prompt } from "react-router-dom";
import { validationConstants } from './validation';

import "moment/locale/de";
// import "react-phone-input-2/dist/style.css";
import countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/de.json"));



const CategoryFormUI = (props) => {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <Prompt
                when={props.dirty}
                message={location => t('forms.generic.messages.unsavedData')}
            />
            <Grid container style={{ margin: 0, padding: 0 }}>
                <Grid item xs={6}>
                    <Field
                        name={`translations.${props.contentLanguage}.name`}
                        as={GOFormikTextInput}
                        maxChars={validationConstants.name.max}
                        flag={props.contentLanguage}
                        labelText={t("generic.name")}
                    />
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={12}>
                    <Field
                        name={`translations.${props.contentLanguage}.description`}
                        as={GOFormikTextInput}
                        maxChars={validationConstants.description.max}
                        flag={props.contentLanguage}
                        multiline
                        rows="4"
                        rowsMax="8"
                        labelText={t("generic.description")}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default CategoryFormUI;