import * as Yup from "yup";


export const validationConstants = {
    name: {
        min: 3,
        max: 50
    },
    teaser: {
        min: 3,
        max: 50
    },
    description: {
        min: 3,
        max: 5000
    },
    hygiene: {
        max: 5000
    },
    location: {
        street: {
            min: 3,
            max: 50
        }
    },
    infos: {
        maxLines: 5,
        min: 3,
        max: 200
    },
    tags: {
        minLines: 1,
        maxLines: 3
    }
}

const activityValidationSchema = (language, t) => {
    const v = validationConstants;
    return Yup.object().shape({
        tags: Yup.array()
            .of(Yup.string()
                .required(t('forms.activity.validation.required')))
            .min(v.tags.minLines, t('forms.activity.validation.minTags', { minTags: v.tags.minLines }))
            .max(v.tags.maxLines, t('forms.activity.validation.maxTags', { maxTags: v.tags.maxLines })),
        provider: Yup.string().required(t('forms.activity.validation.required')),
        location: Yup.object().shape({
            zip: Yup.string()
                .matches(/(\d){4,5}/, t("forms.contact.validations.zip.max"))
                .required(t("forms.contact.validations.zip.req")),
            street: Yup.string()
                .min(v.location.street.min, t("forms.activities.validations.street.min"))
                .max(v.location.street.max, t("forms.activities.validations.street.max"))
                .required(t("forms.activity.validation.required")),
        }),

        translations: Yup.object().shape({
            [language]: Yup.object().shape({
                name: Yup.string()
                    .min(v.name.min, t('forms.activity.validation.min', { min: v.name.min }))
                    .max(v.name.max, t('forms.activity.validation.max', { max: v.name.max }))
                    .required(t('forms.activity.validation.required')),
                teaser: Yup.string()
                    .min(v.teaser.min, t('forms.activity.validation.min', { min: v.teaser.min }))
                    .max(v.teaser.max, t('forms.activity.validation.max', { max: v.teaser.max }))
                    .required(t('forms.activity.validation.required')),
                description: Yup.string()
                    .min(v.description.min, t('forms.activity.validation.min', { min: v.description.min }))
                    .max(v.description.max, t('forms.activity.validation.max', { max: v.description.max }))
                    .required(t('forms.activity.validation.required')),
                hygiene: Yup.string()
                    .max(v.description.max, t('forms.activity.validation.max', { max: v.description.max })),
                infos: Yup.array()
                    .of(Yup.string()
                        .max(v.infos.max, t('forms.activity.validation.max', { max: v.infos.max }))
                        .required(t('forms.activity.validation.required')))
                    .max(v.infos.maxLines, t('forms.activity.validation.maxInfos', { maxInfos: v.infos.maxLines }))
            })
        })
    })
};

export default activityValidationSchema;