import React from "react";
import MaterialBreadcrumbs from "@material-ui/core/Breadcrumbs";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { NavLink, withRouter } from "react-router-dom";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  breadcrumbContainer: {
    padding: theme.spacing(1, 2),
    backgroundColor: "rgb(249, 249, 249)"
  },
  link: {
    display: "flex",
    fontSize: 12
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20
  }
}));
function BreadCrumbs(props) {
  const classes = useStyles();
  const { links } = props;
  return (
    <Paper elevation={0} className={classes.breadcrumbContainer}>
      <MaterialBreadcrumbs separator="›" aria-label="breadcrumb">
        {links.map((link, index) => {
          if (link.path) {
            return (
              <NavLink key={`breadcrumb-${index}`} to={link.path} exact={true} className={classes.link}>
                {link.title}
              </NavLink>
            )
          } else {
            return (
              <Typography key={`breadcrumb-${index}`}>{link.title}</Typography>
            )
          }

        })}
      </MaterialBreadcrumbs>
    </Paper>
  );
}

export default withRouter(BreadCrumbs);
