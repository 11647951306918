import { Deserializer } from 'jsonapi-serializer';
import axios from 'axios';
import { ActyvystAPIError } from './APIError';
const deserializer = new Deserializer({ keyForAttribute: 'camelCase' });

const getProvidersForUser = async (userId) => {
    try {
        const url = `/auth/users/${userId}/providers`;
        const response = await axios.get(url);
        const user2Provider = await deserializer.deserialize(response.data);
        return user2Provider.providers;
    } catch (err) {
        throw err;
    }
}

const register = async ({ firstName, lastName, email, password, isProvider = true, verificationMethod = 'verify_by_link' }) => {
    try {
        const response = await axios.post('/auth/register', {
            firstName,
            lastName,
            email,
            password,
            isProvider,
            verificationMethod
        });
        const user = await deserializer.deserialize(response.data);
        return user;
    } catch (err) {
        console.log(err);
        throw ActyvystAPIError.createFromAxiosError(err);
    }
}

const login = async (email, password, device) => {
    try {
        let response = await axios.post('/auth/login', {
            email: email,
            password: password,
            channel: device
        });
        let tokens = await deserializer.deserialize(response.data);
        return {
            token: tokens.token,
            refreshToken: tokens.refreshToken
        };

    } catch (err) {
        throw err;
    }
};

const refreshAccessToken = async (oldToken, refreshToken) => {
    try {
        let response = await axios.post('/auth/accesstoken', {
            token: oldToken,
            refreshToken: refreshToken
        });
        let tokens = await deserializer.deserialize(response.data);
        return {
            token: tokens.token,
            refreshToken: tokens.refreshToken
        };
    } catch (err) {
        throw err;
    }
}

const checkAppHealth = async (version = null) => {
    try {
        let q = (version === null) ? '' : `?appversion=${version}`;
        let response = await axios.get(`/apphealth${q}`);
        let result = await deserializer.deserialize(response.data);
        return result;
    } catch (err) {
        throw (err);
    }
}

const getUserData = async (token) => {

    try {
        let options = {};
        if (token) {
            options = { headers: { 'Content-Type': 'application/json', 'x-access-token': token } }
        }

        const rq = Math.floor(Math.random() * 1000 + 1)
        const q = `/auth/user/data?rq=${rq}`;

        let response = await axios.get(q, options);
        let data = await deserializer.deserialize(response.data);

        return data;
    } catch (err) {
        throw err;
    }
}

const saveUserData = async (data) => {
    try {
        let voucherIds = [];
        if (data.vouchers) {
            for (let voucher of data.vouchers) {
                voucherIds.push(voucher.id);
            }
        }
        await axios.post('/auth/user/data', {
            vouchers: voucherIds,
            voucherSlots: data.voucherSlots
        });
    } catch (err) {
        throw err;
    }
}

const requestPasswordReset = async (email) => {
    try {
        const url = 'auth/requestpasswordreset';
        await axios.post(url, { email: email });
    } catch (err) {
        throw err;
    }
}

const resetPasswordByPin = async (email, pin, newPassword) => {
    try {
        const url = 'auth/resetpasswordbypin';
        await axios.post(url, { email: email, pin: pin, newPassword: newPassword });
    } catch (err) {
        throw (err);
    }
}

const createAnonymousUser = async () => {
    try {
        let response = await axios.post('/auth/createanonymous', {});
        let anonymousUser = await deserializer.deserialize(response.data);
        let tokens = await login(anonymousUser.email, 'wurst');
        return tokens;
    } catch (err) {
        throw err;
    }
}

const AuthAPI = {
    login,
    register,
    requestPasswordReset,
    resetPasswordByPin,
    refreshAccessToken,
    createAnonymousUser,
    getUserData,
    saveUserData,
    checkAppHealth,
    getProvidersForUser
};

export default AuthAPI;