import React from 'react';
import Typography from '@material-ui/core/Typography';

const GTCContent = () => {
  return (
    <React.Fragment>
      <Typography variant="h1">Allgemeine Geschäftsbedingungen für Anbieter</Typography>
      <p>Die actyvyst GmbH mit Sitz in der Gerresheimer Straße 153, 40233 Düsseldorf („actyvyst“), betreibt eine
      Online-Buchungsplattform, die für Endkunden über verschiedene Kanäle (z.B. App, Website, Partner-Websites)
      erreichbar ist („Plattform“). Die Plattform erlaubt es Anbietern von Freizeitaktivitäten, Reiseveranstaltern und Vermittlern
      touristischer Dienstleistungen („Anbieter“), ihre touristischen Dienstleistungen („Leistungen“) Endkunden anzubieten
      und Verträge zwischen Anbieter und Endkunde zu vermitteln und abzuschließen.
      actyvyst stellt ausschließlich die Plattform zur Präsentation und Vertragsabwicklung zur Verfügung und handelt als
      vom Anbieter autorisierter Handelsvertreter. actyvyst übernimmt keinerlei Haftung gegenüber dem Endkunden bezüglich der
      Leistungserbringung durch den Anbieter.</p>
      <h2>1. Geltungsbereich dieser Allgemeinen Geschäftsbedingungen</h2>
      <p>Diese Allgemeinen Geschäftsbedingungen („Anbieter-AGB“) regeln ausschließlich die Geschäftsbeziehung zwischen
      actyvyst und dem Anbieter. Die Geschäftsbeziehung zwischen actyvyst und dem Endkunden werden in einer separaten
      Vereinbarung geregelt.
      Die Geschäftsbeziehung zwischen dem Endkunden und dem Anbieter wird auf Grundlage der AGBs des Anbieters geregelt,
      die der Anbieter in der Plattform hinterlegen kann.</p>
      <h2>2. Registrierung als Anbieter</h2>
      <p>Nach erfolgreicher Registrierung auf der Plattform kann der Anbieter seine Leistungen über die Plattform Endkunden
      anbieten. Mit der Registrierung akzeptiert der Anbieter die Anbieter-AGB und bestätigt, dass er diese gelesen und
      verstanden hat.
      Der Anbieter verpflichtet sich, seine Leistungen als Unternehmer in Übereinstimmung mit den für ihn geltenden
      Steuerrichtlinien anzubieten.</p>
      <h2>3. Von actyvyst erbrachte Leistungen</h2>
      <p>Der Anbieter autorisiert actyvyst, (i) seine Leistungen entweder direkt oder mittels Vertriebspartnern Endkunden
      anzubieten, (ii) Verträge zwischen Endkunden und dem Anbieter im Namen des Anbieters abzuschließen und (iii)
      Zahlungen der Endkunden für die Leistungen des Anbieters entgegenzunehmen. Der Anbieter autorisiert actyvyst, für die
      Verarbeitung der Zahlungen Systeme von Drittanbietern („Zahlungsanbieter“) zu verwenden.</p>
      <h2>4. Pflichten des Anbieters</h2>
      <ul>
        <li>
          Der Anbieter garantiert, dass alle Informationen, die er zur Beschreibung seiner Leistung in der Plattform
          hinterlegt, zutreffend und ausreichend sind und dem Endkunden eine wahrheitsgemäße Darstellung der angebotenen
          Leistung bieten. Der Anbieter haftet für alle Ansprüche des Endkunden, die auf einer unzutreffenden Beschreibung seiner Leistung
          beruhen.
        </li>
        <li>
          Der Anbieter garantiert, dass die Durchführung seiner Leistungen im Einklang mit den im Land der Durchführung
          geltenden Gesetzen steht. Insbesondere garantiert der Anbieter, dass er alle geltenden Verbraucherschutzregeln
          beachtet und dass er über alle erforderlichen Lizenzen und Genehmigungen zur Durchführung seiner Leistungen
          verfügt. Auf Anfrage wird der Anbieter actyvyst Einsicht in die betreffenden Dokumente erlauben.
        </li>
        <li>
          Der Anbieter garantiert, dass er den Endkunden mit allen Informationen versorgt, die zur Durchführung der Leistung
          erforderlich sind (z.B. Treffpunkt und Uhrzeit, Kleidung, Ausrüstung).
        </li>
        <li>
          Der Anbieter garantiert, dass alle für ihn im Rahmen der Leistungserbringung tätigen Mitarbeiter über eine gültige
          Arbeitserlaubnis verfügen.
        </li>
        <li>
          Der Anbieter bietet nur von ihm selbst durchgeführte Leistungen an und agiert nicht als Vermittler eines
          Drittanbieters. Vermittelt der Anbieter dennoch Leistungen von Drittanbietern, so gilt er im Rahmen dieser
          Vereinbarung als Vertragspartner für diese Leistungen und haftet für alle im Zusammenhang mit der
          Leistungserbringung entstandenen Schäden.
        </li>
        <li>
          Der Anbieter garantiert, dass alle Preise und Gebühren, die er über die Plattform verfügbar macht, nicht höher als
          in anderen Vertriebskanälen inkl. seines Direktvertriebs sind.
        </li>
        <li>
          Der Anbieter garantiert, dass er über eine Haftpflichtversicherung verfügt, die alle Risiken im Zusammenhang mit
          der Durchführung seiner Leistungen abdeckt. Auf Anfrage wird der Anbieter actyvyst Einsicht in die betreffenden
          Dokumente erlauben.
        </li>
        <li>
          Der Anbieter verpflichtet sich, seinen Kunden reguläre Rechnungen auszustellen, die den Anforderungen der im Land
          der Durchführung der Leistung geltenden Steuergesetzgebung entsprechen.
        </li>
        <li>
          Der Anbieter verpflichtet sich, die digitalen Tickets der Endkunden mit Hilfe der kostenfreien actyvyst
          Anbieter-App zu validieren. Kosten, die durch das Unterlassen der Validierung entstehen, sind vom Anbieter zu
          tragen.
        </li>
        <li>
          Der Anbieter verpflichtet sich, die Darstellung seiner Leistung so zu gestalten, dass jederzeit ersichtlich ist,
          dass er der alleinige Erbringer der Leistung ist. Insbesondere darf nicht der Eindruck erweckt werden, dass
          actyvyst der Anbieter der Leistungen oder Teilen der Leistung ist.
        </li>
      </ul>
      <h2>5. Buchungsprozess</h2>
      <ul>
        <li>
          <b>Buchung auf Anfrage</b><br />
          Ist die Leistung des Anbieters nur „auf Anfrage“ buchbar, wird der Anbieter von actyvyst per Mail über die
          Buchungsanfrage informiert. Der Anbieter verpflichtet sich, auf die Anfrage des Kunden innerhalb des von ihm in
          der Plattform angegeben Zeitraums zu reagieren und die Anfrage entweder zu bestätigen oder abzulehnen.
        </li>
        <li>
          <b>Direktbuchung</b><br />
          Hat der Anbieter seine Leistung in der Plattform als „direkt buchbar“ gekennzeichnet, wird actyvyst unter
          Berücksichtigung der in der Plattform vom Anbieter hinterlegten Kontingente die Buchung unmittelbar verbindlich
          durchführen.
          actyvyst haftet nicht für die Richtigkeit der vom Endkunden im Rahmen des Buchungsprozesses übermittelten Angaben
          (z.B. Name, Adresse, Alter).
        </li>
      </ul>
      <h2>6. Inhalte und Schutzrechte</h2>
      <p>Der Anbieter gewährt actyvyst das nicht - exklusive, weltweite und kostenfreie Recht, die vom Anbieter in der Plattform
      zur Verfügung gestellten Inhalte zu verwenden, zu veröffentlichen, zu kopieren, an Dritte zu lizenzieren und zu
      modifizieren(z.B.zu übersetzen, zu formatieren, zu kürzen etc.).
      Der Anbieter garantiert, dass er für die von ihm in der Plattform zur Verfügung gestellten Inhalte über alle
      erforderlichen Rechte verfügt.Der Anbieter wird actyvyst von allen Ansprüchen Dritter bezüglich der von ihm
      eingestellten Inhalte vollumfänglich schadlos halten.</p>
      <h2>7. Marketing</h2>
      <p>Der Anbieter gestattet actyvyst, seinen Namen und sein Logo im Rahmen von Marketing - Kampagnen(z.B.
      Suchmaschinenmarketing, Flyer - Werbung) zu verwenden, um seine Leistungen zu bewerben.</p>
      <h2>8. Stornierungen</h2 >
      <p>Falls zwischen den Parteien nicht anderweitig vereinbart, stimmt der Anbieter zu, dass Endkunden ihre Buchungen bis 24
      Stunden vor Beginn der Leistung kostenfrei stornieren können. Storniert der Anbieter selbst eine bestehende
      Endkunden - Buchung, wird actyvyst dem Anbieter eine Stornierungsgebühr i.H.v. 10 % des Bruttoverkaufspreises berechnen.
      Ausgenommen von dieser Regelung sind Fälle höherer Gewalt.</p>
      <h2>9. Kundenbeschwerden</h2 >
      <p>Beschwerden im Zusammenhang mit der Erbringung der Leistung müssen vom Anbieter bearbeitet werden, da actyvyst nicht
      für die Leistungserbringung verantwortlich ist.Werden Beschwerden von Endkunden an actyvyst herangetragen, so wird
      actyvyst den Anbieter hierüber informieren.</p>
      <h2>10. Provision</h2 >
      <p>Der Anbieter stimmt zu, actyvyst eine Provisionsgebühr für die Vertragsvermittlung mit dem Endkunden zu bezahlen.Die
      Höhe dieser Gebühr wird an anderer Stelle separat zwischen den Parteien vereinbart. Die vereinbarte Gebühr versteht
      sich zzgl.der jeweils geltenden gesetzlichen Mehrwertsteuer.Die Berechnung der Gebühr erfolgt anteilig auf Grundlage
      des Bruttoverkaufspreis der betreffenden Leistung.</p>
      <h2>11. Bezahlung</h2 >
      <p>actyvyst nimmt Online - Zahlungen von Endkunden im Namen des Anbieters entgegen und nutzt hierfür die Dienste eines
      Zahlungsanbieters.actyvyst trägt die mit der Zahlungsabwicklung verbundenen Kosten.
      Endkunden können Kreditkartenzahlungen innerhalb eines bestimmten Zeitraums widerrufen, in diesem Fall wird actyvyst
      den Anbieter umgehend informieren und die Buchung falls möglich stornieren.Ziehen Endkunden ihre Zahlung nach
      Erbringung der Leistung zurück, wird actyvyst den Zahlungsanbieter kontaktieren, die Gründe für den Widerruf erfragen
      und sie dem Anbieter weiterleiten.actyvyst übernimmt keine Haftung für ausgefallene Zahlungen.
      Falls nicht anderweitig vereinbart, wird actyvyst den Anbieter einmal monatlich auszahlen, der Anbieter erhält alle
      von Endkunden getätigten Zahlungen für seine Leistungen abzüglich der mit actyvyst vereinbarten Provisionen.Die
      Auszahlung für Leistungen innerhalb eines Kalendermonats erfolgt spätestens bis zum 15. des Folgemonats, ebenfalls bis
      zu diesem Termin wird actyvyst dem Anbieter eine Provisionsrechnung zur Verfügung stellen.</p>
      <h2>12. Steuern</h2>
      <p>Der Anbieter bestätigt, dass er für den korrekten Ausweis aller steuerlichen Informationen in der Plattform
      verantwortlich ist und Änderungen z.B.seines steuerlichen Status unmittelbar hinterlegen muss.</p>
      <h2>13. Kundenkommunikation</h2 >
      <p>Der Anbieter verpflichtet sich, sowohl in seiner Leistungsbeschreibung als auch in der direkten Kommunikation mit von
      actyvyst vermittelten Kunden, auf alternative Buchungsmöglichkeiten außerhalb der Plattform hinzuweisen oder diese zu
      bewerben.</p>
      <h2>14. Datenschutz</h2>
      <p>Der Anbieter verpflichtet sich dazu, Kundendaten, die ihm von actyvyst zum Zweck der Durchführung seiner Leistung zur
      Verfügung gestellt werden, in Übereinstimmung mit dem im Land der Durchführung der Leistung geltenden Datenschutzrecht
      zu behandeln und die Daten insbesondere nicht an Dritte weiterzugeben oder sie zu anderen Zwecken als zur
      Leistungsdurchführung zu verwenden.</p>
      <h2>15. Haftungsbeschränkung</h2>
      <p>actyvyst garantiert weder die ununterbrochene Verfügbarkeit der Plattform noch die Verfügbarkeit bestimmter Features
      oder Funktionen.</p>
      <p>Die Haftung von actyvyst beschränkt sich auf folgende Punkte:</p>
      <ul>
        <li>
          actyvyst haftet vollumfänglich für wirtschaftliche Schäden, die dem Anbieter von Vertretern der actyvyst GmbH im
          Rahmen ihrer Tätigkeit für die actyvyst GmbH vorsätzlich oder grob fahrlässig zugefügt werden.
        </li>
        <li>
          actyvyst haftet gemäß den Regelungen des Deutschen Produkthaftungsgesetzes (ProdHaftG).
        </li>
        <li>
          actyvyst haftet für Schäden, die durch die Nichterfüllung wesentlicher Vertragspflichten entstehen.
        </li>
      </ul>
      <h2>16. Laufzeit und Beendigung</h2>
      <ul>
        <li>
          Der Vertrag wird auf unbestimmte Zeit geschlossen.
        </li>
        <li>
          Beide Parteien können den Vertrag zu jeder Zeit und ohne Angabe von Gründen kündigen. Die Kündigung durch den
          Anbieter erfolgt automatisch nach Auswahl der entsprechenden Option innerhalb der Plattform.
        </li>
        <li>
          Der Veranstalter verpflichtet sich, die Leistungen für zum Zeitpunkt der Kündigung noch offene bestätigte
          Endkunden-Buchungen noch durchzuführen.
        </li>
      </ul>
      <h2>17. Schlussbestimmungen</h2>
      <p>actyvyst behält sich vor, diese Anbieter - AGBs jederzeit zu ergänzen, zu ersetzen oder zu ändern.actyvyst wird den
      Anbieter in diesem Fall über seine in der Plattform hinterlegte E - Mail - Adresse kontaktieren.Lehnt der Anbieter die
      neuen Bedingungen ab, muss er das Vertragsverhältnis entweder per Mail oder durch Nutzung der entsprechenden Option in
      der Plattform kündigen. Erfolgt keine Kündigung durch den Anbieter, gelten die erneuerten Anbieter - AGBs als
      angenommen.</p>
      <p>Sollten einzelne Bestimmungen dieses Vertrages unwirksam oder undurchführbar sein oder nach Vertragsschluss unwirksam
      oder undurchführbar werden, bleibt davon die Wirksamkeit des Vertrages im Übrigen unberührt.An die Stelle der
      unwirksamen oder undurchführbaren Bestimmung soll diejenige wirksame und durchführbare Regelung treten, deren
      Wirkungen der wirtschaftlichen Zielsetzung am nächsten kommen, die die Vertragsparteien mit der unwirksamen bzw.
      undurchführbaren Bestimmung verfolgt haben. Die vorstehenden Bestimmungen gelten entsprechend für den Fall, dass sich
      der Vertrag als lückenhaft erweist.
      Diese Vertragsbedingungen unterliegen deutschem Recht, Gerichtsstand ist Düsseldorf.</p>
    </React.Fragment >
  )
}

export default GTCContent;
