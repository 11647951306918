import { createMuiTheme } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";

export const colors = {
  actyDarkBlue: "#14143D",
  actyLightBlue: "#4196F6",
  actyRed: "#CB016F",
  actyGreen: "#1F990E",
  actyGray: '#777777',
  actyLightGray: '#dddddd'
};
const fonts = {
  primary: "'Open Sans', sans-serif",
  secondary: "'Days One', sans-serif"
}

const rawTheme = createMuiTheme({
  overrides: {
    MuiSelect: {
      root: {
        fontSize: "1em"
        // backgroundColor: "#4196F6"
      }
    },
    MuiIcon: {
      fontSizeSmall: {
        fontSize: 20
      }
    },
    MuiChip: {
      root: {
        // fontSize: "1em",
        backgroundColor: "#4196F6",
        color: "white"
      },
      deleteIcon: {
        // backgroundColor: "#172C3D",
        color: "white"
      }
    },
    MuiInputBase: {
      input: {
        "&:-webkit-autofill": {
          transitionDelay: "9999s",
          transitionProperty: "background-color, color"
        }
      },
      inputMultiline: {
        fontSize: "0.9em",
        paddingRight: 25
      }
    },
    MuiInputAdornment: {
      positionStart: {
        marginTop: 0
      },
      positionEnd: {
        position: "absolute",
        top: 0,
        right: 20
      }
    }
  },
  palette: {
    primary: {
      light: "#455663",
      main: "#172C3D",
      dark: "#101e2a"
    },
    secondary: {
      light: "#67abf7",
      main: "#4196F6",
      dark: "#2d69ac"
    },
    warning: {
      main: "#ffc071",
      dark: amber[700]
    },
    error: {
      light: '#f18e8e',
      main: '#e74141',
      dark: '#b61616'
    },
    success: {
      light: green[50],
      main: green[500],
      dark: green[700]
    },
    background: {
      default: "#ffffff",
      intro: "#ffffff"
    }
  },
  typography: {
    fontFamily: fonts.primary,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 700,
    fontFamilySecondary: "'Days One', sans-serif"
  }
});

// const fontHeader = {
//   color: rawTheme.palette.text.primary,
//   fontWeight: rawTheme.typography.fontWeightMedium
//   //   fontFamily: rawTheme.typography.fontFamilySecondary
// };

const theme = {
  ...rawTheme,
  typography: {
    ...rawTheme.typography,

    h1: {
      fontSize: '1.8rem',
      color: colors.actyLightBlue,

      fontWeight: 600
    },
    h2: {
      fontSize: '1.6rem',
      color: colors.actyLightBlue,

      fontWeight: 600
    },
    h3: {
      fontSize: '1.4rem',
      color: colors.actyLightBlue,
      fontWeight: 600
    },
    h4: {
      color: colors.actyGray,
      fontSize: '1.0rem',
      fontWeight: 600,
      paddingTop: 10,
      paddingBottom: 10
    },
    h5: {
      fontSize: '1.2rem',
      color: colors.actyLightBlue,
      fontWeight: 600
    },
    h6: {
      fontSize: '1.2rem',
      color: colors.actyLightBlue,
      fontWeight: 600
    },
    subtitle1: {
      fontSize: '0.6rem',
      color: colors.actyLightBlue,
      fontWeight: 600
    },
    subtitle2: {
      fontSize: '0.6rem',
      color: colors.actyLightBlue,
      fontWeight: 600
    },
    caption: {
      fontSize: '0.6rem'
    },
    // h1: {
    //   ...rawTheme.typography.h1,
    //   ...fontHeader
    // },
    // h4: {
    //   ...rawTheme.typography.h5,
    //   ...fontHeader,
    //   color: "#999999",
    //   textAlign: "left",
    //   fontSize: "1.2em",
    //   paddingTop: 5
    // },
    // h6: {
    //   ...rawTheme.typography.h5,
    //   ...fontHeader,
    //   color: rawTheme.palette.secondary.main,
    //   textAlign: "left",
    //   fontSize: "1.2em",
    //   paddingTop: 5
    // },
    body1: {
      ...rawTheme.typography.body1,
      fontWeight: rawTheme.typography.fontWeightRegular,
      fontSize: 14
    },

  }
};

export default theme;
