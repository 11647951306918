const en = {
  translation: {
    generic: {
      yes: 'Yes',
      no: 'No',
      name: "Name",
      firstName: "First name",
      lastName: "Surname",
      email: "Email",
      password: "Passwort",
      activity: "Activity",
      activity_plural: "Activities",
      variant: "Offer",
      variant_plural: "Offers",
      activities: "Activities",
      variants: "Offers",
      category: "Categories/Tags",
      provider: "Provider",
      provider_plural: "Providers",
      user: "User",
      user_plural: "Users",
      booking: "Booking",
      booking_plural: "Bookings",
      event: "Event",
      event_plural: "Events",
      location: "Adresse",
      contact: "Contact person",
      price: "Price",
      currency: "Currency",
      slot: "Slot",
      date: "Date",
      edit: "Edit",
      save: "Save",
      close: "Close",
      description: "Description",
      web: 'Website',
      country: "Country",
      countries: {
        de: "Germany",
        fr: "France",
        nl: "Netherlands",
        gb: "UK",
        it: "Italy",
        es: "Spain",
        be: "Belgium",
        ch: "Switzerland",
        pt: "Portugal",
        lu: "Luxembourg",
        at: "Austria"
      },
      languages: {
        de: "German",
        en: "English",
        fr: "French",
        es: "Spanish"
      },
      error: 'Unexpected error.'
    },
    navbar: {
      home: 'Home',
      profile: 'Profile',
      categories: 'Categories',
      events: 'Events & News',
      providers: 'Providers',
      activities: 'Activities',
      variants: 'Offers & Prices',
      campaigns: 'Campaigns',
      calendar: 'Calendar',
      bookings: 'Bookings',
      notLoggedIn: 'Not logged in',
      providerApplications: 'Applications',
      callcenter: 'Callcenter'
    },
    email: "Email",
    password: "Password",
    activity: "Activity",
    variant: "Offer",
    startDate: "Start Date",
    date: "Date",
    endDate: "End date",
    dayRange: "Day range",
    dailyStartTime: "Daily starting time",
    startTime: "Start time",
    dailyEndTime: "Daily end time",
    endTime: "End time",
    published: "Published",
    save: "Save",
    pickerDateFormat: "MM/DD/YY",
    tables: {
      generic: {
        actions: 'Actions',
        state: 'State'
      }
    },
    forms: {

      generic: {
        name: "Name",
        published: "Visible in app",
        validations: {
          name: "Please enter a name."
        },
        messages: {
          success: "Saved successfully.",
          saveError: "Data could not be saved: {{error}}",
          validationError: "Data could not be saved, please check marked fields.",
          unsavedData: "Your changes haven't been saved yet. Do you really want to leave the page without saving?",
          unsavedDataTitle: "Close form?"
        },
      },
      profile: {
        actions: {
          change: 'Change',
        },
        salutation: "Hello {{firstname}} {{lastname}}.",
        password: "Password"
      },
      location: {
        country: "Country",
        city: "City",
        zip: "Zip-Code",
        street: "Street"
      },
      booking: {
        actions: {
          confirm: 'Confirm',
          reject: 'Reject'
        },
        confirmMessageTitle: 'Confirm booking?',
        confirmMessage: 'Do you want to confirm the booking? Your customer will be informed via mail and app notification, a digital ticket will be issued.',
        cancelMessageTitle: 'Reject booking request?',
        cancelMessage: 'Do you really want to reject the booking request? Your customer will be informed via mail and app notification, his payment will be refunded.',
        request: 'Booking request',
        booker: 'Customer',
        participants: 'Participants',
        price: 'Price',
        status: 'Status',
        created: 'Waiting for payment',
        confirmed: 'Confirmed',
        confirmationRequired: 'Confirmation required',
        reservation_only: 'Reservation',
        rejected: 'Rejected',
        cancelled: 'Cancelled',
        timestamp: 'Booking time',
        slotStart: 'Booked slot',
        numParticipants: 'Anzahl Personen'
      },
      category: {
        new: 'New category',
        edit: 'Edit category'
      },
      contact: {
        country: "Country",
        city: "City",
        zip: "Zip-Code",
        street: "Street",
        firstName: "Name",
        lastName: "Surname",
        email: "Email",
        phone: "Phone number",
        web: "Web",
        validations: {
          // country: "Land",
          city: {
            req: "Please enter the city name.",
            min: "At least 2 characters are required.",
            max: "Please do not enter more than 50 characters."
          },
          zip: {
            req: "Please enter the zip code.",
            min: "The zip code must contain 4 or 5 digits.",
            max: "Please enter 4-5 digits."
          },
          street: {
            req: "Please enter the street name.",
            min: "The street name must contain at least 5 characters.",
            max: "Please do not enter more than 50 characters."
          },
          firstname: {
            req: "Please enter your name.",
            min: "At least 2 characters are required.",
            max: "Please do not enter more than 50 characters."
          },
          lastname: {
            req: "Please enter your surname.",
            min: "At least 2 characters are required.",
            max: "Please do not enter more than 50 characters."
          },
          email: {
            req: "Please enter a valid email address."
          }
          // phone: {
          //   req: "Please enter a valid phone number, digits only.",
          //   min: "The phone number must contain at least 5 digits.",
          //   max: "Please do not enter more than 15 digits."
          // }
        }
      },
      activity: {
        teaser: "Teaser",
        hygiene: "Hygiene concept",
        info: "Info",
        new: "New activity",
        edit: "Edit activity",
        tags: "Tags",
        newTag: "Add tags",
        newInfo: "Add infos",
        deleteImageMessage: {
          title: 'Delete image?',
          message: 'Do you want to delete the selected image?'
        },
        images: 'Images',
        deleteImageButton: 'Delete image',
        changeImageButton: 'Change image',
        imageMoveRightButton: 'Move right',
        imageMoveLeftButton: 'Move left',
        uploadImageButton: 'Upload image',
        validation: {
          min: 'At least {{min}} characters are required.',
          max: 'Please do not enter more than {{max}} characters.',
          required: 'This is a required field.',
          minTags: 'Please select at least one tag/category.',
          maxTags: 'You can assign at most {{maxTags}} tags/categories',
          maxInfos: 'Please enter at most {{maxInfos}} info lines'
        }
      },
      variant: {
        actions: {
          new: "Create offer",
          edit: "Edit offer",
        },
        type: 'Offer type',
        fixedSlot: 'Fixed dates',
        variableSlot: 'Dates on request',
        resource: 'Resource',
        limitedVacancy: 'Check vacancy',
        bookingOnRequest: 'Book on request',
        duration: "Duration (Minutes)",
        maxParticipants: "Max participants",
        cutoffPeriod: "Cutoff period (Minutes)",
        newPriceCategory: "Add item",
        priceList: "Price list",
        deeplink: "Deeplink",
        queryFromDate: "Query-Parameter Startdatum",
        jscode: "JavaScript"
      },
      slot: {
        actions: {
          new: "Create slot",
          edit: "Edit slot"
        }
      },
      provider: {
        new: "Create provider",
        edit: "Edit provider",
        actions: {
          new: "Neuer Anbieter",
          list: "Alle Anbieter"
        },
        address: "Adress",
        contact: "Contact",
        devices: "Devices"
      },
      event: {
        actions: {
          new: "Create event",
          edit: "Edit event"
        },
        startTime: "From"
      },
      reservation: {
        actions: {
          new: "New reservation",
          edit: "Edit reservation",
          delete: "Delete reservation"
        }
      },
      providerApplications: {
        title: 'Applications',

        details: {
          title: 'Provider application',
          name: 'Name',
          description: 'Details',
          web: 'Website',
          address: 'Address',
          contact: 'Contact',
          connectWithProvider: 'Assign to',
          newProvider: 'New provider'
        },
        actions: {
          confirm: 'Confirm',
          reject: 'Reject'
        },
        confirmMessageTitle: 'Confirm application',
        rejectMessageTitle: 'Reject application',
        confirmMessage: 'Do you want to confirm the application?',
        rejectMessage: 'Do you want to reject the application?'
      },
      register: {
        title: 'Set password',
        description: `Welcome to the actyvyst provider cockpit.<br>
        Please select the password you want to use to log in here.
        Please use at least 5 characters and repeat your password in the second field.<br>
        Best regards, your actyvyst team.`,
        repeatPassword: 'Repeat password',
        actions: {
          submit: 'Set password'
        },
        validation: {
          required: 'Required field',
          max: 'Please enter max {{max}} characters',
          min: 'Please enter at least {{min}} characters',
          confirmPassword: `Passwords don't match`
        },
        userIsProvider: {
          title: 'Provider registration',
          description: `<p>You are already registered as a provider with the e-mail address <b>{{email}}</b>.</p>
          <p>To register as a different provider, please select <b>Provider registration</b> and use a different e-mail address for your new account.</p>
          <p>To log in with your existing account, please select <b>Login</b>. In case you forgot your password, you'll be able to reset it there.</p>
          `,
          actions: {
            login: 'Login',
            register: 'Provider registration'
          }
        }
      }
    },
    login: {
      title: 'Login',
      error: 'Login failed - please try again.',
      actions: {
        login: 'Login'
      }
    },
    welcome: {
      firstSteps: 'First steps',
      activities: `activities`,
      variants: `variants`,
      slots: `slots`,
      bookings: `bookings`
    }
  },
  errors: {
    genericMessage: "An unexpected error occured - please check your internet connection."
  }
}


export default en;
