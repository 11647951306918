import React, { useContext } from 'react';
import ProviderSelect from './ProviderSelect';
import ProviderInfo from './ProviderInfo';
import { UserContext } from '../../Context/UserContext';
import { ProviderContext } from '../../Context/ProviderContext';

const ProviderBar = (props) => {
  const { user } = useContext(UserContext);
  const provider = useContext(ProviderContext);
  return (
    <React.Fragment>
      {(user?.isAdmin === true) ?
        <ProviderSelect {...props} />
        : <ProviderInfo providerName={provider?.name || ''} />
      }
    </React.Fragment>

  )
}

export default ProviderBar;