import React from 'react';
import ReservationFormController from './ReservationFormController';
import ReservationFormUI from './ReservationFormUI';
import FormHeader from '../../Forms/Partials/FormHeader';
import FormFooter from '../../Forms/Partials/FormFooter';
import FormDialog from '../../Forms/Container/FormDialog';

const ReservationForm = (props) => {
  const { open, onClose, onDataChanged, slot, id } = props;
  return (
    <ReservationFormController
      slot={slot}
      onDataChanged={onDataChanged}
      id={id}
      onClose={onClose}

      render={(formProps) => {
        return (
          <FormDialog
            {...formProps}
            onClose={onClose}
            open={open}
            render={(closeDialog) => {
              return (
                <React.Fragment>
                  <FormHeader
                    {...formProps}
                    showCloseIcon={true}
                    showActive={false}
                    showLanguage={false}
                    showLanguageActive={false}
                    showSave={false}
                    onClose={closeDialog}
                  />
                  <ReservationFormUI {...formProps} onClose={closeDialog} />
                  <FormFooter {...formProps}
                    onClose={closeDialog}
                    onSave={formProps.handleSubmit} />
                </React.Fragment>
              )
            }}
          >
          </FormDialog>
        )
      }}
    />
  )
}

export default ReservationForm;