export const formStyles = theme => ({
  testBlue: { backgroundColor: 'blue' },
  textField: {
    padding: theme.spacing(1),
    width: 200
  },
  typography: {
    padding: theme.spacing(2)
  },
  textInput: {
    fontFamily: "'Open Sans', sans-serif",
    minWidth: "99%"
  },
  checkbox: {
    color: theme.palette.primary.main,
    "&$checked": {
      color: theme.palette.primary.light
    }
  },
  formControl: {
    minWidth: 120,
    maxWidth: 300,
    marginBottom: 10,
    marginLeft: 6
  },
  formContainer: {
    maxWidth: 800,
    minWidth: 120
  },
  formHelper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
      color: "white"
    }
  },
  warningButton: {
    backgroundColor: theme.palette.error.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
      color: "white"
    }
  },
  disabledButton: {
    backgroundColor: 'lightgray',
    color: "white",
  },
  closeicon: {
    position: "absolute",
    top: 10,
    right: 10,
    width: 20,
    height: 20,
    backgroundColor: theme.palette.primary.light,
    borderRadius: 4,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.error.main,
      color: "white"
    }
  }
});
