class ActyvystAPIError extends Error {
    constructor(msg, status, code, detail) {
        super(msg);
        this.name = this.constructor.name;
        this.status = status;
        this.code = code;
        this.detail = detail;
    }
    static createFromAxiosError(axiosError) {
        const { response } = axiosError;
        if (!response || !response.status) { return new ActyvystAPIError(axiosError.message, null, null) }
        const status = response.status;
        const data = response.data &&
            response.data.errors &&
            response.data.errors[0] ? response.data.errors[0] : {};
        const code = data.code || null;
        const msg = data.title || `actyvyst API returned ${status}`;
        const detail = data.detail || null;
        return new ActyvystAPIError(msg, status, code, detail);
    }
}

export { ActyvystAPIError }