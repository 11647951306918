import React from 'react';
import { Typography, IconButton } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const Paginator = (props) => {
    return (
        <React.Fragment>
            <IconButton
                onClick={() => {
                    if (props.page > 1) {
                        props.onBack()
                    }
                }}
            >
                <ArrowBackIosIcon />
            </IconButton>
            <Typography>{props.page} / {props.maxPage}</Typography>
            <IconButton
                onClick={() => {
                    if (props.page < props.maxPage) {
                        props.onForward()
                    }
                }}
            >
                <ArrowForwardIosIcon />
            </IconButton>
        </React.Fragment>
    )
}

export default Paginator;