import { Deserializer } from 'jsonapi-serializer';
import qs from 'qs';
import axios from 'axios';
import { ActyvystAPIError } from './APIError';

axios.defaults.baseURL = process.env.REACT_APP_API_HOST;
const deserializer = new Deserializer({ keyForAttribute: 'camelCase' });

export const GenericAPI = (route, { includeMeta = false } = {}) => {
  return {
    fetchList: async (params) => {
      console.log('------PARAMS')
      console.log({ params });
      console.log('------/PARAMS')
      try {
        const response = await axios.get(route, {
          params, paramsSerializer: params => {
            return qs.stringify(params)
          }
        });
        const result = await deserializer.deserialize(response.data);
        if (includeMeta) {
          return {
            meta: response.data.meta,
            result
          }
        } else {
          return result;
        }
      } catch (err) {
        const apiError = ActyvystAPIError.createFromAxiosError(err);
        console.log(apiError.code, apiError.status);
        throw apiError;
      }
    },
    fetchItem: async (id, params) => {
      try {
        const response = await axios.get(`${route}/${id}`, { params });
        return await deserializer.deserialize(response.data);
      } catch (err) {
        const apiError = ActyvystAPIError.createFromAxiosError(err);
        console.log(apiError.code, apiError.status);
        throw apiError;
      }
    },
    createItem: async (item, options = { requestSerializer: null }) => {
      try {
        const { id, ...attributes } = item;
        let data = attributes;
        if (options.requestSerializer) {
          data = options.requestSerializer.serialize(attributes);
        }
        let response = await axios.post(route, {
          ...data
        });
        return await deserializer.deserialize(response.data);
      } catch (err) {
        const apiError = ActyvystAPIError.createFromAxiosError(err);
        console.log(apiError.code, apiError.status);
        throw apiError;
      }
    },
    updateItem: async (item, options = { requestSerializer: null }) => {
      try {
        const { id } = item;
        let data = item;
        if (options.requestSerializer) {
          data = options.requestSerializer.serialize(item);
        }
        let response = await axios.patch(`${route}/${id}`, { ...data });
        return await deserializer.deserialize(response.data);
      } catch (err) {
        const apiError = ActyvystAPIError.createFromAxiosError(err);
        console.log(apiError.code, apiError.status);
        throw apiError;
      }
    },
    deleteItem: async (id) => {
      try {
        await axios.delete(`${route}/${id}`);
      } catch (err) {
        const apiError = ActyvystAPIError.createFromAxiosError(err);
        console.log(apiError.code, apiError.status);
        throw apiError;
      }
    }
  }
}
