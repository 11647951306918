import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from "prop-types";
import { formStyles } from "../../Styles/forms";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles(theme => ({
  ...formStyles(theme),
  displayLinebreak: {
    whiteSpace: 'pre-line'
  }
}));



const AlertDialog = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  function handleClose(result) {
    props.callback(result);
  }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => handleClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className={classes.displayLinebreak}>
            {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {
            props.buttons.map((button, index) => {
              return (
                <Button variant={button.default === true ? 'contained' : 'text'}
                  key={`button-${index}`}
                  onClick={() => handleClose(button.value)}
                  color="secondary"
                  autoFocus={index === 0 ? true : false}>
                  {t(button.label)}
                </Button>
              )
            })
          }
          {/* <Button onClick={() => handleClose(true)} color="primary" autoFocus>
                        {props.agreeLabel || t('generic.yes')}
                    </Button>
                    <Button onClick={() => handleClose(false)} color="primary" >
                        {props.cancelLabel || t('generic.no')}
                    </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}

AlertDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  callback: PropTypes.func,
};

AlertDialog.defaultProps = {
  open: false,
  title: '<title>',
  message: '<message>',
  callback: (value) => { alert(`callback: ${value}`) },
  buttons: [{ label: 'generic.yes', value: true }, { label: 'generic.no', value: false }]
};

export default AlertDialog;
