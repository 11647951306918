import * as Yup from "yup";


export const validationConstants = {
  name: {
    min: 3,
    max: 50
  },
  description: {
    min: 3,
    max: 1000
  }
}

const providerValidationSchema = (language, t) => {
  const v = validationConstants;
  return Yup.object().shape({
    name: Yup.string()
      .min(v.name.min, t('forms.activity.validation.min', { min: v.name.min }))
      .max(v.name.max, t('forms.activity.validation.max', { max: v.name.max }))
      .required(t('forms.activity.validation.required')),
    contact: Yup.object().shape({
      country: Yup.string()
        .required(t('forms.contact.validations.country.req')),
      firstname: Yup.string()
        // .min(2, t("forms.contact.validations.firstname.min"))
        .max(50, t("forms.contact.validations.firstname.max")),
      // .required(t("forms.contact.validations.firstname.req")),
      lastname: Yup.string()
        // .min(2, t("forms.contact.validations.lastname.min"))
        .max(50, t("forms.contact.validations.lastname.max")),
      // .required(t("forms.contact.validations.lastname.req")),
      email: Yup.string()
        .email(t("forms.contact.validations.email"))
        .required(t("forms.contact.validations.email.req")),

      zip: Yup.string()
        // .matches(/(\d){4,5}/, t("forms.contact.validations.zip.max"))
        .required(t("forms.contact.validations.zip.req")),

      street: Yup.string()
        .min(2, t("forms.contact.validations.street.min"))
        .max(50, t("forms.contact.validations.street.max"))
        .required(t("forms.contact.validations.street.req")),

      city: Yup.string()
        .min(2, t("forms.contact.validations.city.min"))
        .max(50, t("forms.contact.validations.city.max"))
        .required(t("forms.contact.validations.city.req"))
    }),
    messaging: Yup.object().shape({
      email: Yup.string()
        .email(t("forms.contact.validations.email.format")),
      sms: Yup.string()

    }),

    translations: Yup.object().shape({
      [language]: Yup.object().shape({
        description: Yup.string()
          .min(v.description.min, t('forms.activity.validation.min', { min: v.description.min }))
          .max(v.description.max, t('forms.activity.validation.max', { max: v.description.max }))
        // .required(t('forms.activity.validation.required')),
      })
    })
  })
};

export default providerValidationSchema;