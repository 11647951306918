import React from "react";
import {
  TextField,
  FormControl,
  FormHelperText,
  useTheme,
  InputAdornment,
} from "@material-ui/core";
import FlagIcon from "../FlagIcon";
import { makeStyles } from "@material-ui/core/styles";
import { formStyles } from "../../Styles/forms";
import { useField, useFormikContext } from "formik";
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  ...formStyles(theme),
  iconPos: {
    position: "relative",
    top: 0,
    left: 0
  },
}))


function IntegerFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      isNumericString
      format="##"
    />
  )
}

function CurrencyFormat(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      fixedDecimalScale
      decimalScale={2}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
    />
  );
}

CurrencyFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

const GOFormikTextInput = props => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();

  const { labelText, flag } = props;

  const inputProps =
    flag !== undefined
      ? {
        endAdornment: (
          <InputAdornment position="end">
            <FlagIcon flag={flag} />
          </InputAdornment>
        )
      }
      : {}
    ;

  switch (props.inputFormat) {
    case 'currency':
      inputProps.inputComponent = CurrencyFormat;
      inputProps.startAdornment =
        <InputAdornment position="start">
          €
        </InputAdornment>

      break;
    case 'integer':
      inputProps.inputComponent = IntegerFormat;
      break;
    default:
      break;
  }

  return (
    <FormControl className={classes.textInput} >
      <TextField
        label={labelText}
        {...props}
        id={`${field.name}`}
        name={`${field.name}`}
        onChange={(e) => {
          if (!props.maxChars || e.target.value.length <= props.maxChars) {
            let v = e.target.value;
            setFieldValue(field.name, v)
          }
        }}
        InputProps={inputProps}
      />
      {props.noValidationDisplay ?
        <React.Fragment /> :
        <FormHelperText
          id={`${field.name}-helper-text`}
          className={classes.formHelper}
          error={meta.error && meta.touched}
          component="p"
        >
          {meta.error && meta.touched ? meta.error : ""}
        </FormHelperText>
      }
    </FormControl >
  );
};

export default GOFormikTextInput;
