import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import ActivityFormUI from "./ActivityFormUI";
import { DataContext } from "../../Context/DataContext";
import { UserContext } from '../../Context/UserContext';
import { Typography, Container, Grid, LinearProgress } from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import { formStyles } from "../../Styles/forms";
import { makeLanguageFields } from '../../Modules/Forms/FormHelper';
import FormHeader from '../Partials/FormHeader';
import FormFooter from '../Partials/FormFooter';
import config from '../../Config/config';
import BreadCrumbs from "../../Modules/Nav/BreadCrumbs";
import _ from "lodash";
import activityValidationSchema from './validation';
import { useHistory } from 'react-router-dom';
import GOSnackBar from '../../Modules/Forms/GOSnackBar';
import { Constants } from '@actyvyst/common';


const useStyles = makeStyles(theme => ({
  ...formStyles(theme),
  checked: {}
}));


const ActivityForm = props => {
  // const [language, setLanguage] = useState(config.defaultContentLanguage);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const { Activity } = useContext(DataContext);
  const [statusMessage, setStatusMessage] = useState({
    open: false,
    message: "",
    variant: ""
  });
  const initialData = {
    id: "new",
    destination: '',
    priority: 0,
    images: [],
    location: {
      country: "de",
      city: "",
      street: "",
      zip: "",
      lat: null,
      lng: null
    },
    tags: [],
    category: "",
    provider: { id: null },
    translations: makeLanguageFields({ name: '', description: '', teaser: '', hygiene: '', infos: [], published: false }),
    published: false,
    destination: 'sylt'
  };

  const [activityData, setActivityData] = useState({ ...initialData });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const history = useHistory();




  useEffect(() => {
    setIsLoading(false);
    if (props.id === "new") {
      setIsLoading(false);
      setActivityData({ ...initialData, provider: { id: user.provider } });
    } else {
      const fetchData = async () => {
        try {
          setIsLoading(true);
          const data = await Activity.fetchItem(props.id);
          const newActivity = _.merge({},
            initialData,
            data
          );

          setActivityData(newActivity);
          setError(null);
          setIsLoading(false);
        } catch (err) {
          setError(err.message);
          setIsLoading(false);
        }
      };
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id, props.language, user]);


  const handleSubmit = (values, formikActions) => {
    const saveData = async (isNew) => {
      try {
        const operation = isNew ? Activity.createItem : Activity.updateItem;
        const newData = await operation({
          ...values
        });
        setActivityData(newData);
        formikActions.setStatus({ success: true });
        formikActions.resetForm(newData);
        setStatusMessage({
          open: true,
          message: t("forms.generic.messages.success"),
          variant: "success"
        });
      } catch (err) {
        formikActions.setStatus({ success: false })
        formikActions.setSubmitting(false)
        formikActions.setErrors({ submit: err.message })

        setStatusMessage({
          open: true,
          message: t("forms.generic.messages.saveError", { error: err.message }),
          variant: "error"
        });
      }
    };
    saveData(values.id === "new");
  };

  const validationSchema = activityValidationSchema(props.language, t);

  return (
    <React.Fragment>
      <Container>
        <Grid container
          justify="flex-start"
          alignItems="flex-start"
          className={classes.formContainer}
        >
          <Grid item xs={12}>
            {isLoading ? (
              <LinearProgress color="secondary" style={{ width: "100%" }} />
            ) : error ? (
              <Typography>{error}</Typography>
            ) : (
              <Formik
                enableReinitialize={true}
                initialValues={{
                  ...activityData
                }}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                validateOnBlur={true}
                validateOnChange={false}

              >
                {(formikProps) => {
                  const onSave = () => {
                    formikProps.validateForm()
                      .then(() => {
                        if (!formikProps.isValid) {
                          setStatusMessage({
                            open: true,
                            message: t("forms.generic.messages.validationError"),
                            variant: "error"
                          });
                        } else {
                          formikProps.handleSubmit();
                        }
                      }).catch((err) => {
                        console.log('onSave ERROR');
                        setStatusMessage({
                          open: true,
                          message: t("forms.generic.messages.saveError", { error: err.message }),
                          variant: "error"
                        });
                      })
                  }

                  const onLanguageChange = (newLanguage) => {
                    history.push({ pathname: `/activities/${props.id}`, search: `language=${newLanguage}` });
                  }

                  return (
                    <Form>
                      <FormHeader
                        {...formikProps}
                        title={props.id === "new"
                          ? t("forms.activity.new")
                          : t("forms.activity.edit")}
                        language={props.language}
                        onLanguageChange={onLanguageChange}
                        onSave={onSave}
                      />
                      <ActivityFormUI
                        {...formikProps}
                        dirty={formikProps.dirty}
                        contentLanguage={props.language}
                      />
                      <Grid item xs={12}>
                        <FormFooter
                          {...formikProps}
                          onSave={onSave}
                        />
                      </Grid>

                    </Form>
                  )
                }}
              </Formik>
            )}
          </Grid>
        </Grid>
        <GOSnackBar
          open={statusMessage.open}
          messagetext={statusMessage.message}
          closeHandle={() => { setStatusMessage({ ...statusMessage, open: false }) }}
          variant={statusMessage.variant}
        />
      </Container>
    </React.Fragment >
  );
};

ActivityForm.propTypes = {
  id: PropTypes.string,
  language: PropTypes.string
};

ActivityForm.defaultProps = {
  id: "new",
  language: config.defaultContentLanguage
};

export default ActivityForm;
