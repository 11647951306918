export const crudInitialState = {
    data: [],
    activeItem: null,
    error: null,
    loading: false,
    itemLoading: false,
    shouldUpdate: true,
}

export const CRUDReducer = (entity, state, action) => {
    const entityUpper = entity.toUpperCase();
    switch (action.type) {
        case `${entityUpper}_SET_SHOULD_UPDATE`:
            return { ...state, shouldUpdate: true }
        case `${entityUpper}_FETCH_LIST`:
            return { ...state, data: [], activeItem: null, loading: true, shouldUpdate: false, error: null }
        case `${entityUpper}_FETCH_LIST_SUCCESS`:
            return { ...state, data: [...action.payload], activeItem: null, loading: false, shouldUpdate: false, error: null }
        case `${entityUpper}_FETCH_LIST_FAILURE`:
            return { ...state, data: [], activeItem: null, loading: false, shouldUpdate: false, error: action.payload }
        case `${entityUpper}_FETCH_ITEM`:
            return { ...state, data: [...state.data], activeItem: null, itemLoading: true, error: null }
        case `${entityUpper}_FETCH_ITEM_SUCCESS`:
            return { ...state, data: [...state.data], activeItem: action.payload, itemLoading: false, error: null }
        case `${entityUpper}_FETCH_ITEM_FAILURE`:
            return { ...state, data: [...state.data], activeItem: null, itemLoading: false, error: action.payload }
        case `${entityUpper}_UPDATE`:
            return { ...state, data: [...state.data], loading: true, shouldUpdate: false, error: null }
        case `${entityUpper}_UPDATE_SUCCESS`:
            return { ...state, data: [...state.data.filter((item) => item.id !== action.payload.id), action.payload], loading: false, error: null, shouldUpdate: true }
        case `${entityUpper}_UPDATE_FAILURE`:
            return { ...state, data: [...state.data], loading: false, shouldUpdate: false, error: action.payload }
        case `${entityUpper}_ADD`:
            return { ...state, data: [...state.data], loading: true, shouldUpdate: false, error: null }
        case `${entityUpper}_ADD_SUCCESS`:
            return { ...state, data: [...state.data, action.payload], activeItem: action.payload, loading: false, shouldUpdate: true, error: null }
        case `${entityUpper}_ADD_FAILURE`:
            return { ...state, data: [...state.data], activeItem: null, loading: false, shouldUpdate: false, error: action.payload }
        case `${entityUpper}_DELETE`:
            return { ...state, data: [...state.data], loading: true, shouldUpdate: false, error: null }
        case `${entityUpper}_DELETE_SUCCESS`:
            return {
                ...state,
                data: state.data.filter((item) => item.id !== action.payload),
                activeItem: state.activeItem && state.activeItem.id === action.payload.id ? null : { ...state.activeItem }, loading: false, error: null, shouldUpdate: true
            }
        case `${entityUpper}_DELETE_FAILURE`:
            return { ...state, data: [...state.data], loading: false, error: action.payload, shouldUpdate: false }
        default: return { ...state };
    }
}

