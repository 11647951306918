import React, { useContext, useState, useEffect } from "react";
import SlotDialog from "../Forms/SlotForm/SlotDialog";
import { makeStyles } from "@material-ui/core/styles";
import { DataContext } from "../Context/DataContext";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "../Styles/sass/styles.scss";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import localization from "moment/locale/de";
import CalendarFilter from './Partials/CalendarFilter';
import { UserContext } from '../Context/UserContext';
import { ProviderContext } from '../Context/ProviderContext';

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2)
  },
  addIcon: {
    fontFamily: "'Open Sans', sans-serif",
    // color: theme.palette.primary.light,
    fontWeight: "bold",
    cursor: "pointer",
    paddingLeft: 7,
    paddingRight: 7,
    "&:hover": {
      color: theme.palette.primary.light
    },
    "&:active": {
      color: theme.palette.primary.light,
      transform: "translateY(1px)"
    },
    zIndex: 50
  }
}));

const CalendarScreen = props => {
  const { i18n } = useTranslation();
  const { user } = useContext(UserContext);
  const providerContext = useContext(ProviderContext);
  moment.updateLocale(i18n.language, localization);
  // moment.updateLocale("en", localization);
  const localizer = momentLocalizer(moment);
  const { Slot } = useContext(DataContext);
  const classes = useStyles();

  const messages = {
    allDay: "ganztägig",
    previous: "<",
    next: ">",
    today: "heute",
    month: "Monat",
    week: "Woche",
    day: "Tag",
    agenda: "Agenda",
    date: "Datum",
    time: "Zeit",
    event: "Veranstaltung", // Or anything you want
    showMore: total => `+ ${total} weitere`
  };
  const [dateRange, setDateRange] = useState({ start: moment().startOf('month').toISOString(), end: moment().endOf('month').toISOString() });
  const [openPopup, setOpenPopup] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [targetPosition, setTargetPosition] = useState({
    top: 0,
    left: 0
  });
  const [currentView, setCurrentView] = useState("month");
  // eslint-disable-next-line no-unused-vars
  const [popupId, setPopupId] = useState(undefined);
  const [slotInfo, setSlotInfo] = useState(moment());
  const [selectionClone, setSelectionClone] = useState(undefined);
  const [selectedEventId, setSelectedEventId] = useState("new");
  const [slotFilter, setSlotFilter] = useState({});


  const onFilterChange = (filter) => {
    setSlotFilter(filter);
    Slot.setShouldUpdate();
  }

  useEffect(() => {
    Slot.setShouldUpdate();
  }, [providerContext.id])

  useEffect(() => {
    if (Slot.shouldUpdate) {
      Slot.fetchList({
        filter: {
          ...slotFilter,
          from: dateRange.start,
          to: dateRange.end
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Slot.shouldUpdate, dateRange.end, dateRange.start, slotFilter]);

  let cal = React.createRef();
  const handleSelect = si => {
    setSlotInfo(si);
    // console.log(si);
    if (si.action === "select") {
      if (currentView !== "month") {
        debugger;

        // console.log(si);
        let selectionDivs = [];
        let boundrect = {};
        switch (si.bounds !== undefined || si.box !== undefined) {
          // selection has one or more slots and bounds or box property
          case true:
            selectionDivs = document.getElementsByClassName(
              "rbc-slot-selection"
            );
            boundrect = selectionDivs[0].getBoundingClientRect();
            setTargetPosition({
              top: boundrect.top + 20,
              left: boundrect.left
            });
            if (selectionDivs.length > 0) {
              setSelectionClone({
                html: selectionDivs[0].cloneNode(true),
                targetDivClass: "rbc-slot-selecting",
                copiedSelectionClass: "rbc-slot-selection"
              });
            }
            break;
          // selection has no time slots but allday slots (horizontal selection)
          case false:
            selectionDivs = document.getElementsByClassName(
              "rbc-day-bg rbc-selected-cell"
            );
            boundrect = selectionDivs[0].getBoundingClientRect();
            let tmpClone = selectionDivs[0].cloneNode(true);
            tmpClone.style = `position: absolute; top: ${boundrect.top -
              20}px; left: ${boundrect.left}px; width: ${boundrect.width *
              selectionDivs.length}px; height: 20px; z-index: 999; `;
            setSelectionClone({
              html: tmpClone,
              targetDivClass: "rbc-time-header-content",
              copiedSelectionClass: "rbc-day-bg rbc-selected-cell"
            });
            boundrect = selectionDivs[0].getBoundingClientRect();
            setTargetPosition({
              top: boundrect.top,
              left: boundrect.left
            });
            break;
          default:
            break;
        }

        setOpenPopup(true);
        setPopupId("eventpopup");
      }
    }
  };

  const DateHeader = props => {
    // console.log(util.inspect(props, false, null))
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
          // backgroundColor:
        }}
      >
        <span
          className={classes.addIcon}
          onClick={e => {
            setTargetPosition({ top: e.pageY, left: e.pageX });
            setSlotInfo({
              start: moment(props.date)
                .hour(8)
                .minute(0),
              end: moment(props.date)
                .hour(9)
                .minute(0)
            });
            setOpenPopup(true);
            setPopupId("eventpopup");
          }}
        >
          +
        </span>
        <span>{props.label}</span>
      </div>
    );

  };
  const handleClose = () => {
    setOpenPopup(false);
    setTargetPosition(null);
    setPopupId(undefined);
    setSelectedEventId("new");
    if (selectionClone !== undefined) {
      setSelectionClone({ ...selectionClone, html: null });
    }
  };
  const showEventDetails = (event, e) => {
    setSelectedEventId(event.resource.id);
    setPopupId("eventpopup");
    setOpenPopup(true);
    setTargetPosition({
      top: e.nativeEvent.clientY,
      left: e.nativeEvent.clientX
    });
  };

  const checkView = e => {
    setCurrentView(e);
  };

  const onRangeChange = (dates) => {
    console.log(dates);
    setDateRange(dates);
    Slot.setShouldUpdate(true);
  }
  useEffect(() => {
  }, [slotInfo]);

  useEffect(() => {
    let targetdiv = null;
    if (selectionClone !== undefined && selectionClone.html !== null) {
      targetdiv = document.getElementsByClassName(
        selectionClone.targetDivClass
      )[0];
      targetdiv.appendChild(selectionClone.html);
    } else if (selectionClone !== undefined && selectionClone.html === null) {
      document
        .getElementsByClassName(selectionClone.copiedSelectionClass)[0]
        .remove();
    }
  }, [selectionClone]);

  return (
    <div>
      {/* {user && user.isAdmin ?
        <CalendarFilter
          onChange={onFilterChange}
        /> : undefined
      } */}

      <div style={Slot.loading ? { opacity: 0.5 } : {}}>
        <Calendar
          localizer={localizer}
          events={Slot.data.map(s => {
            return {
              start: moment(s.startDate).toDate(),
              end: moment(s.endDate).toDate(),
              allDay: false,
              title: s.variant.name,
              resource: { ...s }
            };
          })}
          ref={cal}
          startAccessor="start"
          endAccessor="end"
          allDayAccessor="allDay"
          titleAccessor="title"
          style={{ height: "90vh" }}
          messages={messages}
          culture="de-DE"
          views={["month", "week", "day"]}
          selectable
          onSelectSlot={handleSelect}
          onSelectEvent={showEventDetails}
          onRangeChange={onRangeChange}
          onView={checkView}
          popup
          components={{
            month: {
              dateHeader: DateHeader,
              event: props => {
                const percentBooked = Math.floor(
                  ((props.event.resource.maxParticipants -
                    props.event.resource.availableSlots) *
                    100) /
                  props.event.resource.maxParticipants
                );

                return (
                  <div
                    style={{
                      backgroundColor: "transparent",
                      paddingLeft: 2,
                      paddingRight: 2
                    }}
                  >
                    {`${props.event.title}`}
                    <div
                      style={{
                        flexDirection: "row",
                        width: `${percentBooked}%`,
                        height: "4px",
                        position: "relative",
                        left: 0,
                        bottom: 0,
                        backgroundColor: "rgb(0, 241, 4, 1)",
                        padding: 0
                        // float: "left"
                      }}
                    >
                    </div>
                  </div>
                );
              }
            }

          }}
        />
      </div >
      <SlotDialog
        disableBackdropClick
        open={openPopup}
        maxWidth='md'
        fullWidth={true}
        onClose={handleClose}
        selectedDate={moment(slotInfo.start).toDate()}
        selectedEndDate={moment(slotInfo.end).toDate()}
        id={selectedEventId}
        selectedStartTime={moment(slotInfo.start).format("HH:mm")}
        selectedEndTime={moment(slotInfo.end).format("HH:mm")}
      />

    </div >
  );
};

export default CalendarScreen;
