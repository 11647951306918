import React, { useContext, useState } from 'react'
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { LinearProgress, Box, Grid, Link, Divider, FormControl, Button, Table, TableRow, TableCell, TableBody, Tabs, Tab } from '@material-ui/core';
import ListHeader from '../Partials/ListHeader';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { ProviderContext } from '../../Context/ProviderContext';
import { UserContext } from '../../Context/UserContext';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PartnerState from '../../Modules/PartnerState';
import ProviderTab from './ProviderTab';
import PartnerTab from './PartnerTab';
import UserTab from './UserTab';


import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  firstCol: { width: '10%' },
  nestedFirstCol: { width: '20%' },
  checked: {}
}))

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ProfileScreen = props => {
  const { user, logout } = useContext(UserContext);
  const provider = useContext(ProviderContext);
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(0);

  const onTabChange = (event, newValue) => {
    setActiveTab(newValue);
  }

  return (
    <React.Fragment>
      <Tabs value={activeTab} onChange={onTabChange} aria-label="simple tabs example">
        <Tab label={t("forms.provider.partnerStatus")} {...a11yProps(0)} />
        <Tab label={t("generic.provider")} {...a11yProps(1)} />
        <Tab label={t("generic.user")} {...a11yProps(2)} />
      </Tabs>
      <TabPanel value={activeTab} index={0} style={{ maxWidth: 800 }}>
        <PartnerTab />
      </TabPanel>
      <TabPanel value={activeTab} index={1} style={{ maxWidth: 800 }}>
        <ProviderTab />
      </TabPanel>
      <TabPanel value={activeTab} index={2} style={{ maxWidth: 800 }}>
        <UserTab />
      </TabPanel>
    </React.Fragment>
  )
}


const ProfileScreenOld = props => {
  const { user, logout } = useContext(UserContext);
  const provider = useContext(ProviderContext);
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const providerPartial = <React.Fragment>
    {
      provider?.loading === true ?
        <LinearProgress color="secondary" />
        : provider?.id === null ?
          <Typography>Kein Anbieter ausgewählt</Typography>
          :
          <React.Fragment>
            <Grid item xs={12}>
              <Typography variant="h4">
                {t("forms.provider.partnerStatus")}

              </Typography>
            </Grid>
            <Grid item xs={12}>
              <PartnerState bookable={provider.bookable} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">
                Kontaktdaten
            </Typography>
            </Grid>
            <Grid item xs={12}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.firstCol}>
                      {t('generic.name')}
                    </TableCell>
                    <TableCell>
                      {provider.name}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.firstCol}>
                      {t('generic.description')}
                    </TableCell>
                    <TableCell>
                      {provider.description}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.firstCol}>
                      {t('forms.provider.contact')}
                    </TableCell>
                    <TableCell>
                      <Table size="small">
                        <TableBody>
                          <TableRow>
                            <TableCell className={classes.nestedFirstCol}>{t('forms.provider.address')}</TableCell>
                            <TableCell>{`${provider.contact?.street}, ${provider.contact?.zip} ${provider.contact?.city}`}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.nestedFirstCol}>{t('forms.contact.phone')}</TableCell>

                            <TableCell>{provider.contact?.phone}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.nestedFirstCol}>{t('forms.contact.email')}</TableCell>

                            <TableCell>{provider.contact?.email}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.nestedFirstCol}>{t('forms.contact.web')}</TableCell>

                            <TableCell>{provider.contact?.web}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableCell>
                  </TableRow>
                  <TableRow>

                    <TableCell className={classes.firstCol}>
                      {t('forms.provider.messaging')}
                    </TableCell>
                    <TableCell>
                      <Table size="small">
                        <TableBody>
                          <TableRow>
                            <TableCell className={classes.nestedFirstCol}>{t('generic.email')}</TableCell>
                            <TableCell>{provider?.messaging?.email}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.nestedFirstCol}>{t('generic.sms')}</TableCell>

                            <TableCell>{provider?.messaging?.sms}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.nestedFirstCol}>{t('forms.provider.devices')}</TableCell>

                            <TableCell>{
                              provider?.messaging.devices ?
                                provider.messaging.devices.map((device) => {
                                  return (
                                    <Typography>{device.deviceName}, {device.osName} {device.osVersion}</Typography>
                                  )
                                }) : ''
                            }</TableCell>
                          </TableRow>

                        </TableBody>
                      </Table>
                    </TableCell>

                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </React.Fragment>
    }

  </React.Fragment >
  return (
    <Container>
      <ListHeader
        title={t('navbar.profile')}
        hideNew={true}
      />
      <Grid container>

        {providerPartial}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={9}>
          <Typography variant="h4">{t("generic.user")}</Typography>
        </Grid>
        <Grid item xs={3} align="end">
          <FormControl >
            <Button
              variant="contained"
              onClick={() => logout()}
            >
              {t('forms.profile.actions.logout')}
            </Button>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12} style={{ paddingTop: theme.spacing(2) }}>
          <Table>
            <TableBody>
              <TableRow><TableCell className={classes.firstCol}>{t('generic.name')}</TableCell><TableCell>{`${user.firstName} ${user.lastName}`}</TableCell></TableRow>
              <TableRow><TableCell className={classes.firstCol}>{t('generic.email')}</TableCell><TableCell>{user.email}</TableCell></TableRow>
              <TableRow>
                <TableCell className={classes.firstCol}>{t('forms.profile.password')}</TableCell>
                <TableCell>
                  <Grid container>
                    {'*****'}
                    <Link href={`${process.env.REACT_APP_ACTYVYST_COM}/forgotpassword`} style={{ paddingLeft: 15 }} target="_blank"><Typography variant="caption">Ändern</Typography></Link>
                  </Grid>
                </TableCell>
              </TableRow>

            </TableBody>
          </Table>
        </Grid>



        <Grid item xs={9} style={{ paddingTop: 12 }}>
          <Typography variant="h4">{t("generic.provider")}</Typography>
        </Grid>
        <Grid item xs={3} align="end" style={{ paddingTop: 12 }}>
          <FormControl >
            <Button
              disabled={provider.id ? false : true}
              variant="contained"
              color="secondary"
              onClick={() => history.push(`/providers/${provider.id}`)}
            >
              {t('generic.edit')}
            </Button>
          </FormControl>
        </Grid>

        {/* {JSON.stringify(provider)} */}
      </Grid>
    </Container>
  )
}

ProfileScreen.propTypes = {

}

export default ProfileScreen
