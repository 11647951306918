const config = {
    contentLanguages: ['de', 'en', 'fr', 'es'],
    defaultContentLanguage: 'de',
    contentImagePath: 'https://media.actyvyst.de/images/activities/raw/',
    providerCountries: [
        "de",
        "fr",
        "nl",
        "gb",
        "it",
        "es",
        "be",
        "ch",
        "pt",
        "lu",
        "at"
    ],
    defaultConditions: {
        percentage: 15,
        fixed: 0
    }
}

export default config;