import React, { useState, useEffect } from 'react';
import { MenuItem, Input, Checkbox, ListItemText, InputLabel, Select, FormControlLabel, FormControl, FormLabel, Radio, RadioGroup, Paper, TextField, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Button } from '@material-ui/core';

const languages = ['de', 'en', 'es'];

const LoginForm = ({
  contactInfo,
  setContactInfo
}) => {
  const [languageSelectOpen, setLanguageSelectOpen] = useState(false);
  const handleLanguageChange = (event) => {

    setContactInfo({ ...contactInfo, languages: [...event.target.value.filter((item) => languages.indexOf(item) > -1)] })
  }
  return (
    <>
      <FormControl component="fieldset" style={{ width: '100%', marginTop: 10 }}>
        <FormLabel component="legend">Einloggen als:</FormLabel>
        <TextField
          style={{ width: '100%', marginTop: 10, marginBottom: 10 }}
          label="Name"
          value={contactInfo.name}
          onChange={(event) => { setContactInfo({ ...contactInfo, name: event.target.value }) }}
          size="small"
        />
        <TextField
          style={{ width: '100%', marginBottom: 10 }}
          label="Telefon"
          value={contactInfo.phone}
          onChange={(event) => { setContactInfo({ ...contactInfo, phone: event.target.value }) }}
          size="small"
        />
        <FormControl component="fieldset" style={{ width: '100%', marginTop: 5, marginBottom: 5 }}>
          <FormLabel component="legend">Geschlecht</FormLabel>
          <RadioGroup aria-label="gender" name="gender1" value={contactInfo.gender} onChange={(e) => { setContactInfo({ ...contactInfo, gender: e.target.value }) }}>
            <FormControlLabel value="m" control={<Radio size="small" />} label="Männlich" />
            <FormControlLabel value="f" control={<Radio size="small" />} label="Weiblich" />
          </RadioGroup>
        </FormControl>

        <FormControl style={{ width: '100%', marginTop: 5, marginBottom: 5 }}>
          <InputLabel>Sprachen</InputLabel>
          <Select
            multiple
            value={contactInfo.languages}
            onChange={handleLanguageChange}
            input={<Input />}
            renderValue={(selected) => selected.join(', ')}
            open={languageSelectOpen}
            onOpen={() => { setLanguageSelectOpen(true); }}
            onClose={() => { setLanguageSelectOpen(false) }}
          >

            {
              languages.map((language) => {
                return (
                  <MenuItem key={language} value={language} >
                    <Checkbox checked={contactInfo.languages.indexOf(language) > -1} />
                    <ListItemText primary={language} />
                  </MenuItem>
                )
              })
            }
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button color="secondary" onClick={() => { setLanguageSelectOpen(false) }}>Schließen</Button>
            </div>

          </Select>
        </FormControl>
      </FormControl>
    </ >
  )
}
const initialState = {
  name: '',
  gender: 'm',
  phone: '',
  languages: ['de', 'en']
};

const CallcenterLogin = ({
  action = "login",
  open,
  destination,
  languages,
  onAction = () => { },
  onCancel = () => { }
}) => {

  const [contactInfo, setContactInfo] = useState(initialState);
  const [target, setTarget] = useState('destination');

  useEffect(() => {
    if (!open) {
      setContactInfo(initialState);
      setTarget('destination');
    }
  }, [open])

  return (
    <Dialog open={open} onClose={() => { onCancel() }} >
      <DialogTitle>{action === 'login' ? 'Login' : 'Logout'}</DialogTitle>
      <DialogContent style={{ width: 400 }}>
        <FormControl component="fieldset" style={{ width: '100%', marginTop: 5, marginBottom: 5 }}>
          <FormLabel component="legend">{action === 'login' ? 'Einloggen für' : 'Abmelden von'}</FormLabel>
          <RadioGroup aria-label="target" name="target1" value={target} onChange={(e) => { setTarget(e.target.value) }}>
            <FormControlLabel value="destination" control={<Radio size="small" />} label={`Ausgewählte Destination (${destination})`} />
            {
              action === 'login' && <FormControlLabel value="unavailable" control={<Radio size="small" />} label="Nicht besetzte Destinationen" />
            }
            <FormControlLabel value="all" control={<Radio size="small" />} label="Alle Destinationen" />
          </RadioGroup>
        </FormControl>
        {
          action === 'login' ?
            <LoginForm
              contactInfo={contactInfo}
              setContactInfo={setContactInfo}
            /> : undefined
        }
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          style={{ width: 120 }}
          disabled={action === 'login' && (contactInfo.name.length < 1 || contactInfo.phone.length < 1)}
          color="secondary"
          onClick={() => { onAction({ action, target, contactInfo }); }}
        >{action === 'login' ? 'Login' : 'Logout'}</Button>
        <Button
          variant="contained"
          style={{ width: 120, backgroundColor: 'gray', color: 'white' }}
          onClick={() => { onCancel() }}
        >Abbrechen</Button>
      </DialogActions>
    </Dialog >
  )
}

export default CallcenterLogin;