import React, { useEffect, useState } from 'react';
import { Container, Typography, Card, Grid, Button, CardContent, CardActions, Grow } from '@material-ui/core';
import { formStyles } from '../Styles/forms';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  ...formStyles(theme),
  card: {
    marginTop: 10,
    marginBottom: 10
  },
  media: {
    height: 140,
  }
}));
const Upgrade = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [showCards, setShowCards] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      setShowCards(true);
    }, 500)
  }, [])

  return (
    <Container >
      <Grid container style={{ marginTop: 10, marginBottom: 10 }}>
        <Typography variant="h6">{t('forms.upgrade.title')}</Typography>
        <Grid container style={{ marginTop: 10, marginBottom: 10 }}>
          <Grow in={showCards}>
            <Grid item xs={12} >
              <Grid item lg={7} md={9} xs={12}>
                <Card className={classes.card}>
                  <CardContent>
                    <Typography variant="h6">{t('forms.upgrade.teaser')}</Typography>
                    <Typography variant="body2" component="p" >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('forms.upgrade.description', { 'interpolation': { 'escapeValue': false } })
                        }}
                      />
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button contained className={classes.button}
                      onClick={() => {
                        history.push({ pathname: `/apply` });
                      }}
                    >
                      {t('forms.upgrade.button')}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={5}></Grid>
            </Grid>
          </Grow>

        </Grid>
      </Grid>
    </Container >
  )
}

export default Upgrade;