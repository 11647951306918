import React, { createContext, useReducer } from 'react';
import {
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { useTranslation } from "react-i18next";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/de";
class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return moment(date).format("LL");
  }
}

export const AppContext = createContext();

const initialAppState = {
  error: null
}

const appContextReducer = (state, action) => {
  switch (action.type) {
    case 'SET_ERROR':
      return { ...state, error: action.payload }
    default: return { ...state }
  }
}

export const AppContextProvider = (props) => {
  const [appState, dispatch] = useReducer(appContextReducer, initialAppState, (initialState) => {
    return {

    }
  });
  const { error } = appState;
  const { i18n } = useTranslation();
  moment.locale(i18n.language);

  const setError = (err) => {
    dispatch({ type: 'SET_ERROR', payload: err });
  }

  const resetError = () => {
    dispatch({ type: 'SET_ERROR', payload: null });
  }

  return (
    <AppContext.Provider value={{
      error,
      setError,
      resetError
    }}>
      <MuiPickersUtilsProvider libInstance={moment} utils={LocalizedUtils} locale={i18n.language}>
        {props.children}
      </MuiPickersUtilsProvider>
    </AppContext.Provider>
  )
}