import React, { useEffect, useState, useRef } from "react";
import {
  LinearProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import "moment/locale/de";
import { useFormikContext, useField } from 'formik';

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2)
  },
  mItem: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: "1em"
  },

  formControl: {
    width: "100%",
    // minWidth: 120,
    // maxWidth: 300,
    // marginBottom: 10,
    // marginLeft: 6
    paddingRight: 6
  },
  root: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: "1em",
  }
}));

const nestedValue = (object, key) => {
  const _nestedRecursive = (object, keys) => {
    if (keys.length < 2) {
      return object[keys[0]]
    }
    else {
      let nestedObject = object[keys[0]];
      keys.shift();
      return _nestedRecursive(nestedObject, keys);
    }
  }
  const keys = key.split('.');
  return _nestedRecursive(object, keys)
}

const GOFormikSelect = props => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);

  const [labelWidth, setLabelWidth] = useState(0);
  useEffect(() => {
    if (inputLabel?.current?.offsetWidth) {
      setLabelWidth(inputLabel.current.offsetWidth);
    }
  }, []);

  const inputLabel = useRef(null);
  const classes = useStyles();
  const {
    name,
    value,
    dataSet,
    inputLabelId,
    labelText,
    datafilters
  } = props;

  const [filteredData, setFilteredData] = useState([]);

  const filterData = () => {
    let tmpfiltered = [];
    if (datafilters !== undefined && datafilters.length > 0) {
      datafilters.forEach(filterset => {
        if (filterset.value !== "") {
          tmpfiltered = dataSet.data.filter(
            dataitem => nestedValue(dataitem, filterset.key) === filterset.value
          );
        }
      });
      return tmpfiltered;
    } else {
      return dataSet.data;
    }
  };

  useEffect(() => {
    if (filteredData.length > 0 && value === '') {
      setFieldValue(name, filteredData[0].id, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData, name, value])

  useEffect(() => {
    setFilteredData(filterData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSet, datafilters]);

  useEffect(() => {
    if (dataSet.shouldUpdate) {
      dataSet.fetchList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSet.shouldUpdate]);

  return (
    <FormControl className={classes.formControl}>
      {dataSet.loading ? (
        <LinearProgress color="secondary" />
      ) : (
          <React.Fragment>
            <InputLabel id={inputLabelId} ref={inputLabel}>
              {labelText}
            </InputLabel>
            <Select
              labelId={inputLabelId}
              labelWidth={labelWidth}
              className={classes.root}
              {...props}
            >
              {filteredData.sort((a, b) => { if (props.sort) { return a.name > b.name ? 1 : -1 } else { return 0 } }).map(item => {
                return (
                  <MenuItem
                    className={classes.mItem}
                    value={item.id}
                    key={item.id}
                  >
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </React.Fragment>
        )
      }
      <FormHelperText
        id={`${field.name}-helper-text`}
        error={meta.error && meta.touched}
        component="p"
      >
        {meta.error && meta.touched
          ? meta.error
          : ""}
      </FormHelperText>
    </FormControl >
  );
};

GOFormikSelect.defaultProps = {
  sort: true
};

export default GOFormikSelect;
