import React, { useEffect, useState } from 'react';
import { Paper, Container, Table, TableContainer, TableBody, TableCell, TableHead, TableRow, Button } from '@material-ui/core';
import axios from 'axios';
import CallcenterLogin from './CallcenterLogin';

axios.defaults.baseURL = process.env.REACT_APP_API_HOST;

const CallcenterScreen = () => {
  const [callcenters, setCallcenters] = useState([]);
  const [shouldUpdate, setShouldUpdate] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [action, setAction] = useState('login');

  useEffect(() => {
    const loadStatus = async () => {
      try {
        setLoading(true);
        setError(null);
        const response = await axios.get('/activities/callcenter/all');
        setCallcenters(response.data);
      } catch (err) {
        console.log(err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }
    if (shouldUpdate) {
      setShouldUpdate(false);
      loadStatus();
    }
  }, [shouldUpdate]);

  const collectDestinations = (target) => {
    let destinations = [];
    switch (target) {
      case 'destination':
        destinations.push(selectedItem.destination)
        break;
      case 'unavailable':
        for (const item of callcenters) {
          if (!item.available) {
            destinations.push(item.destination)
          }
        }
        break;
      case 'all':
        for (const item of callcenters) {
          destinations.push(item.destination)
        }
        break;
      default:
        break;
    }
    return destinations;
  }

  const loginToCallcenter = async (target, contactInfo) => {
    try {
      const destinations = collectDestinations(target);
      await axios.post('/activities/callcenter/login', {
        destinations,
        contact: { ...contactInfo }
      });
      setShouldUpdate(true);
    } catch (err) {
      console.log(err);
      alert(err.message)
    }
  }

  const logoutFromCallcenter = async (target) => {
    try {
      const destinations = collectDestinations(target);
      await axios.post('/activities/callcenter/logout', {
        destinations
      });
      setShouldUpdate(true);
    } catch (err) {
      console.log(err);
      alert(err.message)
    }
  }

  const rows = callcenters.map((callcenter) => {
    const languages = callcenter.contact?.languages ?
      callcenter.contact.languages.reduce((acc, cur, index) => {
        return `${acc}${index === 0 ? '' : ', '}${cur}`
      }, '')
      : '';
    return (
      <TableRow style={callcenter.available !== true ? { backgroundColor: '#f6f6f6' } : {}}>
        <TableCell>{callcenter.destination}</TableCell>
        {callcenter.available === true ?
          <>
            <TableCell>{callcenter.contact?.name}</TableCell>
            <TableCell>{callcenter.contact?.gender}</TableCell>
            <TableCell>{callcenter.contact?.phone}</TableCell>
            <TableCell>{languages}</TableCell>
          </>
          : <TableCell colSpan={4}><div style={{ textAlign: 'center' }}><em>Nicht besetzt</em></div></TableCell>
        }
        <TableCell>
          <Button
            variant="contained"
            color={callcenter.available === true ? 'primary' : 'secondary'}
            style={{ width: 120, backgroundColor: callcenter.available === true ? 'gray' : undefined }}
            onClick={() => { setSelectedItem(callcenter); setAction(callcenter.available ? 'logout' : 'login'); setDialogOpen(true) }}
          >
            {callcenter.available === true ? 'Logout' : 'Login'}
          </Button>
        </TableCell>
      </TableRow >
    )
  })


  return (
    <Container>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Destination</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>m/f</TableCell>
              <TableCell>Telefon</TableCell>
              <TableCell>Sprachen</TableCell>
              <TableCell>Aktion</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows}
          </TableBody>
        </Table>
      </TableContainer>
      <CallcenterLogin
        open={dialogOpen}
        destination={selectedItem?.destination}
        action={action}
        onAction={({ action, target, contactInfo }) => {
          setDialogOpen(false);
          if (action === 'login') {
            loginToCallcenter(target, contactInfo);
          } else {
            logoutFromCallcenter(target);
          }
        }}
        onCancel={() => { setDialogOpen(false) }}
      />
    </Container>
  )
}

export default CallcenterScreen;