import React, { useEffect } from "react";
import {
  TextField,
  FormControl,
  FormHelperText,
  useTheme,
  LinearProgress
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { formStyles } from "../../Styles/forms";
import { useField, useFormikContext } from "formik";
import clsx from "clsx";

const useStyles =
  makeStyles(theme => ({
    ...formStyles(theme),
    root: {
      marginTop: 20,
      "& > * + *": {
        marginTop: theme.spacing(3)
      }
    }
  }));


const GOFormikTags = props => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  const { labelText, dataSet } = props;
  // const { move, remove, swap, push, insert, unshift, pop, form } = props;


  useEffect(() => {
    if (dataSet.shouldUpdate) {
      dataSet.fetchList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSet.shouldUpdate]);


  return (
    <FormControl className={clsx(classes.textInput, classes.root)}>
      {dataSet.shouldUpdate || dataSet.isLoading ?
        <LinearProgress /> :
        <React.Fragment>
          <Autocomplete
            name={props.name}
            multiple
            options={dataSet.data}
            getOptionLabel={option => {
              const category = dataSet.data.find((item) => item.id === option.id);
              return category ? category.name : ''
            }}
            onInputChange={(event, value, reason) => {
              console.log('INPUT CHANGE', reason)
            }}
            onChange={(event, items) => {
              console.log('ON CHANGE', items)

              if (!props.maxEntries || items.length <= props.maxEntries) {
                setFieldValue(props.name, items);
              }
              if (props.afterChange) { props.afterChange(event) };
            }}
            value={field.value.map((item) => {
              return item;
            })}
            filterSelectedOptions
            renderInput={params => (
              <TextField
                {...params}
                // name={props.name}
                // variant="outlined"
                label={labelText}
                placeholder={labelText}
                fullWidth
                style={{ marginTop: 10 }}
              />
            )}

          />
          <FormHelperText
            id={`${field.name}-helper-text`}
            className={classes.formHelper}
            error={meta.error}
            component="p"
          >
            {meta.error ? meta.error : ""}
          </FormHelperText>
        </React.Fragment>
      }
    </FormControl>

  );
};

GOFormikTags.propTypes = {
  // dataSet: PropTypes.array,
  // getOptionLabel: PropTypes.func
};

GOFormikTags.defaultProps = {
  // dataSet: []
};

export default GOFormikTags;
