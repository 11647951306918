import React from "react";
import { Checkbox, FormControlLabel, FormHelperText, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useField } from 'formik';
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const useStyles = makeStyles((theme) => {
  return ({
    checked: {},
    checkbox: {
      color: theme.palette.primary.main,
      "&$checked": {
        color: theme.palette.primary.light
      }
    },
    checkBoxIcon: (props) => {
      let fontSize;
      switch (props.size) {
        case 'small':
          fontSize = 12;
          break;
        case 'large':
          fontSize = 36;
          break;
        case 'medium':
        default:
          fontSize = 24;
      }
      return ({ fontSize: fontSize })
    },
    formControlLabel: props => {
      let fontSize;
      switch (props.size) {
        case 'small':
          fontSize = theme.typography.subtitle1.fontSize;
          // fontSize = 8;
          break;
        case 'large':
          fontSize = theme.typography.h4.fontSize;
          break;
        case 'medium':
        default:
          fontSize = theme.typography.fontSize;
      }
      return {
        fontSize: fontSize
      }
    }
  })
});



const GOFormikCheckBox = props => {
  const classes = useStyles(props);
  const [field, meta] = useField(props);
  const { labelText } = props;

  return (
    <React.Fragment>
      <FormControlLabel
        {...props}

        control={
          <Checkbox
            {...props}
            name={props.name}
            id={props.name}
            onChange={props.onChange}
            onSubmit={props.onSubmit}
            value={props.value}
            checked={props.value}
            className={props.className || classes.checkbox}
            icon={<CheckBoxOutlineBlankIcon className={classes.checkBoxIcon} />}
            checkedIcon={<CheckBoxIcon className={classes.checkBoxIcon} />}
          />
        }

        label={<Typography className={classes.formControlLabel}>{labelText}</Typography>}
        styles={{ ...props.styles }}
      // classes={{ label: classes.formControlLabel }}
      // classes={labelClasses}

      />
      <FormHelperText
        id={`${field.name}-helper-text`}
        error={meta.error && meta.touched}
        component="p"
      >
        {meta.error && meta.touched
          ? meta.error
          : ""}
      </FormHelperText>
    </React.Fragment>
  );
};
GOFormikCheckBox.defaultProps = {
  size: 'medium'
};

export default GOFormikCheckBox;
