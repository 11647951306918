import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import LoginScreen from "./Screens/LoginScreen";
import ProfileScreen from "./Screens/ProfileScreen";
import CategoryScreen from "./Screens/CategoryScreen";
import ActivityScreen from "./Screens/ActivityScreen";
import CalendarScreen from "./Screens/CalendarScreen";
import FormScreen from "./Screens/FormScreen";
import TestScreen from "./Screens/TestScreen";
import RegisterScreen from "./Screens/RegisterScreen";
import SlotList from "./Screens/SlotList";
import VariantList from "./Screens/VariantList";
import BookingList from "./Screens/BookingList";
import EventList from "./Screens/EventList";
import ProviderScreen from "./Screens/ProviderScreen";
import Welcome from "./Screens/Welcome";
import ErrorScreen from './Screens/ErrorScreen';
import ProviderRequired from './Screens/ProviderRequired';
import MakeBookable from './Screens/MakeBookable';
import StyleGuide from './Test/StyleGuide';
import CallcenterScreen from './Screens/CallcenterScreen';
import GTC from './Screens/GTC';
import ProviderApplicationList from './Screens/ProviderApplicationList';
import Upgrade from './Screens/Upgrade';
import NavBar from "./Modules/Nav/NavBar";
import theme from "./theme";
import axios from "axios";
import { UserContextProvider, UserContext } from "./Context/UserContext";
import { DataContextProvider } from "./Context/DataContext";
import { AppContextProvider, AppContext } from "./Context/AppContext";
import { ProviderContextProvider } from "./Context/ProviderContext";

import PrivateRoute from "./Modules/Routes/PrivateRoute";
import IdleHandler from "./Modules/Util/IdleHandler";

function App() {

  useEffect(() => {
    axios.defaults.baseURL = process.env.REACT_APP_API_HOST;
  }, []);
  return (
    <AppContextProvider>
      <UserContextProvider>
        <UserContext.Consumer>
          {({ user }) => {
            return (
              <ProviderContextProvider providerId={user ? user.provider : null}>
                <DataContextProvider>
                  <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Router>
                      <div style={{ display: "flex" }}>
                        <IdleHandler />
                        <NavBar />
                        <Container style={{ flexGrow: 1, marginTop: 50, padding: 5, minWidth: 800 }}>
                          <AppContext.Consumer>
                            {({ error }) => {
                              return (
                                <Switch>
                                  <PrivateRoute exact path="/">
                                    <Welcome />
                                  </PrivateRoute>
                                  <Route path="/login">
                                    <LoginScreen />
                                  </Route>
                                  <Route path="/gtc">
                                    <GTC />
                                  </Route>
                                  <Route path="/style">
                                    <StyleGuide />
                                  </Route>
                                  <PrivateRoute path="/providerapplications" requiresAdmin={true}>
                                    <ProviderApplicationList />
                                  </PrivateRoute>
                                  <PrivateRoute path="/test">
                                    <TestScreen />
                                  </PrivateRoute>
                                  <PrivateRoute path="/profile">
                                    <ProfileScreen />
                                  </PrivateRoute>
                                  <PrivateRoute path="/categories/:id" requiresAdmin={true}>
                                    <FormScreen />
                                  </PrivateRoute>
                                  <PrivateRoute path="/categories" requiresAdmin={true}>
                                    <CategoryScreen />
                                  </PrivateRoute>
                                  <PrivateRoute path="/activities/:id">
                                    <FormScreen />
                                  </PrivateRoute>
                                  <PrivateRoute path="/activities">
                                    <ActivityScreen />
                                  </PrivateRoute>
                                  <PrivateRoute path="/slots">
                                    <SlotList />
                                  </PrivateRoute>
                                  <PrivateRoute path="/variants/:id" requiresBookable={true}>
                                    <FormScreen />
                                  </PrivateRoute>
                                  <PrivateRoute path="/variants" requiresBookable={true}>
                                    <VariantList />
                                  </PrivateRoute>
                                  <PrivateRoute path="/bookings" requiresBookable={true}>
                                    <BookingList />
                                  </PrivateRoute>
                                  <PrivateRoute path="/events">
                                    <EventList />
                                  </PrivateRoute>
                                  <PrivateRoute path="/calendar" requiresBookable={true} requiresProvider={true}>
                                    <CalendarScreen />
                                  </PrivateRoute>
                                  <PrivateRoute path="/providers/:id">
                                    <FormScreen />
                                  </PrivateRoute>
                                  <PrivateRoute path="/providerrequired">
                                    <ProviderRequired />
                                  </PrivateRoute>
                                  <PrivateRoute path="/providers" requiresAdmin={true}>
                                    <ProviderScreen />
                                  </PrivateRoute>
                                  <PrivateRoute path="/callcenter" requiresAdmin={true}>
                                    <CallcenterScreen />
                                  </PrivateRoute>
                                  <Route path="/apply">
                                    <MakeBookable />
                                  </Route>
                                  <Route path="/upgrade">
                                    <Upgrade />
                                  </Route>
                                  <Route path="/register/:id">
                                    <RegisterScreen />
                                  </Route>
                                  <Route path="/error/:error">
                                    <ErrorScreen />
                                  </Route>
                                  {
                                    user ?
                                      <Redirect to="/" />
                                      : <Redirect to="/login" />
                                  }
                                </Switch>
                              )
                            }}
                          </AppContext.Consumer>
                        </Container>
                      </div>
                    </Router>
                  </ThemeProvider>
                </DataContextProvider>
              </ProviderContextProvider>
            )
          }}
        </UserContext.Consumer>
      </UserContextProvider>
    </AppContextProvider>
  );
}

export default App;
