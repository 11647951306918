import React, { useContext } from "react";
import GOFormikSelect from '../../Modules/Forms/GOFormikSelect';
import GOFormikSimpleSelect from '../../Modules/Forms/GOFormikSimpleSelect';
import GOFormikTextInput from '../../Modules/Forms/GOFormikTextInput';
import GOFormikCheckBox from '../../Modules/Forms/GOFormikCheckBox';
import { validationConstants } from './validation';

import { Form, Field, FieldArray } from 'formik';
import { useTranslation } from "react-i18next";
import {
  Typography,
  Grid,
  Button,
  Icon,
  IconButton,
  Divider
} from "@material-ui/core";

// import "moment/locale/de";
import { DataContext } from "../../Context/DataContext";
import ExternalVariantPartial from './ExternalVariantPartial';
import InternalVariantPartial from './InternalVariantPartial';
import PriceCategoryListPartial from './PriceCategoryListPartial';

const VariantFormUI = props => {
  const { t } = useTranslation();
  const { Activity } = useContext(DataContext);

  return (
    <Form>
      <Grid container spacing={2} style={{ marginTop: 10 }}>

        <Grid item xs={12}>
          <Field
            name="activity.id"
            as={GOFormikSelect}
            dataSet={Activity}
            optionLabel={option => option.name}
            labelText={t("generic.activity")}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name={`translations.${props.contentLanguage}.name`}
            as={GOFormikTextInput}
            labelText={t('generic.name')}
            flag={props.contentLanguage}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name={`translations.${props.contentLanguage}.description`}
            as={GOFormikTextInput}
            labelText={t('generic.description')}
            flag={props.contentLanguage}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name={`translations.${props.contentLanguage}.details`}
            as={GOFormikTextInput}
            labelText={t('forms.variant.details')}
            maxChars={validationConstants.details.max}
            multiline
            rows="4"
            rowsMax="10"
            flag={props.contentLanguage}
          />
        </Grid>
        <InternalVariantPartial
          {...props}
        />
        <FieldArray
          name="priceCategories"
          render={(arrayHelpers) => <PriceCategoryListPartial {...arrayHelpers} contentLanguage={props.contentLanguage} />}
        />

      </Grid>
    </Form >
  );
};

export default VariantFormUI;
