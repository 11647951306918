import React from 'react';
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const MakeBookableConfirmation = () => {
  const { t } = useTranslation();
  return (
    <Grid container style={{ padding: 20, width: '100%', minWidth: 400, maxWidth: 1000 }}>
      <Typography variant="h6">{t('forms.makeBookable.confirmation.title')}</Typography>
      <Grid container style={{ marginTop: 10, marginBottom: 10 }}>
        <Typography >{t('forms.makeBookable.confirmation.details')}</Typography>
      </Grid>
      <Grid container style={{ marginTop: 10, marginBottom: 10 }}>
        <Link to="/variants"><b>{t('forms.makeBookable.confirmation.gotoVariants')}</b></Link>
      </Grid>
      <Grid container style={{ marginTop: 10, marginBottom: 10 }}>
        <Link to="/calendar"><b>{t('forms.makeBookable.confirmation.gotoCalendar')}</b></Link>
      </Grid>
    </Grid>
  )
}

export default MakeBookableConfirmation;