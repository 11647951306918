import React from "react";

import GOFormikTextInput from "../../Modules/Forms/GOFormikTextInput";
import GOFormikSimpleSelect from "../../Modules/Forms/GOFormikSimpleSelect";
import { Field } from "formik";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import config from "../../Config/config";
import moment from "moment";
import "moment/locale/de";
import countries from "i18n-iso-countries"
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/de.json"));

moment.locale("de");

const LocationFormUI = props => {
  const { t } = useTranslation();
  // const countryIso =
  //   values.contact.country !== ""
  //     ? String(values.contact.country).toUpperCase()
  //     : "DE";

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Typography variant="h4">{t("generic.location")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Field
          name="location.country"
          as={GOFormikSimpleSelect}
          dataSet={config.providerCountries.map(iso => ({
            key: iso,
            title: t(`generic.countries.${iso}`)
          }))}
          labelText={t("forms.contact.country")}
        />

      </Grid>
      <Grid item xs={6}>
        <Field
          name="location.zip"
          as={GOFormikTextInput}
          labelText={t("forms.location.zip")}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          name="location.city"
          as={GOFormikTextInput}
          labelText={t("forms.location.city")}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="location.street"
          as={GOFormikTextInput}
          labelText={t("forms.location.street")}
        />
      </Grid>
    </React.Fragment>
  );
};

export default LocationFormUI;
