import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import FilterBar from '../../Modules/FilterBar';
import PropTypes from 'prop-types';
import { DataContext } from "../../Context/DataContext";



const CalendarFilter = (props) => {
  const { t } = useTranslation();
  const { Provider, Activity, Variant } = useContext(DataContext);
  const initialFilters = [
    {
      name: t('generic.provider_plural'),
      data: [],
      valueCol: 'id',
      displayCol: 'name',
      selected: ''
    }, {
      name: t('generic.activity_plural'),
      data: [],
      valueCol: 'id',
      displayCol: 'name',
      selected: '',
      dependentOn: 0,
      dependencyCol: 'provider.id'
    }, {
      name: t('generic.variant_plural'),
      data: [],
      valueCol: 'id',
      displayCol: 'name',
      selected: '',
      dependentOn: 1,
      dependencyCol: 'activity.id'
    }
  ]

  const [filters, setFilters] = useState(initialFilters);

  const setFilterData = (data, index) => {
    setFilters(filters.map((filter, i) => {
      if (i === index) {
        return {
          ...filter,
          data: data,
          selected: ''
        }
      } else {
        return {
          ...filter
        }
      }
    }))
  }

  const onFilterChange = (values) => {
    console.log('CalendarFilter onChange')
    const filterLabels = ['provider', 'activity', 'variant'];
    let filter = {};
    for (let i = 0; i < values.length; i++) {
      if (values[i] !== '') { filter[filterLabels[i]] = values[i] }
    }
    props.onChange(filter);
  }

  useEffect(() => {
    if (Provider.data.length < 1) Provider.setShouldUpdate();
    if (Activity.data.length < 1) Activity.setShouldUpdate();
    if (Variant.data.length < 1) Variant.setShouldUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Provider, Activity, Variant]);

  useEffect(() => {
    if (Provider.shouldUpdate && !Provider.loading) {
      Provider.fetchList();
    }
    if (Activity.shouldUpdate && !Activity.loading) {
      Activity.fetchList();
    }
    if (Variant.shouldUpdate && !Variant.loading) {
      Variant.fetchList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Provider.shouldUpdate, Activity.shouldUpdate, Variant.shouldUpdate]);


  useEffect(() => {
    setFilterData(Provider.data, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Provider.data]);

  useEffect(() => {
    setFilterData(Activity.data, 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Activity.data]);

  useEffect(() => {
    setFilterData(Variant.data, 2)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Variant.data]);

  return (
    <FilterBar
      filters={filters}
      onChange={(values) => { onFilterChange(values) }}
    />
  )
}

CalendarFilter.propTypes = {
  onChange: PropTypes.func
}
CalendarFilter.defaultProps = {
  onChange: (values) => { console.log('default onChange', values) }
}

export default CalendarFilter;