import React from "react";
import { useTranslation } from "react-i18next";
import {
    Typography,
    Grid,
    Button,
    Icon,
    IconButton,
} from "@material-ui/core";
import { Form, Field, } from "formik";
import GOFormikTextInput from "../../Modules/Forms/GOFormikTextInput";
import { makeStyles } from '@material-ui/core';
import { formStyles } from '../../Styles/forms';

const useStyles = makeStyles((theme) => ({
    ...formStyles(theme)
}));

const InfoList = props => {
    const classes = useStyles();
    const { move, remove, push, form } = props;
    const { t } = useTranslation();
    const maxInfos = 5;

    return (
        <Form>
            <input type="hidden" value="prayer" />
            <Grid container style={{ marginBottom: 20 }}>
                <Grid item xs={12}>
                    <Typography variant="h4">{t("forms.activity.info")}</Typography>
                </Grid>
                {form.values.translations[props.contentLanguage].infos.map(
                    (item, index) => {
                        return (
                            <Grid
                                container
                                key={`translations.${props.contentLanguage}.infos[${index}]`}
                            >
                                <Grid item xs={11}>
                                    <Field
                                        as={GOFormikTextInput}
                                        noValidationDisplay
                                        flag={props.contentLanguage}
                                        name={`translations.${props.contentLanguage}.infos[${index}]`}
                                        label={t("forms.activity.info")}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <IconButton
                                                size="small"
                                                onClick={() => {
                                                    remove(index);
                                                }}
                                            >
                                                <Icon color="secondary" variant="outlined" fontSize="small">
                                                    remove_circle
                                                </Icon>
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {
                                                index < maxInfos - 1 ? (
                                                    <IconButton size="small" onClick={() => push("")}>
                                                        <Icon color="secondary" fontSize="small">
                                                            add_circle
                                                        </Icon>
                                                    </IconButton>
                                                ) : (
                                                        <React.Fragment />
                                                    )
                                            }
                                        </Grid>
                                        <Grid item xs={6}>
                                            {index > 0 ? (
                                                <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                        move(index, index - 1);
                                                    }}
                                                >
                                                    <Icon
                                                        color="secondary"
                                                        variant="outlined"
                                                        fontSize="small"
                                                    >
                                                        arrow_upward
                                                    </Icon>
                                                </IconButton>
                                            ) : (
                                                    <React.Fragment />
                                                )}
                                        </Grid>
                                        <Grid item xs={6}>
                                            {index <
                                                form.values.translations[props.contentLanguage].infos.length -
                                                1 ? (
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => move(index, index + 1)}
                                                    >
                                                        <Icon color="secondary" fontSize="small">
                                                            arrow_downward
                                                        </Icon>
                                                    </IconButton>
                                                ) : (
                                                    <React.Fragment />
                                                )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    }
                )}
                {form.values.translations[props.contentLanguage].infos.length < 1 ? (
                    <Button className={classes.button} onClick={() => push("")}>
                        {t("forms.activity.newInfo")}
                    </Button>
                ) : (
                        <React.Fragment />
                    )}
            </Grid>
        </Form>
    );
};

export default InfoList;
