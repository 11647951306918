import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { Formik } from 'formik';
import { useTranslation } from "react-i18next";
import { GenericAPI } from '../../API/generic';
import { BookingSerializer } from '../../API/Serializers/Booking';
import { ValidationSchema } from './validation';

const initialData = {
  bookingType: 'reservation_only',
  slot: null,
  reservation: {
    numParticipants: 1,
    comment: ''
  },
  booker: {
    name: '',
    email: '',
    phone: ''
  },
}

const ReservationFormController = (props) => {
  const { id, slot, render, onDataChanged, onClose } = props;
  const [formData, setFormData] = useState({ ...initialData });
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [saving, setSaving] = useState(false);
  // const [title, setTitle] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    const loadAsync = async () => {
      try {
        setLoading(true);
        const data = GenericAPI(`/activities/bookings`).fetchItem(id);
        setFormData(data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        alert(err);
      }
    }
    if (id === 'new') {
      setFormData({ ...initialData, slot });
    } else {
      if (id && id.length > 0) {
        loadAsync();
      }
    }
  }, [id, slot]);

  // useEffect(() => {
  //     alert(formData.slot);
  // }, formData.slot)
  const handleSubmit = (values, formikActions) => {
    const createBooking = async () => {
      try {
        setSaving(true);
        // alert(JSON.stringify(values));
        if (id === 'new') {
          const response = await GenericAPI(`/activities/bookings`).createItem(
            BookingSerializer.serialize(values)
          );
          // alert(JSON.stringify(response));
          const newData = {
            bookingType: response.bookingType,
            slot: response.slot.id,
            reservation: { ...response.reservation },
            booker: { ...response.booker },
          };
          console.log(response);
          console.log(newData)
          setFormData(newData);
          formikActions.resetForm(newData);
          onDataChanged();
          onClose();
          setFormData({ ...initialData, slot });
          formikActions.resetForm({ ...initialData, slot });
        } else {
          alert('Edit booking not implemented yet')
        }
        setSaving(false);
      } catch (err) {
        setSaving(false);
        console.log(err);
        alert(t('forms.reservation.error.save'));
      }
    }
    createBooking();
  }
  return (
    <Formik
      enableReinitialize={true}
      initialValues={formData}
      onSubmit={handleSubmit}
      validationSchema={ValidationSchema(t)}
      validateOnBlur={true}
      validateOnChange={false}
      render={(formikProps) => {
        return (
          <React.Fragment>
            {render({ ...formikProps, title: id === 'new' ? t('forms.reservation.actions.new') : t('forms.reservation.actions.edit'), loading })}
            {/* <Grid container>
              <Typography paragraph={true} display="inline">slot: {slot} </Typography><br></br>
              <Typography paragraph display="inline">formik: {formikProps.values?.slot}</Typography>
              <Typography paragraph display="inline">id: {id}</Typography>
            </Grid> */}
          </React.Fragment>
        )
      }}
    />
  )
}
ReservationFormController.propTypes = {
  id: PropTypes.string.isRequired,
  slot: PropTypes.string,
  onDataChanged: PropTypes.func
}

ReservationFormController.defaultProps = {
  id: 'new',
  slot: null,
  onDataChanged: () => { }
};

export default ReservationFormController;