import React from "react";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { formStyles } from "../../Styles/forms";
import { Button, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  ...formStyles(theme),
}))

const FormFooter = ({ showCancel, dirty, onSave, onClose, wurst }) => {
  const theme = useTheme();
  const formClasses = useStyles(theme);
  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container justify="flex-end" >
          {
            showCancel ?
              <Button className={formClasses.button} onClick={onClose}>
                Abbrechen
                            </Button> :
              <React.Fragment />
          }
          <Button
            disabled={!dirty}
            classes={{ root: formClasses.button, disabled: formClasses.disabledButton }}
            onClick={onSave}
          >
            {t('generic.save')}
          </Button>
        </Grid>
      </Grid>

    </Grid>
  )
}
FormFooter.propTypes = {
  dirty: PropTypes.bool,
  showCancel: PropTypes.bool,
  onSave: PropTypes.func,
  onClose: PropTypes.func
}
FormFooter.defaultProps = {
  dirty: false,
  showCancel: false,
  onSave: () => { alert('Not implemented') },
  onClose: () => { alert('Not implemented') }
}

export default FormFooter;