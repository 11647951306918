import React from "react";

import GOFormikTextInput from "../../Modules/Forms/GOFormikTextInput";
import GOFormikPhoneInput from "../../Modules/Forms/GOFormikPhoneInput";
import GOFormikSimpleSelect from "../../Modules/Forms/GOFormikSimpleSelect";
import { Field, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import "moment/locale/de";

moment.locale("de");

const ContactFormUI = props => {
  const { t } = useTranslation();
  const { values } = useFormikContext();
  const countryIso =
    values.contact.country !== ""
      ? String(values.contact.country).toLowerCase()
      : "de";
  const providerCountries = [
    "de",
    "fr",
    "nl",
    "gb",
    "it",
    "es",
    "be",
    "ch",
    "pt",
    "lu",
    "at"
  ];
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Typography variant="h4">{t("generic.contact")}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Field
          name="contact.firstname"
          as={GOFormikTextInput}
          labelText={t("forms.contact.firstName")}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          name="contact.lastname"
          as={GOFormikTextInput}
          labelText={t("forms.contact.lastName")}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="contact.street"
          as={GOFormikTextInput}
          labelText={t("forms.contact.street")}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          name="contact.zip"
          as={GOFormikTextInput}
          labelText={t("forms.contact.zip")}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          name="contact.city"
          as={GOFormikTextInput}
          labelText={t("forms.contact.city")}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="contact.country"
          as={GOFormikSimpleSelect}
          dataSet={providerCountries.map(iso => ({
            key: iso,
            title: t(`generic.countries.${iso}`)
          }))}
          labelText={t("forms.contact.country")}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          name="contact.email"
          as={GOFormikTextInput}
          labelText={t("forms.contact.email")}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          name="contact.phone"
          as={GOFormikPhoneInput}
          labelText={t("forms.contact.phone")}
          countryCode={countryIso}
        />
      </Grid>

    </React.Fragment>
  );
};

export default ContactFormUI;
