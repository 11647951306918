
import React, { useReducer, useEffect, createContext, useContext } from 'react';
import { dataReducer, dataInitialState } from './DataReducer';
import { ProviderSerializer } from '../API/Serializers/Provider';
import { ActivitySerializer } from '../API/Serializers/Activity';
import { VariantSerializer } from '../API/Serializers/Variant';
import { CategorySerializer } from '../API/Serializers/Category';
import { SlotSerializer } from '../API/Serializers/Slot';
import { EventSerializer } from '../API/Serializers/Event';
import { ProviderContext } from '../Context/ProviderContext';
import { entities } from './DataReducer';

import { UserContext } from './UserContext';

import { GenericAPI } from '../API/generic';
import { ActivityAPI } from '../API/activity';
export const DataContext = createContext();

const serializers = {
  PROVIDER: ProviderSerializer,
  ACTIVITY: ActivitySerializer,
  VARIANT: VariantSerializer,
  SLOT: SlotSerializer,
  CATEGORY: CategorySerializer,
  EVENT: EventSerializer
};

export const DataContextProvider = (props) => {
  const { user } = useContext(UserContext);
  const providerContext = useContext(ProviderContext);

  const [state, dispatch] = useReducer(dataReducer, {
    ...dataInitialState
  });



  const makeFetchList = (api, entity, filter = {}) => {
    return async (query = {}) => {
      try {
        const q = { ...query, ...filter }
        dispatch({ type: `${entity}_FETCH_LIST` });
        const data = await api.fetchList(q);
        dispatch({ type: `${entity}_FETCH_LIST_SUCCESS`, payload: data });
      } catch (err) {
        console.log(err);
        dispatch({ type: `${entity}_FETCH_LIST_FAILURE`, payload: err.message });
        throw (err);
      }
    }
  }

  const makeFetchItem = (api, entity, filter = {}) => {
    return async (id, query = {}) => {
      try {
        const q = { ...query, ...filter };
        dispatch({ type: `${entity}_FETCH_ITEM` });
        let data;
        const object = await api.fetchItem(id, q);
        if (Array.isArray(object)) {
          data = object[0];
        } else { data = object }
        dispatch({ type: `${entity}_FETCH_ITEM_SUCCESS`, payload: data });
        return data;
      } catch (err) {
        console.log(err);
        dispatch({ type: `${entity}_FETCH_ITEM_FAILURE`, payload: err.message });
        throw (err);
      }
    }
  }

  const makeCreateItem = (api, entity) => {
    return async (item) => {
      try {
        dispatch({ type: `${entity}_ADD` });
        const data = await api.createItem(item, { requestSerializer: serializers[entity] });
        dispatch({ type: `${entity}_ADD_SUCCESS`, payload: data });
        return data;
      } catch (err) {
        console.log(err);
        dispatch({ type: `${entity}_ADD_FAILURE`, payload: err.message });
        throw (err);
      }
    }
  }

  const makeUpdateItem = (api, entity) => {
    return async (item) => {
      try {
        dispatch({ type: `${entity}_UPDATE` });
        const data = await api.updateItem(item, { requestSerializer: serializers[entity] });
        dispatch({ type: `${entity}_UPDATE_SUCCESS`, payload: data });
        return data;
      } catch (err) {
        console.log(err);
        dispatch({ type: `${entity}_UPDATE_FAILURE`, payload: err.message });
        throw (err);
      }
    }
  }
  const makeDeleteItem = (api, entity) => {
    return async (id) => {
      try {
        dispatch({ type: `${entity}_DELETE` });
        await api.deleteItem(id);
        for (const e of entities) {
          if (e !== entity) {
            setShouldUpdate(e)();
          }
        }
        dispatch({ type: `${entity}_DELETE_SUCCESS`, payload: id });
      } catch (err) {
        console.log(err);
        dispatch({ type: `${entity}_DELETE_FAILURE`, payload: err.message });
        throw (err);
      }
    }
  }

  const setShouldUpdate = (entity) => {
    return (
      () => dispatch({ type: `${entity}_SET_SHOULD_UPDATE` })
    )
  }

  useEffect(() => {
    dispatch({ type: 'INITIALIZE' });
  }, [user, providerContext?.id])

  const fetchOptions = { extended: 'true', destination: 'all' };
  if (user && !user.isAdmin) { fetchOptions.provider = user.provider }
  if (user?.isAdmin && providerContext.id) { fetchOptions.provider = providerContext.id }

  return (
    <DataContext.Provider value={{
      Activity: {
        ...state.Activity,
        fetchList: makeFetchList(GenericAPI('/activities'), 'ACTIVITY', fetchOptions),
        fetchItem: makeFetchItem(GenericAPI('/activities'), 'ACTIVITY', { extended: 'true' }),
        createItem: makeCreateItem(GenericAPI('/activities'), 'ACTIVITY'),
        updateItem: makeUpdateItem(GenericAPI('/activities'), 'ACTIVITY'),
        deleteItem: makeDeleteItem(GenericAPI('/activities'), 'ACTIVITY'),
        setShouldUpdate: setShouldUpdate('ACTIVITY'),
        uploadImage: ActivityAPI.uploadImage
      },
      Category: {
        ...state.Category,
        fetchList: makeFetchList(GenericAPI('/activities/categories'), 'CATEGORY', { extended: 'true' }),
        fetchItem: makeFetchItem(GenericAPI('/activities/categories'), 'CATEGORY', { extended: 'true' }),
        createItem: makeCreateItem(GenericAPI('/activities/categories'), 'CATEGORY'),
        updateItem: makeUpdateItem(GenericAPI('/activities/categories'), 'CATEGORY'),
        deleteItem: makeDeleteItem(GenericAPI('/activities/categories'), 'CATEGORY'),
        setShouldUpdate: setShouldUpdate('CATEGORY')
      },
      Provider: {
        ...state.Provider,
        fetchList: makeFetchList(GenericAPI('/activities/providers'), 'PROVIDER', fetchOptions),
        fetchItem: makeFetchItem(GenericAPI('/activities/providers'), 'PROVIDER', { extended: 'true' }),
        createItem: makeCreateItem(GenericAPI('/activities/providers'), 'PROVIDER'),
        updateItem: makeUpdateItem(GenericAPI('/activities/providers'), 'PROVIDER'),
        deleteItem: makeDeleteItem(GenericAPI('/activities/providers'), 'PROVIDER'),
        setShouldUpdate: setShouldUpdate('PROVIDER'),
      },
      Variant: {
        ...state.Variant,
        fetchList: makeFetchList(GenericAPI('/activities/variants'), 'VARIANT', fetchOptions),
        fetchItem: makeFetchItem(GenericAPI('/activities/variants'), 'VARIANT', { extended: 'true' }),
        createItem: makeCreateItem(GenericAPI('/activities/variants'), 'VARIANT'),
        updateItem: makeUpdateItem(GenericAPI('/activities/variants'), 'VARIANT'),
        deleteItem: makeDeleteItem(GenericAPI('/activities/variants'), 'VARIANT'),
        setShouldUpdate: setShouldUpdate('VARIANT'),
      },
      Slot: {
        ...state.Slot,
        fetchList: makeFetchList(GenericAPI('/activities/slots'), 'SLOT', fetchOptions),
        fetchItem: makeFetchItem(GenericAPI('/activities/slots'), 'SLOT'),
        createItem: makeCreateItem(GenericAPI('/activities/slots'), 'SLOT'),
        updateItem: makeUpdateItem(GenericAPI('/activities/slots'), 'SLOT'),
        deleteItem: makeDeleteItem(GenericAPI('/activities/slots'), 'SLOT'),
        setShouldUpdate: setShouldUpdate('SLOT'),
      },
      Event: {
        ...state.Event,
        fetchList: makeFetchList(GenericAPI('/activities/events'), 'EVENT', fetchOptions),
        fetchItem: makeFetchItem(GenericAPI('/activities/events'), 'EVENT'),
        createItem: makeCreateItem(GenericAPI('/activities/events'), 'EVENT'),
        updateItem: makeUpdateItem(GenericAPI('/activities/events'), 'EVENT'),
        deleteItem: makeDeleteItem(GenericAPI('/activities/events'), 'EVENT'),
        setShouldUpdate: setShouldUpdate('EVENT'),
      }
      // selectedProvider: state.selectedProvider,
      // setSelectedProvider: setSelectedProvider
    }}>
      {props.children}
    </DataContext.Provider>
  )
}
