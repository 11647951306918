import * as Yup from "yup";

export const validationConstants = {
    name: {
        min: 3,
        max: 50
    },
    description: {
        min: 3,
        max: 200
    },
    details: {
        max: 1000,
    },
    duration: {
        min: 10,
        max: 1000
    },
    participants: {
        max: 1000
    },
    priceCategories: {
        name: {
            min: 3,
            max: 50
        },
        description: {
            min: 3,
            max: 1000
        },
        allowedParticipants: {
            min: 1,
            max: 10
        }
    }
}

const variantValidationSchema = (language, t) => {
    const v = validationConstants;
    return Yup.object().shape({
        priceCategories: Yup.array().of(Yup.object().shape({
            price: Yup.number()
                .required(t('forms.variant.validation.price'))
                .min(1, t('forms.variant.validation.price')),
            allowedParticipants: Yup.number()
                .required(t('forms.variant.validation.allowedParticipants'))
                .min(1, t('forms.variant.validation.allowedParticipants')),
            translations: Yup.object().shape({
                [language]: Yup.object().shape({
                    name: Yup.string()
                        .required(t('forms.variant.validation.categoryName')),
                    description: Yup.string()
                        .required(t('forms.variant.validation.categoryDescription')),
                })
            })
        })),
        duration: Yup.number()
            .required(t('forms.variant.validation.duration'))
            .min(v.duration.min, t('forms.variant.validation.duration'))
            .max(v.duration.max, t('forms.variant.validation.duration')),
        maxParticipants: Yup.number()
            .required(t('forms.variant.validation.maxParticipants'))
            .min(1, t('forms.variant.validation.maxParticipants')),
        cutOffPeriod: Yup.number()
            .required()
            .min(0, t('forms.variant.validation.cutOffPeriod')),
        translations: Yup.object().shape({
            [language]: Yup.object().shape({
                name: Yup.string()
                    .required(t('forms.variant.validation.name')),
                description: Yup.string()
                    .required(t('forms.variant.validation.description')),
            })
        })
    })
};

export default variantValidationSchema;