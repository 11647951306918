import React, { useRef } from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Typography,
  Select
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import "moment/locale/de";
import { useField } from "formik";

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2)
  },
  mItem: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: "1em"
  },

  formControl: {
    width: "100%",
    paddingRight: 6,
    marginBottom: 10
  }
}));

const GOFormikSimpleSelect = props => {
  const [field, meta] = useField(props);
  const inputLabel = useRef(null);
  const classes = useStyles();
  const { dataSet, inputLabelId, labelText } = props;

  return (
    <FormControl className={classes.formControl}>
      <React.Fragment>
        <InputLabel id={inputLabelId} ref={inputLabel}>
          {labelText}
        </InputLabel>
        <Select labelId={inputLabelId} {...props}>
          {dataSet.map((o, index) => {
            return (
              <MenuItem className={classes.mItem} value={o.key} key={index}>
                {o.title}
              </MenuItem>
            );
          })}
        </Select>
      </React.Fragment>

      <FormHelperText
        id={`${field.name}-helper-text`}
        error={meta.error && meta.touched}
        component="p"
      >
        {meta.error && meta.touched ? meta.error : ""}
      </FormHelperText>
    </FormControl>
  );
};

export default GOFormikSimpleSelect;
