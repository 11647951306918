import axios from 'axios';
axios.defaults.baseURL = process.env.REACT_APP_API_HOST;

const deleteBooking = async (id) => {
  try {
    await axios.post(`activities/bookings/${id}/delete`);
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export {
  deleteBooking
}