import React, { useEffect, useReducer, useContext, createContext } from 'react';
import { GenericAPI } from '../API/generic';
import axios from 'axios';
import Config from '../Config/config';
import { UserContext } from './UserContext';
import { StylesProvider } from '@material-ui/core';

const providerAPI = GenericAPI('/activities/providers');

const { defaultConditions } = Config;

export const ProviderContext = createContext();

const providerReducer = (state, action) => {
  switch (action.type) {
    case 'SET_PROVIDER': {
      return {
        ...state,
        error: null,
        loading: false,
        id: action.payload.id,
        name: action.payload.name,
        bookable: action.payload.bookable,
        conditions: action.payload.conditions,
        description: action.payload.description,
        contact: { ...action.payload.contact },
        messaging: { ...action.payload.messaging }
      }
    }
    case 'SET_ERROR': {
      return {
        ...state,
        id: null,
        loading: false,
        name: null,
        bookable: false,
        conditions: defaultConditions,
        error: action.payload
      }
    }
    case 'SET_BOOKABLE': {
      return {
        ...state,
        bookable: action.payload
      }
    }
    case 'SET_SHOULD_UPDATE': {
      return {
        ...state,
        shouldUpdate: action.payload
      }
    }
    case 'SET_LOADING': {
      return {
        ...state,
        loading: action.payload.loading
      }
    }
    default: {
      return { ...state }
    }
  }
}

export const ProviderContextProvider = (props) => {
  const { user, isInitialized } = useContext(UserContext);
  const [state, dispatch] = useReducer(providerReducer, {
    error: null,
    id: null,
    name: null,
    bookable: false,
    conditions: defaultConditions,
    description: null,
    messaging: null,
    contact: null,
    loading: false,
    shouldUpdate: true
  });
  const { id, shouldUpdate, providers, description, messaging, contact, name, bookable, loading, conditions } = state;

  const makeBookable = async (flag) => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true })
      await axios.post(`/activities/providers/${id}/makebookable`, {
        bookable: flag
      });
      dispatch({ type: 'SET_BOOKABLE', payload: flag })
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      dispatch({ type: 'SET_LOADING', payload: true })
    }
  }

  useEffect(() => {
    if (user && isInitialized) {
      if (user.isAdmin === true) {
        const data = localStorage.getItem('providerMap');
        if (data) {
          const map = JSON.parse(data);
          if (map && map[user.id]) {
            loadProvider(map[user.id]);
          } else {
            dispatch({ type: 'SET_PROVIDER', payload: { id: null, name: '', bookable: false } })
          }
        }
      } else {
        if (user.provider) {
          loadProvider(user.provider);
        } else {
          dispatch({ type: 'SET_ERROR', payload: 'no_provider_assigned_to_user' })
        }
      }
    } else {
      dispatch({ type: 'SET_PROVIDER', payload: { id: null, name: '', bookable: false } })
    }

  }, [user, isInitialized])

  const setProvider = (pId) => {
    if (user?.isAdmin === true) {
      loadProvider(pId);
    } else {
      console.log('setProvider can only be called by Admin.')
    }
  }
  const loadProvider = async (pId) => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true })
      const provider = await providerAPI.fetchItem(pId, { extended: true });
      console.log('provider to be set: ', provider)
      if (user && provider) {
        const data = localStorage.getItem('providerMap');
        const tmpMap = data ? JSON.parse(data) : {};
        console.log('user: ', user)
        if (user?.id) {
          console.log('tmpMap: ', tmpMap)
          tmpMap[user.id] = provider.id;
          console.log('tmpMap: ', tmpMap)
          localStorage.setItem('providerMap', JSON.stringify(tmpMap))
        }
      }
      dispatch({
        type: 'SET_PROVIDER', payload: {
          id: provider.id,
          name: provider.name,
          conditions: provider.conditions || defaultConditions,
          bookable: provider.bookable === true ? true : false,
          description: provider.description,
          contact: { ...provider.contact },
          messaging: { ...provider.messaging }
        }
      });
      dispatch({ type: 'SET_LOADING', payload: false })
    } catch (err) {
      dispatch({ type: 'SET_ERROR', payload: 'could_not_load_provider' })
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false })
    }
  }

  return (<ProviderContext.Provider value={{
    id,
    name,
    description,
    bookable,
    conditions,
    messaging,
    contact,
    description,
    loading,
    makeBookable,
    setProvider
  }}>
    {props.children}
  </ProviderContext.Provider>)
}