import React from 'react';
import DashboardIcon from "@material-ui/icons/Dashboard";
import DateRangeIcon from '@material-ui/icons/DateRange';
import CategoryIcon from '@material-ui/icons/Category';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import EuroIcon from '@material-ui/icons/Euro';
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import BusinessIcon from '@material-ui/icons/Business';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';

export const menuData = [
  {
    title: "navbar.home",
    link: "/",
    exactMatch: true,
    icon: <DashboardIcon />,
    requiresLogin: true
  },
  {
    title: "navbar.profile",
    link: "/profile",
    exactMatch: false,
    icon: <AccountCircleIcon />,
    requiresLogin: true,
  },
  {
    title: "navbar.login",
    link: "/login",
    exactMatch: false,
    icon: <AccountCircleIcon />,
    publicOnly: true,
  },
  {
    title: "navbar.providerApplications",
    link: "/providerapplications",
    exactMatch: false,
    icon: <MailOutlineIcon />,
    requiresAdmin: true,
    requiresLogin: true,
  },
  {
    title: "navbar.callcenter",
    link: "/callcenter",
    exactMatch: false,
    icon: <PhoneIcon />,
    requiresAdmin: true,
    requiresLogin: true,
  },
  {
    title: "navbar.categories",
    link: "/categories",
    exactMatch: false,
    icon: <CategoryIcon />,
    requiresAdmin: true,
    requiresLogin: true,
  },
  {
    title: "navbar.providers",
    link: "/providers",
    exactMatch: false,
    icon: <BusinessIcon />,
    requiresLogin: true,
    requiresAdmin: true,
  },
  {
    title: "navbar.events",
    link: "/events",
    exactMatch: false,
    icon: <AnnouncementIcon />,
    requiresLogin: true,
    requiresAdmin: true,
  },
  {
    title: "navbar.activities",
    link: "/activities",
    exactMatch: false,
    icon: <DirectionsRunIcon />,
    requiresLogin: true,
  },
  {
    title: "navbar.variants",
    link: "/variants",
    exactMatch: false,
    icon: <EuroIcon />,
    requiresLogin: true,
  },
  // {
  //     title: "navbar.campaigns",
  //     link: "/campaigns",
  //     exactMatch: false,
  //     icon: <NewReleasesIcon />,
  //     requiresLogin: true,
  //     requiresAdmin: false,
  //     requiresBookable: true
  // },
  {
    title: "navbar.calendar",
    link: "/calendar",
    exactMatch: false,
    icon: <DateRangeIcon />,
    requiresLogin: true,
  },
  {
    title: "navbar.bookings",
    link: "/bookings",
    exactMatch: false,
    icon: <ShoppingCartIcon />,
    requiresLogin: true,
  },
  // {
  //     title: "navbar.admin",
  //     icon: <ShoppingCartIcon />,
  //     requiresLogin: true,
  //     requiresAdmin: false,
  //     link: '#',
  //     children: [
  //         {
  //             title: "navbar.test",
  //             icon: <ShoppingCartIcon />,
  //             requiresLogin: true,
  //             requiresAdmin: false,
  //             link: '/test'
  //         }
  //     ]
  // },
  // {
  //     title: "navbar2.admin",
  //     icon: <ShoppingCartIcon />,
  //     requiresLogin: true,
  //     requiresAdmin: false,
  //     link: '#',
  //     children: [
  //         {
  //             title: "navbar2.test",
  //             icon: <ShoppingCartIcon />,
  //             requiresLogin: true,
  //             requiresAdmin: false,
  //             link: '/test2'
  //         }
  //     ]
  // }
];

