import React from 'react';
import {
  Container,
  Icon
} from "@material-ui/core";
import MaterialTable, { MTableCell, MTableToolbar } from 'material-table';
import { useTranslation } from "react-i18next";
import moment from "moment";
import { GenericAPI } from '../../API/generic';
import { BookingSerializer } from '../../API/Serializers/Booking';
import { deleteBooking } from '../../API/booking';

const tableStyles = {
  fontSizeCell: 12,
  fontSizeHeader: 12
}

const EditableBookingTable = (props) => {
  const { i18n, t } = useTranslation();

  return (
    <MaterialTable minWidth="100%" width="100%" style={{ width: '100%', minWidth: '100%', maxWidth: '100%', fontSize: 8, overflowY: 'auto', overflowX: 'hidden', padding: 0 }}
      tableRef={props.tableRef}
      columns={[
        {
          title: 'Datum', field: 'timestamp',
          render: rowdata => (moment(rowdata.timestamp).locale(i18n.language).format('L HH:mm'))
        },
        { title: 'Kontakt', field: 'booker.name' },
        { title: 'E-Mail', field: 'booker.email' },
        { title: 'Telefon', field: 'booker.phone' },
        { title: 'Teilnehmer', field: 'totalParticipants', type: 'numeric' },
      ]}
      components={{
        Cell: props => (
          <MTableCell {...props} style={{ ...props.style, fontSize: tableStyles.fontSizeCell }} />
        ),
        Toolbar: props => (<div style={{ display: 'none' }}><MTableToolbar {...props} /></div>),
        Container: props => (<Container {...props} style={{ padding: 2 }} />),
        // Action: props => (props.data.id === 'new' ? <MTableAction {...props} /> : <React.Fragment />)
      }}
      options={{
        search: false,
        tableLayout: 'fixed',
        headerStyle: { fontSize: tableStyles.fontSizeHeader },
        showTitle: false,
        paging: false,
        addRowPosition: 'first'
      }}
      icons={{
        Add: () => <Icon style={{ display: 'none' }} data-addtotable="add-icon-handler" color="secondary" fontSize="large">
          add_circle
                </Icon>
      }}
      editable={{
        isEditable: () => false,
        isDeletable: () => true,
        onRowAdd: (newData) => {
          return new Promise((resolve, reject) => {
            const BookingRequest = {
              bookingType: 'reservation_only',
              booker: {
                name: 'Test',
                email: 'test@test.de',
                phone: '123456'
              },
              slot: props.id,
              reservation: {
                numParticipants: 1
              }
            };
            GenericAPI('/activities/bookings').createItem(
              BookingRequest, {
              requestSerializer: BookingSerializer
            }).then(result => {
              resolve();
            }).catch(err => {
              reject(err);
            })
          })
        },
        onRowDelete: (record) => {
          return new Promise((resolve, reject) => {
            const doDelete = async () => {
              await deleteBooking(record.id);
            }
            doDelete()
              .then(() => {
                return resolve()
              })
              .catch((err) => {
                return reject(err);
              })

          })

        },
        onRowUpdate: (oldData) => { return new Promise((resolve, reject) => { resolve() }) }
      }
      }
      data={() => {
        return new Promise((resolve, reject) => {
          GenericAPI(`/activities/bookings?filter[slot]=${props.id}&sort=-timestamp`).fetchList()
            .then((bookings) => {
              console.debug(JSON.stringify(bookings));
              return resolve({
                data: bookings,
                page: 0,
                totalCount: bookings.length
              })
            }).catch((err) => {
              resolve({ data: [], page: 0, totalCount: 0 });
            })
        })
      }}
      localization={{
        body: {
          editRow: {
            deleteText: t('forms.slot.actions.deleteBooking'),
            emptyDataSourceMessage: t('forms.slot.messages.emptyList')
          }
        }
      }}
    />
  )
}

export default EditableBookingTable;