import React, { useEffect, useState } from 'react';
import { LinearProgress, Popover, Button, Container, Typography, Table, TableRow, TableCell, TableBody, TableHead, IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import ListHeader from './Partials/ListHeader';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { formStyles } from "../Styles/forms";
import { GenericAPI } from '../API/generic';
import ProviderApplicationDetails from '../Forms/ProviderApplicationDetails';
import { ProviderApplicationStates } from '../Constants/providerApplication';
import { ProviderApplicationSerializer } from '../API/Serializers/Provider';
import axios from 'axios';
axios.defaults.baseURL = process.env.REACT_APP_API_HOST;


const useStyles = makeStyles(theme => ({
  ...formStyles(theme),
  iconButtonHover: {
    "&:hover, &.Mui-focusVisible": { color: theme.palette.secondary.main }
  },
  deleteButtonHover: {
    "&:hover, &.Mui-focusVisible": { color: theme.palette.error.main }
  },
  sortIconActive: {
    color: theme.palette.secondary.main
  },
  sortIcon: {
    color: 'lightgray'
  },
  actionCell: {
    width: 250,
    minWidth: 250,
    maxWidth: 250
  }
}));

const ProviderApplicationList = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [providerApplications, setProviderApplications] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activeItem, setActiveItem] = React.useState(null);
  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const handlePopoverClick = (event, selectedApplication) => {
    setActiveItem(selectedApplication);
    setAnchorEl(event.currentTarget);
  };


  useEffect(() => {
    const fetchApplications = async () => {
      try {
        setLoading(true);
        const data = await GenericAPI('/auth/providerapplications?sort=-timestamp').fetchList();
        setProviderApplications(data);
        setLoading(false)
      } catch (err) {
        setLoading(false);
        alert(err);
      }
    }
    if (shouldUpdate) {
      fetchApplications();
      setShouldUpdate(false);
    }
  }, [shouldUpdate]);

  useEffect(() => {
    setShouldUpdate(true);
  }, [])

  const handleConfirm = async (application, provider) => {
    try {
      const serializedData = ProviderApplicationSerializer.serialize({ provider });
      await axios.post(`/auth/providerapplications/${application.id}/approve`, serializedData);
    } catch (err) {
      alert(err.message)
    }
    setShouldUpdate(true);
  }

  const applicationTable = providerApplications.map((application) => {
    return (
      <TableRow key={application.id} >
        <TableCell>
          <IconButton
            size="small"
            className={classes.iconButtonHover}
            onClick={(event) => {
              handlePopoverClick(event, application)
            }}
          >
            <InfoIcon />
          </IconButton>
        </TableCell>
        <TableCell className={classes.actionCell}>
          {
            application.status === ProviderApplicationStates.WAITING_FOR_APPROVAL ?
              <React.Fragment>
                <Button
                  aria-describedby={popoverId}
                  onClick={(event) => {
                    handlePopoverClick(event, application)
                  }}
                  classes={{ root: classes.button, disabled: classes.disabledButton }}
                >
                  {`${t('forms.providerApplications.actions.confirm')}/${t('forms.providerApplications.actions.reject')}`}
                </Button>
              </React.Fragment>
              :
              <Typography >
                {/* {booking.status} */}
                {t(application.status)}
              </Typography>
          }

        </TableCell>
        <TableCell>{application.name}</TableCell>
        <TableCell>{application.web}</TableCell>
        <TableCell>{application.contact.email}</TableCell>
      </TableRow >
    )
  });
  return (
    <Container>
      <ListHeader
        title={t('forms.providerApplications.title', { count: 2 })}
        newButtonLabel={t('forms.booking.new')}
        hideNew={true}
        onReload={() => { setShouldUpdate(true) }}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('tables.generic.actions')}</TableCell>
            <TableCell className={classes.actionCell}>{t('tables.generic.state')}</TableCell>
            <TableCell>{t('forms.generic.name')}</TableCell>
            <TableCell>{t('generic.web')}</TableCell>
            <TableCell>{t('generic.email')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            loading ?
              <LinearProgress />
              : applicationTable
          }
        </TableBody>
      </Table>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {activeItem ?
          <ProviderApplicationDetails
            data={activeItem}
            onConfirm={(provider) => {
              handleConfirm(activeItem, provider)
              handlePopoverClose();
            }}
            onReject={() => { handlePopoverClose() }}
            onClose={() => { handlePopoverClose() }}
          /> : <React.Fragment />
        }

      </Popover>
    </Container>
  )

}

export default ProviderApplicationList