import React, { useContext } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';
import { Grid } from '@material-ui/core';
import { AppContext } from '../Context/AppContext';
import { useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const ErrorScreen = (props) => {
  const { error } = useContext(AppContext);
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Container style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
      <Grid container style={{ marginTop: 10, marginBottom: 10 }}>
        <Typography variant='h6'>Registrierung fehlgeschlagen</Typography>
        <ErrorIcon style={{ fontSize: 40, color: theme.palette.secondary.main }} />
        <Typography variant="h5">
          {error.message || t('errors.genericMessage')}
        </Typography>
      </Grid>
    </Container>
  )
}

export default ErrorScreen;