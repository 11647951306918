import React from "react";

// import { makeStyles } from "@material-ui/core/styles";

// const useStyles = makeStyles(theme => ({
//   btn: {
//     borderRadius: 0,
//     marginLeft: 4,
//     "&:hover": {
//       color: "transparent",
//       backgroundColor: "transparent"
//     },
//     width: 4,
//     height: 3
//   },

//   es: {
//     background: `url("/flags/es.svg") no-repeat center center`
//   },
//   de: {
//     background: `url("/flags/de.svg") no-repeat center center`
//   },
//   nl: {
//     background: `url("/flags/nl.svg") no-repeat center center`
//   },
//   en: {
//     background: `url("/flags/en.svg") no-repeat center center`
//   },
//   it: {
//     background: `url("/flags/it.svg") no-repeat center center`
//   },
//   fr: {
//     background: `url("/flags/fr.svg") no-repeat center center`
//   }
// }));


function FlagIcon(props) {
  return (
    <img
      alt=""
      src={`/flags/${props.flag}.svg`}
      style={{
        width: 12,
        position: "absolute",
        top: 6,
        left: 4,
        borderRadius: 2
      }}
    />
  );
}

export default FlagIcon;
