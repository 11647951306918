import React from "react";
import FormHelperText from "@material-ui/core/FormHelperText";
import {
  KeyboardDatePicker
} from "@material-ui/pickers";
import "moment/locale/de";
import moment from "moment";
import { useFormikContext, useField } from 'formik';

const GOFormikDatePicker = props => {
  const {
    labelText,
    locale,
    format,
    customStyle,
  } = props;
  moment.locale(locale);
  const [field, meta] = useField(props);

  const { setFieldValue, setFieldTouched } = useFormikContext();

  return (
    <div>
      <KeyboardDatePicker
        autoOk
        variant="inline"
        // inputVariant="outlined"
        label={labelText}
        format={format}
        InputAdornmentProps={{ position: "start" }}
        name={props.name}
        value={props.value}
        onChange={(date) => { setFieldValue(props.name, date, true); setFieldTouched(props.name, true) }}
        style={{ ...customStyle }}
      />
      <FormHelperText
        id={`${field.name}-helper-text`}
        error={meta.error && meta.touched}
        component="p"
      >
        {meta.error && meta.touched
          ? meta.error
          : ""}
      </FormHelperText>
    </div>
  );
};

export default GOFormikDatePicker;
