import React, { useContext } from "react";
import GOFormikSelect from "../../Modules/Forms/GOFormikSelect";
import GOFormikSimpleSelect from "../../Modules/Forms/GOFormikSimpleSelect";
import GOFormikTextInput from "../../Modules/Forms/GOFormikTextInput";
import GOFormikTags from "../../Modules/Forms/GOFormikTags";
import ImageUpload from "./ImageUpload";
import { Field, FieldArray } from "formik";
import { useTranslation } from "react-i18next";
import LocationFormUI from "../Partials/LocationFormUI";
import { Constants } from '@actyvyst/common';

import {
  Typography,
  Grid,
  Divider
} from "@material-ui/core";
import { Prompt } from "react-router-dom";
import InfoList from './InfoList';
import { validationConstants } from './validation';

import "moment/locale/de";
import { DataContext } from "../../Context/DataContext";
// import "react-phone-input-2/dist/style.css";
import countries from "i18n-iso-countries";
import { UserContext } from "../../Context/UserContext";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/de.json"));

const ActivityFormUI = props => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { Provider, Category } = useContext(DataContext);

  return (
    <React.Fragment>
      <Prompt
        when={props.dirty}
        message={location => t('forms.generic.messages.unsavedData')}
      />
      <Grid container style={{ margin: 0, padding: 0 }}>

        <Grid item xs={6}>
          <Field
            name="provider.id"
            as={GOFormikSelect}
            disabled={user && user.isAdmin ? false : true}
            dataSet={Provider}
            sort={true}
            styles={{ paddingRight: 5 }}
            optionLabel={option => option.name}
            labelText={t("generic.provider")}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name={'destination'}
            as={GOFormikSimpleSelect}
            sort={true}
            dataSet={Object.entries(Constants.Destinations).map(([key, value]) => {
              return {
                key: key,
                title: value.name
              }
            })}
            labelText={t("Destination")}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name={`translations.${props.contentLanguage}.name`}
            as={GOFormikTextInput}
            maxChars={validationConstants.name.max}
            flag={props.contentLanguage}
            labelText={t("generic.name")}
          />
        </Grid>

        <Grid item xs={12}>
          <Field
            name={`translations.${props.contentLanguage}.teaser`}
            as={GOFormikTextInput}
            maxChars={validationConstants.teaser.max}
            labelText={t("forms.activity.teaser")}
            flag={props.contentLanguage}
          />
        </Grid>

        <Grid item xs={12}>
          <Field
            name={`translations.${props.contentLanguage}.description`}
            as={GOFormikTextInput}
            maxChars={validationConstants.description.max}
            flag={props.contentLanguage}
            multiline
            rows="4"
            rowsMax="20"
            labelText={t("generic.description")}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name={`translations.${props.contentLanguage}.hygiene`}
            as={GOFormikTextInput}
            maxChars={validationConstants.hygiene.max}
            flag={props.contentLanguage}
            multiline
            rows="4"
            rowsMax="20"
            labelText={t("forms.activity.hygiene")}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <FieldArray
            name={`translations.${props.contentLanguage}.infos`}
            // component={InfoList}
            // contentLanguage={props.contentLanguage}
            render={arrayHelpers => (
              <InfoList
                {...arrayHelpers}
                contentLanguage={props.contentLanguage}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">{t("forms.activity.tags")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <FieldArray
            name={"tags"}
            render={arrayHelpers => (
              <GOFormikTags
                {...arrayHelpers}
                name={'tags'}
                contentLanguage={props.contentLanguage}
                dataSet={Category}
                inputLabelId={`tags-select-label`}
                labelText={t("generic.category")}
                maxEntries={validationConstants.tags.maxLines}
                validateOnChange={true}
                afterChange={(e) => { props.setFieldTouched('tags', true); props.handleChange(e); props.validateForm(); }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <LocationFormUI />
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">{t("forms.activity.images")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <FieldArray name="images" component={ImageUpload} />
        </Grid>
      </Grid>

    </React.Fragment>
  );
};

export default ActivityFormUI;
