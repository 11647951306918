import React from 'react';
import { Container } from '@material-ui/core';
import TestForm from '../Test/TestForm';

const TestScreen = (props) => {

  return (
    <Container>
      <TestForm />
    </Container >
  )
}

export default TestScreen;
