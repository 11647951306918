import React, { useContext, useState } from 'react'
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { LinearProgress, Box, Grid, Link, Divider, FormControl, Button, Table, TableRow, TableCell, TableBody, Tabs, Tab } from '@material-ui/core';
import ListHeader from '../Partials/ListHeader';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { ProviderContext } from '../../Context/ProviderContext';
import { UserContext } from '../../Context/UserContext';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PartnerState from '../../Modules/PartnerState';

const useStyles = makeStyles((theme) => ({
  firstCol: { width: '10%' },
  nestedFirstCol: { width: '20%' },
  checked: {}
}))

const ProviderTab = () => {
  const provider = useContext(ProviderContext);
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();


  return (
    provider?.loading === true ?
      <LinearProgress color="secondary" />
      : provider?.id === null ?
        <Typography>Kein Anbieter ausgewählt</Typography>
        :
        <Grid container>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={9}>
            <Typography variant="h4" color="primary">
              {provider.name}
            </Typography>
          </Grid>

          {/* <Grid item xs={9} style={{ paddingTop: 12 }}>
            <Typography variant="h4">{t("generic.provider")}</Typography>
          </Grid> */}
          <Grid item container xs={3} alignItems="center" justify="flex-end">
            <FormControl >
              <Button
                disabled={provider.id ? false : true}
                variant="contained"
                color="secondary"
                onClick={() => history.push(`/providers/${provider.id}`)}
              >
                {t('generic.edit')}
              </Button>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Table>
              <TableBody>
                {/* <TableRow>
                  <TableCell className={classes.firstCol}>
                    {t('generic.name')}
                  </TableCell>
                  <TableCell>
                    {provider.name}
                  </TableCell>
                </TableRow> */}
                <TableRow>
                  <TableCell className={classes.firstCol}>
                    {t('generic.description')}
                  </TableCell>
                  <TableCell>
                    {provider.description}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.firstCol}>
                    {t('forms.provider.contact')}
                  </TableCell>
                  <TableCell>
                    <Table size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell className={classes.nestedFirstCol}>{t('forms.provider.address')}</TableCell>
                          <TableCell>{`${provider.contact?.street}, ${provider.contact?.zip} ${provider.contact?.city}`}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.nestedFirstCol}>{t('forms.contact.phone')}</TableCell>

                          <TableCell>{provider.contact?.phone}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.nestedFirstCol}>{t('forms.contact.email')}</TableCell>

                          <TableCell>{provider.contact?.email}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.nestedFirstCol}>{t('forms.contact.web')}</TableCell>

                          <TableCell>{provider.contact?.web}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableCell>
                </TableRow>
                <TableRow>

                  <TableCell className={classes.firstCol}>
                    {t('forms.provider.messaging')}
                  </TableCell>
                  <TableCell>
                    <Table size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell className={classes.nestedFirstCol}>{t('generic.email')}</TableCell>
                          <TableCell>{provider?.messaging?.email}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.nestedFirstCol}>{t('generic.sms')}</TableCell>

                          <TableCell>{provider?.messaging?.sms}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.nestedFirstCol}>{t('forms.provider.devices')}</TableCell>

                          <TableCell>{
                            provider?.messaging.devices ?
                              provider.messaging.devices.map((device) => {
                                return (
                                  <Typography>{device.deviceName}, {device.osName} {device.osVersion}</Typography>
                                )
                              }) : ''
                          }</TableCell>
                        </TableRow>

                      </TableBody>
                    </Table>
                  </TableCell>

                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
  )
}

export default ProviderTab
