import React, { useState, useContext } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import { Typography, Divider, ListItem } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import Menu from './Menu';
import { useTranslation } from "react-i18next";
import { UserContext } from '../../Context/UserContext';
import { ProviderContext } from '../../Context/ProviderContext';
import { menuData } from './menuData';
import ProviderBar from '../ProviderBar';


const drawerWidth = 240;
const appBarHeight = 50;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  label: {
    textTransform: 'none'
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    display: 'flex',
    height: appBarHeight,
    marginLeft: drawerWidth,
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: 0,
  },
  navItem: {
    fontSize: '1em'
  },
  languageSwitch: {
    groupedTextPrimary: { color: "white" },
    position: "absolute",
    top: 0,
    right: 0,
    marginRight: 10,
    marginTop: 8
  }
}));

const NavBar = (props) => {
  const { user } = useContext(UserContext);
  const provider = useContext(ProviderContext);
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const classes = useStyles(theme);
  const { i18n } = useTranslation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          <Hidden mdUp implementation="css">
            <Typography variant='h1' classes={{ root: classes.navItem }}>
              <span style={{ color: 'white' }}>{'actyvyst'}</span> <span style={{ color: theme.palette.secondary.main }}>{'Anbieter Cockpit'}</span>
            </Typography>
          </Hidden>
          <ProviderBar />
          {/* <ButtonGroup
            size="small"
            variant="outlined"
            color="secondary"
            className={classes.languageSwitch}
            disableFocusRipple={true}
            disableRipple={true}
          >
            <Button
              classes={{ label: classes.label }}
              onClick={() => i18n.changeLanguage("de")}
              style={
                i18n.language === "de"
                  ? { color: 'white' }
                  : {}
              }
            >
              de
                        </Button>
            <Button
              classes={{ label: classes.label }}

              onClick={() => i18n.changeLanguage("en")}
              style={
                i18n.language === "en"
                  ? { color: 'white' }
                  : {}
              }
            >
              en
                        </Button>
          </ButtonGroup> */}
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}>
            <Menu menuData={menuData} />

          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <Menu menuData={menuData} />
            <Divider />
            <ListItem>
              <div>
                <Typography>{user ? `${user.firstName} ${user.lastName}` : 'Nicht angemeldet'}</Typography>
                <Typography>{provider.id ? `${provider.name}` : 'Kein Provider'}</Typography>
                {
                  process.env.NODE_ENV && process.env.NODE_ENV !== 'development' ?
                    undefined :
                    <React.Fragment>
                      <Typography>{provider.id ? `${provider.bookable === true ? 'buchbar' : 'nicht buchbar'}` : ''}</Typography>
                      <Typography>API-Host:{process.env.REACT_APP_API_HOST}</Typography>
                    </React.Fragment>

                }

              </div>
            </ListItem>
            <Divider />
          </Drawer>
        </Hidden>
      </nav>
    </div>
  )
}

export default NavBar;
