import React from 'react';
import { Container, Grid, Typography, Card, CardHeader, CardContent, CardActions, Button } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import { formStyles } from '../../Styles/forms';
import { useHistory } from 'react-router-dom';



const useStyles = makeStyles((theme) => ({
  ...formStyles(theme),
  textField: {
    width: '100%',
    minWidth: '100%'
  },
  card: {
    marginTop: 10,
    marginBottom: 10
  },
  cardTitle: theme.typography.h6
}));

const RegisterSuccess = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Container>
      <Grid item xs={12} >
        <Grid item lg={7} md={9} xs={12}>
          <Card className={classes.card}>
            <CardHeader
              title={t('forms.register.success.title')}
              classes={{ title: classes.cardTitle }}
            >
              <Typography variant='h6'>{t('forms.register.success.title')}</Typography>
            </CardHeader>
            <CardContent>
              <Typography variant="body2" component="p" style={{ textAlign: 'center' }}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('forms.register.success.description', { 'interpolation': { 'escapeValue': false } })
                  }}
                />
              </Typography>
            </CardContent>
            <CardActions >
              <Grid container justify="flex-end">

                <Button
                  className={classes.button}
                  variant="contained"
                  onClick={() => { history.push(`/login${'?email=' + props.email || ''}`) }}
                >
                  {t('forms.register.userIsProvider.actions.login')}
                </Button>
              </Grid>

            </CardActions>
          </Card>
        </Grid>
      </Grid>

    </Container>
  )
}

export default RegisterSuccess;