import React from 'react';
import { Container, Grid, LinearProgress } from '@material-ui/core';
import { Form, Field } from 'formik';
import { ValidationConstants } from "./validation";
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import GOFormikTextInput from "../../Modules/Forms/GOFormikTextInput";

const ReservationFormUI = ({ title, isLoading, values, ...formikProps }) => {
  const { t } = useTranslation();
  const content = isLoading ?
    <LinearProgress /> :
    <Form>
      {/* <Grid container>
                <Typography>{JSON.stringify(values)}</Typography>
            </Grid> */}
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item xs={6}>
          <Field
            name="booker.name"
            as={GOFormikTextInput}
            maxChars={ValidationConstants.booker.name.max}
            labelText={t("generic.name")}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name="booker.email"
            as={GOFormikTextInput}
            maxChars={ValidationConstants.booker.email.max}
            labelText={t("generic.email")}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name="booker.phone"
            as={GOFormikTextInput}
            maxChars={ValidationConstants.booker.phone.max}
            labelText={t("forms.contact.phone")}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name="reservation.numParticipants"
            as={GOFormikTextInput}
            inputFormat="integer"
            labelText={t("forms.booking.numParticipants")}
          />
        </Grid>
        {/* <Grid item xs={12}>
          <Typography>{JSON.stringify(formikProps)}</Typography>
        </Grid> */}
      </Grid>
    </Form>
  return (
    <Container>
      {content}
    </Container>
  )
}

ReservationFormUI.propTypes = {
  title: PropTypes.string,
  isLoading: PropTypes.bool
}
ReservationFormUI.defaultProps = {
  title: '',
  isLoading: false
}


export default ReservationFormUI;