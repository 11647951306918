import React from 'react';
import { Container } from '@material-ui/core';
import GTCContent from './provider-gtc';

const GTC = (props) => {
  return (
    <Container>
      <GTCContent />
    </Container>
  )
}

export default GTC;