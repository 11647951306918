import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, Link, Divider, ListItem, ListItemText, ListItemIcon, Avatar, Card, CardHeader, CardMedia, CardContent, CardActions, Typography, Grid, Button } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { colors } from '../../theme';

const gold = '#dca61f';
const disabledBg = '#eeeeee';

const useStyles = props => makeStyles((theme) => ({
  avatar: {
    backgroundColor: props.color
  },
  listItemPrimary: {
    fontWeight: 'bold'
  },
  listItemSecondary: {
    fontWeight: 'normal',
    fontSize: '0.8em',
    marginLeft: 6
  },
  cardHeaderTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: props.color
  },
  cardHeaderSubHeader: {
    fontSize: '0.8em',
    marginLeft: 0
  },
  cardAction: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
    minHeight: 60,
    maxHeight: 60
    // marginTop: 'auto'
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    borderStyle: props.enabled ? 'none' : 'dashed',
    borderWidth: 1,
    borderColor: 'gray',
    // justifyContent: 'space-between',
    maxWidth: 270,
    // marginLeft: 12,
    marginRight: 12,
  },
  cardHeader: {
    // opacity: props.enabled ? 1 : 0.8,
    backgroundColor: props.enabled ? 'transparent' : disabledBg,

    padding: 12
  },
  cardContent: {
    // opacity: props.enabled ? 1 : 0.5,
    backgroundColor: props.enabled ? 'transparent' : disabledBg,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0
  },
  checkIcon: {
    fontSize: 48,
    margin: 0,
    color: props.color
  }
}));

const StateCard = ({
  color,
  avatarLetter,
  title,
  subheader,
  callToAction,
  children,
  enabled = true
}) => {
  const classes = useStyles({ enabled, color: enabled ? color : 'gray' })();
  return (
    <Card classes={{ root: classes.card }}>
      <CardHeader
        classes={{ root: classes.cardHeader, title: classes.cardHeaderTitle, subheader: classes.cardHeaderSubHeader }}
        avatar={<Avatar className={classes.avatar}>{avatarLetter}</Avatar>}
        title={title}
        subheader={subheader}
      />
      <Divider />

      <CardContent
        className={classes.cardContent}
      >
        {children}
      </CardContent>
      <div style={{ backgroundColor: enabled ? 'transparent' : disabledBg, display: 'flex', marginTop: 'auto', padding: 4, width: '100%', minWidth: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="caption">{`Sie sind ${enabled ? '' : 'kein'} actyvyst-${title}`}</Typography>
      </div>
      <Divider />

      <CardActions className={classes.cardAction}>
        {
          enabled === true ?
            <React.Fragment>
              <CheckCircleIcon className={classes.checkIcon} />
            </React.Fragment>
            : <Link href="/apply" style={{ fontWeight: 'bold', color: colors.actyRed }}>{callToAction}</Link>
        }
      </CardActions>



    </Card>
  )
}

const PartnerState = (props) => {
  const classes = useStyles(props)();
  return (
    <Grid container>
      {
        props.bookable !== true ?
          <StateCard
            color={colors.actyLightBlue}
            avatarLetter="C"
            title="Contentpartner"
            subheader="Angebot sichtbar machen"
          >
            <List dense={true}>
              <ListItem>
                <ListItemText
                  classes={{ primary: classes.listItemPrimary, secondary: classes.listItemSecondary }}
                  primary="Aktivitäten präsentieren"
                  secondary="Präsentieren Sie bis zu drei Aktivitäten mit Text & Bild"
                />
              </ListItem>
            </List>
          </StateCard>
          : undefined
      }

      <StateCard
        color={gold}
        avatarLetter="B"
        title="Buchungspartner"
        subheader="Umsatz steigern"
        enabled={props.bookable === true}
        callToAction="Jetzt Buchungspartner werden"
      >
        <List dense={true}>
          <ListItem>
            <ListItemText
              classes={{ primary: classes.listItemPrimary, secondary: classes.listItemSecondary }}
              primary="Aktivitäten präsentieren"
              secondary="Präsentieren Sie bis zu fünf Aktivitäten mit Text & Bild"
            />

          </ListItem>
          <ListItem>
            <ListItemText
              classes={{ primary: classes.listItemPrimary, secondary: classes.listItemSecondary }}
              primary="Buchungen erhalten"
              secondary="Kunden können Ihre Angebote direkt in der App buchen"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              classes={{ primary: classes.listItemPrimary, secondary: classes.listItemSecondary }}
              primary="Termine verwalten"
              secondary="Nutzen Sie unsere komfortable Termin&shy;verwaltung für alle Ihre Buchungen und Anfragen"
            />
          </ListItem>
        </List>
      </StateCard>

    </Grid >
  )
}

export default PartnerState
