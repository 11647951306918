import React, { useContext, useEffect } from 'react';
import { LinearProgress, Container, Typography, Table, TableRow, TableCell, TableBody, TableHead } from '@material-ui/core';
import { DataContext } from '../Context/DataContext';
import moment from 'moment';


const SlotList = (props) => {
    const { Slot } = useContext(DataContext);

    useEffect(() => {
        if (Slot.data.length === 0 || Slot.shouldUpdate) {
            Slot.fetchList();
        }
    }, [Slot]);

    const slotTable = Slot.data.map(slot =>
        <TableRow key={slot.id}>
            <TableCell>{slot.activity.name}</TableCell>
            <TableCell>{slot.variant.name}</TableCell>
            <TableCell>{moment(slot.startDate).format('DD.MM.YYYY, hh:mm')}</TableCell>
            <TableCell>{moment(slot.endDate).format('DD.MM.YYYY, hh:mm')}</TableCell>
        </TableRow>)
    return (
        <Container>
            <Typography variant="h6">Slots</Typography>
            <Typography>{Slot.error}</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Aktivität
                        </TableCell>
                        <TableCell>
                            Variante
                        </TableCell>
                        <TableCell>
                            Von
                        </TableCell>
                        <TableCell>
                            Bis
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Slot.loading ?
                        <TableRow>
                            <TableCell colSpan={2}>
                                <LinearProgress color="secondary" />
                            </TableCell>
                        </TableRow>
                        : slotTable
                    }
                </TableBody>
            </Table>
        </Container>

    )
}

export default SlotList;