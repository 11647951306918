import React, { useContext, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import PriceCategoryListPartial from './PriceCategoryListPartial';
import GOFormikSelect from '../../Modules/Forms/GOFormikSelect';
import GOFormikSimpleSelect from '../../Modules/Forms/GOFormikSimpleSelect';
import GOFormikTextInput from '../../Modules/Forms/GOFormikTextInput';
import GOFormikCheckBox from '../../Modules/Forms/GOFormikCheckBox';
import { validationConstants } from './validation';
import {
    Typography,
    Grid,
    Button,
    Icon,
    IconButton,
    Divider
} from "@material-ui/core";
import { Form, Field, FieldArray } from 'formik';
import { useTranslation } from "react-i18next";

const InternalVariantPartial = (props) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Grid item xs={6}>
                <Field
                    name={'type'}
                    as={GOFormikSimpleSelect}
                    dataSet={[
                        { key: 'fixedSlot', title: t('forms.variant.fixedSlot') },
                        // { key: 'variableSlot', title: t('forms.variant.variableSlot') },
                        // { key: 'resource', title: t('forms.variant.resource') },
                    ]}
                    labelText={t('forms.variant.type')}
                />
            </Grid>
            {/* <Grid item xs={3}>
                <Field
                    name={'limitedVacancy'}
                    as={GOFormikCheckBox}
                    labelText={t('forms.variant.limitedVacancy')}
                />
            </Grid> */}
            <Grid item xs={6}>
                <Field
                    name={'bookOnRequest'}
                    as={GOFormikCheckBox}
                    labelText={t('forms.variant.bookingOnRequest')}
                />
            </Grid>

            <Grid item xs={3}>
                <Field
                    name="duration"
                    as={GOFormikTextInput}
                    labelText={t('forms.variant.duration')}
                />
            </Grid>
            <Grid item xs={3}>
                <Field
                    name="maxParticipants"
                    as={GOFormikTextInput}
                    labelText={t('forms.variant.maxParticipants')}
                />
            </Grid>
            <Grid item xs={6}>
                <Field
                    name="cutoffPeriod"
                    as={GOFormikTextInput}
                    labelText={t('forms.variant.cutoffPeriod')}
                />
            </Grid>
            {/* <Grid item xs={12}>
                <FieldArray
                    name="priceCategories"
                    render={(arrayHelpers) => <PriceCategoryListPartial {...arrayHelpers} contentLanguage={props.contentLanguage} />}
                />
            </Grid> */}

        </React.Fragment>
    )
}

export default InternalVariantPartial;