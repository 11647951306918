import React, { useEffect, useState, useContext } from 'react';
import { Container, Grid, FormControl, FormHelperText, LinearProgress, Typography, TextField, Button, Card, CardContent, CardActions } from '@material-ui/core';
import { useFormik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GenericAPI } from '../../API/generic';
import { UserContext } from '../../Context/UserContext';
import UserIsProvider from './UserIsProvider';
import AuthAPI from '../../API/auth';
import RegisterError from './Error';
import RegisterSuccess from './Success';
import { ActyvystAPIError } from '../../API/APIError';
import * as Yup from 'yup';

import { formStyles } from '../../Styles/forms';

const useStyles = makeStyles((theme) => ({
  ...formStyles(theme),
  textField: {
    width: '100%',
    minWidth: '100%'
  },
  card: {
    marginTop: 10,
    marginBottom: 10
  },

}));

const FormStates = {
  EDIT: 0,
  SUCCESS: 1,
  ERROR: 2
}

const RegisterScreen = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { user, logout } = useContext(UserContext);

  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [providerApplication, setProviderApplication] = useState(null);
  const [error, setError] = useState(null);
  const [formState, setFormState] = useState(FormStates.EDIT);

  const formik = useFormik({
    initialValues: {
      password: '',
      repeatPassword: ''
    },
    onSubmit: (values, { setSubmitting }) => {
      // setError(new Error('Testfehler'))
      const register = async () => {
        try {
          await AuthAPI.register({
            firstName: providerApplication.contact.firstName,
            lastName: providerApplication.contact.lastName,
            email: providerApplication.contact.email,
            password: values.password,
            isProvider: true
          });
          setSubmitting(false);
          setFormState(FormStates.SUCCESS);
        } catch (err) {
          setSubmitting(false);
          setError(err);
          setFormState(FormStates.ERROR);
        }
      }
      register();
    },
    validationSchema: Yup.object({
      password: Yup.string().required(t('forms.register.validation.required')).min(5, t('forms.register.validation.min', { min: 5 })),
      repeatPassword: Yup.string().required(t('forms.register.validation.required')).test('equalsPassword', t('forms.register.validation.confirmPassword'), function (val) {
        return val === this.parent.password
      })
    }),
  });
  useEffect(() => {
    console.log('logout')
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    const loadProviderApplication = async () => {
      try {
        setLoading(true);
        const data = await GenericAPI(`/auth/providerapplications`).fetchItem(id);
        setProviderApplication(data);
        setLoading(false);
      } catch (err) {
        const error = ActyvystAPIError.createFromAxiosError(err);
        setLoading(false);
        setError(error);
        setFormState(FormStates.ERROR);
      }
    }
    if (id) {
      loadProviderApplication();
    }
  }, [id]);

  if (formState === FormStates.ERROR && error) {
    return <RegisterError error={error} />
  }
  if (formState === FormStates.SUCCESS) {
    return <RegisterSuccess email={providerApplication.contact.email} />
  }

  if (loading || !providerApplication) {
    return <LinearProgress />
  }

  if (providerApplication && providerApplication.userExists === true && providerApplication.userIsProvider === false) {
    return (
      <Container>
        <Grid container style={{ marginTop: 10, marginBottom: 10 }}>
          <Typography variant='h6'>Existing user / no provider</Typography>
        </Grid>
      </Container>
    )
  }

  if (providerApplication && providerApplication.userExists === true && providerApplication.userIsProvider === true) {
    return (
      <UserIsProvider
        email={providerApplication.contact.email}
      />
    )
  }

  return (
    <Container>
      <Grid container style={{ marginTop: 10, marginBottom: 10 }}>
        <Typography variant='h6'>{t('forms.register.title')}</Typography>

        <Grid item xs={12} >
          <Grid item lg={7} md={9} xs={12}>
            <Card className={classes.card}>
              <form onSubmit={formik.handleSubmit} style={{ width: '100%', minWidth: '100%' }}>
                <CardContent>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="body2" component="p" style={{ textAlign: 'center' }}>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: t('forms.register.description', { 'interpolation': { 'escapeValue': false } })
                          }}
                        />
                      </Typography>
                    </Grid>

                    <Grid item xs={12} style={{ minWidth: '100%' }}>
                      <FormControl className={classes.textField}>
                        <TextField id="password" variant="outlined" margin="normal" name="password" label={t('password')} type="password" aria-describedby="password-helper-text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </FormControl>
                      <FormHelperText id="password-helper-text" error={formik.errors.password && formik.touched.password}>
                        {formik.errors.password && formik.touched.password ? formik.errors.password : ''}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12} style={{ minWidth: '100%' }}>
                      <FormControl className={classes.textField}>
                        <TextField id="repeatPassword" variant="outlined" margin="normal" name="repeatPassword" label={t('forms.register.repeatPassword')} type="password" aria-describedby="repeat-password-helper-text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </FormControl>
                      <FormHelperText id="repeat-password-helper-text" error={formik.errors.repeatPassword && formik.touched.repeatPassword}>
                        {formik.errors.repeatPassword && formik.touched.repeatPassword ? formik.errors.repeatPassword : ''}
                      </FormHelperText>
                    </Grid>

                  </Grid>

                </CardContent>
                <CardActions >
                  <Grid container justify="flex-end">
                    <Button
                      className={classes.button}
                      variant="contained"
                      type="submit"
                      disabled={formik.isSubmitting}
                    >
                      {t('forms.register.actions.submit')}
                    </Button>
                  </Grid>

                </CardActions>
              </form>

            </Card>
          </Grid>
          <Grid item xs={5}></Grid>
        </Grid>
      </Grid>

    </Container >
  )
}

export default RegisterScreen;