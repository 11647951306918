import React, { useContext, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import VariantFormUI from './VariantFormUI';
import { DataContext } from "../../Context/DataContext";
import { Typography, Container, Grid } from '@material-ui/core';
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { Formik, Form } from 'formik';
import { useTranslation } from "react-i18next";
import { formStyles } from '../../Styles/forms';
import { makeLanguageFields } from '../../Modules/Forms/FormHelper';
import FormHeader from '../Partials/FormHeader';
import FormFooter from '../Partials/FormFooter';
import BreadCrumbs from "../../Modules/Nav/BreadCrumbs";
import config from '../../Config/config';
import variantValidationSchema from './validation';
import GOSnackBar from '../../Modules/Forms/GOSnackBar'

import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
    ...formStyles(theme),
}));

const VariantForm = props => {
    const [language, setLanguage] = useState(config.defaultContentLanguage);
    const { t } = useTranslation();
    const theme = useTheme();
    const classes = useStyles(theme);
    const { Variant } = useContext(DataContext);
    const [statusMessage, setStatusMessage] = useState({
        open: false,
        message: "",
        variant: ""
    });
    const initialData = {
        id: 'new',
        type: 'fixedSlot',
        limitedVacancy: true,
        bookOnRequest: false,
        activity: '',
        name: '',
        description: '',
        maxParticipants: 0,
        cutOffPeriod: 0,
        priceCategories: [],
        translations: makeLanguageFields({ name: '', description: '', details: '', published: false }),
        published: false
    }

    const [variantData, setVariantData] = useState({ ...initialData });
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const validationSchema = variantValidationSchema(props.language, t);

    useEffect(() => {
        setIsLoading(false);
        if (props.id === 'new') {
            setIsLoading(false);
            setVariantData({ ...initialData });
        } else {
            const fetchData = async () => {
                try {
                    setIsLoading(true);
                    const data = await Variant.fetchItem(props.id);

                    const newVariant = _.merge(
                        {},
                        initialData,
                        {
                            ...data,
                            priceCategories: data.priceCategories.map((item) => {
                                return {
                                    ...item,
                                    price: item.price / 100
                                }
                            })
                        },
                        { bookOnRequest: data.directBooking === null || data.directBooking === false ? true : false }
                    );
                    // console.log(newVariant.priceCategories)
                    setVariantData(newVariant);
                    setError(null);
                    setIsLoading(false);
                } catch (err) {
                    setError(err.message);
                    setIsLoading(false);
                }
            }
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.id, props.language]);


    const handleSubmit = (values, formikActions) => {
        const saveData = async (isNew) => {
            try {
                const operation = isNew ? Variant.createItem : Variant.updateItem;
                const newData = await operation({
                    ...values,
                    priceCategories: values.priceCategories.map((item) => {
                        return {
                            ...item,
                            price: item.price * 100
                        }
                    }),
                    directBooking: !values.bookOnRequest
                });
                const newVariant = _.merge(
                    {},
                    initialData,
                    {
                        ...newData,
                        priceCategories: newData.priceCategories.map((item) => {
                            return {
                                ...item,
                                price: item.price / 100
                            }
                        })
                    },
                    { bookOnRequest: newData.directBooking === null || newData.directBooking === false ? true : false }
                );
                setVariantData(newVariant);
                formikActions.setStatus({ success: true });
                formikActions.resetForm(newVariant);

                setStatusMessage({
                    open: true,
                    message: t("forms.generic.messages.success"),
                    variant: "success"
                });
            } catch (err) {
                console.log('handleSubmit ERROR');
                console.log(err.message);

                formikActions.setStatus({ success: false })
                formikActions.setSubmitting(false)
                formikActions.setErrors({ submit: err.message })

                setStatusMessage({
                    open: true,
                    message: t("forms.generic.messages.saveError", { error: err.message }),
                    variant: "error"
                });
            }
        };
        saveData(values.id === "new");
    }

    return (
        <React.Fragment>
            <Container >
                {/* <BreadCrumbs
          links={[
            { title: t("generic.variant"), path: "/variants" },
            { title: t("forms.variant.actions.new"), path: "/variants/new" }
          ]}
        /> */}
                <Grid container
                    justify="flex-start"
                    alignItems="flex-start"
                    className={classes.formContainer}
                >


                    {isLoading ? <Typography>Loading</Typography> :
                        error ? <Typography>{error}</Typography> :

                            <Formik
                                initialValues={{
                                    ...variantData
                                }}
                                onSubmit={handleSubmit}
                                render={(formikProps) => {
                                    const onSave = () => {
                                        formikProps.validateForm()
                                            .then(() => {
                                                if (!formikProps.isValid) {
                                                    // alert('error');
                                                    console.log(JSON.stringify(formikProps.errors))
                                                    setStatusMessage({
                                                        open: true,
                                                        message: t("forms.generic.messages.validationError"),
                                                        variant: "error"
                                                    });
                                                } else {
                                                    // alert('success');
                                                    formikProps.handleSubmit();
                                                }
                                            }).catch((err) => {
                                                console.log('onSave ERROR');
                                                console.log(JSON.stringify(formikProps.errors))

                                                setStatusMessage({
                                                    open: true,
                                                    message: t("forms.generic.messages.saveError", { error: err.message }),
                                                    variant: "error"
                                                });
                                            })
                                    }
                                    return (
                                        <Form>
                                            <FormHeader
                                                {...formikProps}
                                                title={props.id === "new"
                                                    ? t("forms.variant.actions.new")
                                                    : t("forms.variant.actions.edit")}
                                                language={language}
                                                onLanguageChange={setLanguage}
                                                onSave={onSave}
                                            />
                                            <VariantFormUI
                                                {...formikProps}
                                                contentLanguage={language}
                                            />
                                            <Grid item xs={12}>
                                                <FormFooter
                                                    {...formikProps}
                                                    onSave={onSave}
                                                />
                                            </Grid>

                                        </Form>
                                    )
                                }}
                                validationSchema={validationSchema}
                                validateOnBlur={true}
                                validateOnChange={false}
                            />
                    }
                </Grid>
                <GOSnackBar
                    open={statusMessage.open}
                    messagetext={statusMessage.message}
                    closeHandle={() => { setStatusMessage({ ...statusMessage, open: false }) }}
                    variant={statusMessage.variant}
                />
            </Container>
        </React.Fragment>
    )
};

VariantForm.propTypes = {
    id: PropTypes.string,
}

VariantForm.defaultProps = {
    id: 'new',
}

export default VariantForm;
