import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from '@material-ui/core';
import { formStyles } from "../../Styles/forms";
import {
    Typography,
    Grid,
    Button,
    Divider,
    Container,
    Table,
    TableRow,
    TableCell,
    Link

} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import PropTypes from "prop-types";
import AlertDialog from '../../Modules/AlertDialog';
import RejectBooking from '../../Forms/RejectBooking';

const useStyles = makeStyles(theme => ({
    ...formStyles(theme),
}))


const BookingDetails = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [rejectOpen, setRejectOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertContent, setAlertContent] = useState({
        title: t('forms.booking.confirmMessageTitle'),
        message: t('forms.booking.confirmMessage'),
        callback: (value) => { alert(value); setAlertOpen(false) }
    })
    const classes = useStyles(theme);
    return (
        <Container style={{ display: 'flex', minHeight: 180, minWidth: 320, padding: 10 }}>
            <CloseIcon className={classes.closeicon} onClick={props.onClose} />
            <Grid container style={{ padding: 10 }}>
                <Grid item xs={12}>
                    <Typography variant="h6">
                        {t('forms.booking.request')}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Table>
                        <TableRow>
                            <TableCell>{t('forms.booking.timestamp')}</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>{props.timestamp}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{t('generic.activity')}</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} >
                                {props.activity}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{t('generic.variant')}</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>{props.variant}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{t('generic.slot')}</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>{props.slot}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{t('forms.booking.participants')}</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>{props.numParticipants}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{t('forms.booking.price')}</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>{props.price}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{t('forms.booking.booker')}</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>
                                {props.contact.name}<br />
                                <Link href={`mailto:${props.contact.email}`}>{props.contact.email}</Link><br />
                                {props.contact.phone}
                            </TableCell>
                        </TableRow>

                    </Table>
                </Grid>
                <Divider />
                <Grid item xs={6} align="center" style={{ paddingTop: 20, paddingBottom: 10 }}>
                    <Button
                        classes={{ root: classes.button, disabled: classes.disabledButton }}
                        onClick={() => {
                            setAlertContent({
                                title: t('forms.booking.confirmMessageTitle'),
                                message: t('forms.booking.confirmMessage'),
                                callback: (value) => {
                                    if (value === true) {
                                        props.onConfirm();
                                    }
                                    setAlertOpen(false);
                                }
                            });
                            setAlertOpen(true);

                        }}
                    >
                        {t('forms.booking.actions.confirm')}
                    </Button>
                </Grid>
                <Grid item xs={6} align="center" style={{ paddingTop: 20, paddingBottom: 10 }}>
                    <Button
                        classes={{ root: classes.warningButton, disabled: classes.disabledButton }}
                        color='primary'
                        onClick={() => {
                            setRejectOpen(true);
                            // setAlertContent({
                            //     title: t('forms.booking.cancelMessageTitle'),
                            //     message: t('forms.booking.cancelMessage'),
                            //     callback: (value) => {
                            //         if (value === true) {
                            //             props.onReject();
                            //         }
                            //         setAlertOpen(false);
                            //     }
                            // });
                            // setAlertOpen(true);
                        }
                        }
                    >
                        {t('forms.booking.actions.reject')}
                    </Button>
                    <AlertDialog
                        open={alertOpen}
                        title={alertContent.title}
                        message={alertContent.message}
                        callback={alertContent.callback}
                    />
                    <RejectBooking
                        open={rejectOpen}
                        onConfirm={(message) => {
                            setRejectOpen(false);
                            props.onReject(message);
                        }}
                        onCancel={() => { setRejectOpen(false); }}
                    />

                </Grid>
            </Grid>
        </Container>
    )
}

BookingDetails.propTypes = {
    activity: PropTypes.string,
    variant: PropTypes.string,
    slot: PropTypes.string,
    numParticipants: PropTypes.string,
    price: PropTypes.string,
    contact: PropTypes.string,
    onReject: PropTypes.func,
    onConfirm: PropTypes.func,
    onClose: PropTypes.func
};

BookingDetails.defaultProps = {
    activity: '<activity>',
    variant: '<variant>',
    slot: '<slot>',
    numParticipants: '<numParticipants>',
    price: '<price>',
    contact: {
        name: '<name>',
        email: '<email>',
        phone: '<phone>'
    },
    onReject: () => { alert('Cancelled'); },
    onConfirm: () => { alert('Confirmed'); },
    onClose: () => { alert('Close'); }
};

export default BookingDetails;