import { CRUDReducer, crudInitialState } from "./CRUDReducer"

export const entities = [
  'CATEGORY',
  'PROVIDER',
  'ACTIVITY',
  'VARIANT',
  'SLOT',
  'EVENT'
]

const entityNames = [
  'Category',
  'Provider',
  'Activity',
  'Variant',
  'Slot',
  'Event'
]

export const dataInitialState = {
  Activity: { ...crudInitialState },
  Category: { ...crudInitialState },
  Provider: { ...crudInitialState },
  Variant: { ...crudInitialState },
  Slot: { ...crudInitialState },
  Event: { ...crudInitialState }
}

export const dataReducer = (state, action) => {
  const substrings = action.type.split('_');
  const index = entities.findIndex((entity) => entity === substrings[0]);
  if (substrings && substrings.length > 0) {
    if (index >= 0) {
      const entity = entityNames[index];
      return {
        ...state, [entity]: CRUDReducer(substrings[0], state[entity], action)
      };
    }
  }
  switch (action.type) {
    case 'INITIALIZE':
      return {
        ...dataInitialState
      }
    default:
      return {
        ...state
      }
  }
}