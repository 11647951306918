import React from "react";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import * as Yup from "yup";

import GOFormikTextInput from "../../Modules/Forms/GOFormikTextInput";
import { Formik, Form, Field } from 'formik';



const RejectBooking = (props) => {
  const { t } = useTranslation();

  function handleClose(result, message) {
    if (result) {
      props.onConfirm(message)
    } else {
      props.onCancel();
    }
  }
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => handleClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Formik
          initialValues={{
            message: ''
          }}
          onSubmit={values => {
            handleClose(true, values.message)
          }}
          validationSchema={
            Yup.object().shape({
              message: Yup
                .string()
                .required(t('forms.booking.validation.required'))
                .max(1000, t('forms.booking.validation.max', { max: 1000 }))
            })
          }
          validateOnBlur={true}
          validateOnChange={false}
          render={
            (formikProps) => {
              return (
                <Form>
                  <DialogTitle id="alert-dialog-title">{t('forms.booking.cancelMessageTitle')}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      <Typography>{t('forms.booking.cancelMessage')}</Typography>
                    </DialogContentText>

                    <Grid container>
                      <Grid item xs={12}>
                        <Field
                          name="message"
                          as={GOFormikTextInput}
                          maxChars={1000}
                          multiline
                          rows="4"
                          rowsMax="10"
                          labelText={t("forms.booking.rejectReason")}
                        />
                      </Grid>
                    </Grid>

                  </DialogContent>
                  <DialogActions>
                    <Button contained onClick={() => formikProps.handleSubmit()} color="primary" autoFocus>
                      {props.agreeLabel || t('generic.yes')}
                    </Button>
                    <Button contained onClick={() => handleClose(false)} color="primary" >
                      {props.cancelLabel || t('generic.no')}
                    </Button>
                  </DialogActions>
                </Form>
              )
            }}
        />
      </Dialog>
    </div>
  )
}
export default RejectBooking;