import React, { useState, useEffect, useContext } from 'react';
import { Container, Typography, Grid, Button, Checkbox, FormControlLabel, LinearProgress } from '@material-ui/core';
import { formStyles } from '../../Styles/forms';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import MakeBookableConfirmation from './confirm';
import { ProviderContext } from '../../Context/ProviderContext';

import Config from '../../Config/config';

const { defaultConditions } = Config;

const useStyles = makeStyles((theme) => ({
  ...formStyles(theme),
  card: {
    marginTop: 10,
    marginBottom: 10
  },
  media: {
    height: 140,
  },
  gridItem: {
    paddingTop: 8,
    paddingBottom: 8
  }
}));

const topics = [
  {
    title: 'forms.makeBookable.topics.slotsAndVariants.title',
    description: 'forms.makeBookable.topics.slotsAndVariants.description'
  },
  {
    title: 'forms.makeBookable.topics.direct.title',
    description: 'forms.makeBookable.topics.direct.description'
  },
  {
    title: 'forms.makeBookable.topics.onRequest.title',
    description: 'forms.makeBookable.topics.onRequest.description'
  },
  {
    title: 'forms.makeBookable.topics.tickets.title',
    description: 'forms.makeBookable.topics.tickets.description'
  },
  {
    title: 'forms.makeBookable.topics.cancellation.title',
    description: 'forms.makeBookable.topics.cancellation.description'
  },
  {
    title: 'forms.makeBookable.topics.payment.title',
    description: 'forms.makeBookable.topics.payment.description'
  },
  // {
  //   title: 'forms.makeBookable.topics.conditions.title',
  //   description: 'forms.makeBookable.topics.conditions.description'
  // },
]

const MakeBookable = (props) => {
  const provider = useContext(ProviderContext);
  const classes = useStyles();
  const { t } = useTranslation();
  const [gtcChecked, setGtcChecked] = useState(false);
  const [stage, setStage] = useState(0);
  // const [conditions, setConditions] = useState(defaultConditions);

  // useEffect(() => {
  //   const getConditions = async () => {
  //     const conditions = await axios.get(`/activities/provider/${provider.id}/conditions`);
  //   }
  // }, [provider.id]);



  useEffect(() => {
    if (provider.bookable === true) {
      setStage(1);
    }
  }, [provider.bookable])

  if (provider.loading || !provider.id) {
    return (
      <LinearProgress />
    )
  }

  if (provider.bookable || stage === 1) {
    return <MakeBookableConfirmation />
  }

  const localizedTopics = topics.map((topic) => {
    return {
      title: t(topic.title),
      __html: t(topic.description, { 'interpolation': { 'escapeValue': false } })
    }
  });

  const conditions = {
    percentage: (provider.conditions?.percentage || defaultConditions.percentage).toFixed(1),
    fixed: ((provider.conditions?.fixed || defaultConditions.fixed) / 100).toFixed(2)
  }
  const conditionsTopic = {
    title: t('forms.makeBookable.topics.conditions.title'),
    __html: `${t('forms.makeBookable.topics.conditions.description', { 'interpolation': { 'escapeValue': false }, percentage: conditions.percentage })}
     ${provider.conditions?.fixed && provider.conditions?.fixed > 0 ?
        t('forms.makeBookable.topics.conditions.fixedFee', { 'interpolation': { 'escapeValue': false }, fixed: conditions.fixed, currency: 'EUR' })
        : ''
      }`
  }
  localizedTopics.push(conditionsTopic);

  const topicTable = localizedTopics.map((item, index) => {
    return (
      <React.Fragment key={`topic-${index}`}>
        <Grid item xs={3} className={classes.gridItem}>
          <Typography color="secondary"><b>{item.title}</b></Typography>
          {
            index === localizedTopics.length - 1 &&
              (provider.conditions?.percentage !== defaultConditions.percentage || provider.conditions?.fixed !== defaultConditions.fixed) ?
              <Tooltip
                aria-label="individual-conditions"
                arrows
                title={t('forms.makeBookable.toolTips.individualConditions', { percentage: defaultConditions.percentage })}
              >
                <Grid container alignItems="center" direction="row" wrap="nowrap">
                  <Grid item >
                    <Grid container alignItems="center">
                      <Typography gutterBottom={false} variant="caption" style={{ color: 'grey' }}>
                        <b>{t('forms.makeBookable.individualConditions')} &#x00A0;</b>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item >
                    <Grid container alignItems="center">
                      <HelpOutlineIcon
                        style={{ color: 'grey' }}
                        fontSize="small"
                        aria-label="individual-conditions"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Tooltip>
              : undefined
          }
        </Grid>
        <Grid item xs={9} className={classes.gridItem}>
          <Typography >
            <span
              dangerouslySetInnerHTML={{
                __html: item.__html
              }} />
          </Typography>
        </Grid>
      </React.Fragment>
    )
  })

  return (
    <Container >
      <Grid container style={{ marginTop: 10, marginBottom: 10, width: '100%', minWidth: 400, maxWidth: 1000 }}>
        <Typography variant="h6">{t('forms.makeBookable.title')}</Typography>
        <Grid container style={{ marginTop: 10, marginBottom: 10 }}>
          <Grid item xs={12} className={classes.gridItem}>
            <Typography>
              <span
                dangerouslySetInnerHTML={{
                  __html: t('forms.makeBookable.intro', { 'interpolation': { 'escapeValue': false } })
                }} />
            </Typography>
          </Grid>
          {topicTable}
          <Grid item xs={12} className={classes.gridItem}>
            <FormControlLabel
              label={
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('forms.makeBookable.gtcConfirmation', { 'interpolation': { 'escapeValue': false } })
                  }} />
              }
              control={
                <Checkbox
                  checked={gtcChecked}
                  onChange={(event) => { setGtcChecked(event.target.checked) }}
                />
              }
            />
          </Grid>
          <Grid container justify="center">
            <Button
              classes={{ root: classes.button, disabled: classes.disabledButton }}
              disabled={!gtcChecked}
              onClick={() => {
                provider.makeBookable(true);
              }}
            >
              {t('forms.makeBookable.actions.apply')}
            </Button>
          </Grid>

        </Grid>
      </Grid>
    </Container >
  )
}

export default MakeBookable;