import React, { useContext, useEffect, useState } from 'react';
import { LinearProgress, Container, Typography, Table, TableRow, TableCell, TableBody, TableHead, IconButton } from '@material-ui/core';
import { DataContext } from '../Context/DataContext';
import { makeStyles, useTheme } from '@material-ui/core';
import ListHeader from './Partials/ListHeader';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import EditIcon from '@material-ui/icons/Edit';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';


const useStyles = makeStyles(theme => ({
  iconButtonHover: {
    "&:hover, &.Mui-focusVisible": { color: theme.palette.secondary.main }
  },
  deleteButtonHover: {
    "&:hover, &.Mui-focusVisible": { color: theme.palette.error.main }
  },
  sortIconActive: {
    color: theme.palette.secondary.main
  },
  sortIcon: {
    color: 'lightgray'
  }
}));

const CategoryScreen = (props) => {
  const { Category } = useContext(DataContext);
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const [sort, setSort] = useState({ field: 'name', direction: 1 });
  const history = useHistory();

  useEffect(() => {
    if (Category.data.length === 0 || Category.shouldUpdate) {
      Category.fetchList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Category.shouldUpdate]);

  const categoryTable = Category.data.sort((item1, item2) => {
    const i1 = item1[sort.field] ? item1[sort.field].toLowerCase() : '';
    const i2 = item2[sort.field] ? item2[sort.field].toLowerCase() : '';
    if (i1 < i2) { return -sort.direction } else { return sort.direction }
  }).map(c =>
    <TableRow key={c.id}>
      <TableCell>
        <IconButton
          size="small"
          className={classes.deleteButtonHover}
          onClick={() => { alert(`Delete ${c.id}`) }}
        >
          <HighlightOffIcon />
        </IconButton>
        <IconButton
          size="small"
          className={classes.iconButtonHover}
          // style={{ color: 'red', '&:hover': { color: 'blue' } }}
          onClick={() => { history.push(`/categories/${c.id}`) }}
        >
          <EditIcon />
        </IconButton>

      </TableCell>
      <TableCell>{c.name}</TableCell>
      <TableCell>{c.description}</TableCell>
    </TableRow>)
  return (
    <Container>
      <ListHeader
        title={t('generic.category')}
        newButtonLabel={t('forms.category.new')}
        onNew={() => history.push('/categories/new')}
        onReload={() => { Category.setShouldUpdate(true) }}
      />
      <Typography>{Category.error}</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('tables.generic.actions')}</TableCell>
            <TableCell>
              {t('generic.name')}
              <IconButton
                className={sort.field === 'name' ? classes.sortIconActive : classes.sortIcon}
                onClick={() => {
                  const direction = sort.field === 'name' ? -sort.direction : 1;
                  setSort({ field: 'name', direction: direction })
                }}
              >
                {sort.field === 'name' && sort.direction === -1 ?
                  <KeyboardArrowUpIcon /> :
                  <KeyboardArrowDownIcon />
                }
              </IconButton>
            </TableCell>
            <TableCell>
              {t('generic.description')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Category.loading ?
            <TableRow>
              <TableCell colSpan={2}>
                <LinearProgress color="secondary" />
              </TableCell>
            </TableRow>
            : categoryTable
          }
        </TableBody>
      </Table>
    </Container>

  )
}

export default CategoryScreen;