import React, { useContext } from 'react';
import { UserContext } from '../../Context/UserContext';
import { Route, Redirect } from 'react-router-dom';
import { Typography, LinearProgress } from '@material-ui/core';
import { ProviderContext } from '../../Context/ProviderContext';

const PrivateRoute = ({ children, requiresAdmin, requiresBookable, requiresProvider, ...rest }) => {
  const { user, isInitialized, isLoggingIn, loginError } = useContext(UserContext);
  const provider = useContext(ProviderContext);

  const renderLoading = ({ location }) => <LinearProgress color="secondary" />;
  const renderUpgrade = ({ location }) => <Redirect to={{ pathname: '/upgrade', state: { from: location } }} />;
  const renderProviderRequired = () => <Typography variant="h1">Bitte wählen Sie einen Anbieter</Typography>;
  // const renderProviderRequired = ({ location }) => <Redirect to={{ pathname: '/providerrequired', state: { from: location } }} />;
  const renderAdminRequired = ({ location }) => <Typography>Administratorrechte erforderlich</Typography>;
  const renderError = (error) => <Typography>Fehler: {error}</Typography>;
  const renderLogin = ({ location }) => <Redirect to={{ pathname: "/login", state: { from: location } }} />;
  const renderPage = ({ location }) => children;

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!isInitialized || isLoggingIn) { return renderLoading({ location }) }
        if (!user) { return renderLogin({ location }); }
        if (provider.loading) { return renderLoading({ location }) }
        if (loginError || provider.error) { return renderError('') }
        if (!provider?.id && !user.isAdmin === true) { return renderError('Ihr Nutzerkonto ist keinem Anbieter zugeordnet - bitte kontaktieren Sie den actyvyst Support.') }
        if (requiresAdmin) {
          if (!user.isAdmin === true) { return renderAdminRequired({ location }); }
        }
        if (requiresProvider && !provider?.id) {
          return renderProviderRequired({ location })
        }
        if (requiresBookable) {
          if (!user.isAdmin && !provider.bookable === true) {
            return renderUpgrade({ location })
          }
        }
        return renderPage({ location })
      }}
    />
  )
}

export default PrivateRoute;