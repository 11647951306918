import React, { useEffect, useState } from 'react';
import { Container, Typography, Select, MenuItem, FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  containerBar: {
    display: 'flex',
    // border: 'solid',
    // borderWidth: 1,
    // borderColor: 'lightgrey',
    width: '100%',
    padding: 0,
    marginBottom: 5,
    marginTop: 0,
    // height: 50,
    // minHeight: 50,
    fontSize: '0.7em'
  },
  formControl: {
    paddingLeft: 3,
    paddingRight: 3,
    margin: 0,
    minWidth: 100,
  },
  menuHighlight: {
    color: theme.palette.secondary.main
    // color: 'blue'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const FilterBar = ({ filters, onChange }) => {
  const classes = useStyles();
  const [values, setValues] = useState([]);
  useEffect(() => {
    setValues(filters.map((filter) => {
      return ''
    }))
  }, [filters]);

  // useEffect(() => {
  //     console.log('FilterBar useEffect')
  //     onChange(values);
  // }, [values])

  const findDependants = (index) => {
    let dep = [];
    for (let i = 0; i < filters.length; i++) {
      if (!isNaN(filters[i].dependentOn) && filters[i].dependentOn === index) {
        dep.push(i);
      }
    }
    for (let i = 0; i < dep.length; i++) {
      Array.prototype.push.apply(dep, findDependants(dep[i]));
    }
    return dep;
  }

  const filterMap = filters.map((filter, index) => {
    return (
      <FormControl key={`filter_${index}`} className={classes.formControl} style={{ width: '14.28%' }}>

        <Select
          onChange={(event) => {
            console.log('FilterBar onChange');
            const dep = findDependants(index);
            const v = values.map((item, i) => {
              if (dep.findIndex((j) => j === i) >= 0) {
                return '';
              }
              return i === index ? event.target.value : item
            })
            setValues(v);
            onChange(v);
          }}
          value={values[index] || ''}
          className={classes.root}
          style={{ fontSize: '1.0em' }}
          displayEmpty
        >
          <MenuItem value="" className={classes.menuHighlight}>Alle {filter.name}</MenuItem>
          {filter.data
            .filter((item) => {
              if (!isNaN(filter.dependentOn)) {
                return _.get(item, filter.dependencyCol) === values[filter.dependentOn];
              } else { return true }
            })
            .sort((a, b) => { return a[filter.displayCol] > b[filter.displayCol] ? 1 : -1 })
            .map((item, innerIndex) => {
              return (
                <MenuItem
                  key={`select_${index}_menuItem_${innerIndex}`}
                  value={item[filter.valueCol]}
                >
                  {item[filter.displayCol]}
                </MenuItem>
              )
            })}

        </Select>
        {/* <FormHelperText>{filter.name}</FormHelperText> */}
      </FormControl >
    )
  })
  return (
    <Container className={classes.containerBar}>
      <Container style={{ width: '14.28%', margin: 0, display: 'flex', alignItems: 'center', paddingLeft: 10 }}>
        <Typography style={{ justifyContent: 'center', alignItems: 'center', fontSize: '1.0em' }}>Anzeige filtern:</Typography>
      </Container>
      {filterMap}
    </Container>
  )
}

FilterBar.propTypes = {
  onChange: PropTypes.func,
  filters: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    data: PropTypes.array,
    valueCol: PropTypes.string,
    displayCol: PropTypes.string,
    dependentOn: PropTypes.number,
    dependencyCol: PropTypes.string
  }))
}



export default FilterBar;