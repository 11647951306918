import { Deserializer } from 'jsonapi-serializer';
import axios from 'axios';
axios.defaults.baseURL = process.env.REACT_APP_API_HOST;
const deserializer = new Deserializer({ keyForAttribute: 'camelCase' });

const confirmBooking = async (id) => {
  try {
    await axios.post(`/activities/bookings/${id}/confirm`, {});
  } catch (err) {
    console.error(err);
    throw err;
  }
}

const rejectBooking = async (id, message) => {
  try {
    await axios.post(`/activities/bookings/${id}/reject`, { message });
  } catch (err) {
    console.error(err);
    throw err;
  }
}

const fetchList = async (params) => {
  try {
    const response = await axios.get(`/activities`, { params });
    const activities = await deserializer.deserialize(response.data);
    return activities;
  } catch (err) {
    console.error(err);
    throw new Error(err);
  }
}

const fetchItem = async (id) => {
  try {
    const response = await axios.get(`/activities/${id}`);
    const activities = await deserializer.deserialize(response.data);
    return activities[0];
  } catch (err) {
    console.error(err);
    throw new Error(err);
  }
}

const createItem = async (activity) => {
  try {
    let { id, ...attributes } = activity;
    let response = await axios.post('/activities', {
      data: [{ type: 'activities', attributes: attributes }]
    })

    let activities = await deserializer.deserialize(response.data);
    return activities[0];
  } catch (err) {
    console.error(err);
    throw new Error(err);
  }
};


const updateItem = async (activity) => {
  try {
    const { id, ...attributes } = [activity];

    let response = await axios.patch(`/activities/${activity.id}`, {
      data: [{ type: 'activities', id: id, attributes: attributes }]
    });
    let savedActivity = deserializer.deserialize(response.data);
    return savedActivity[0];

  } catch (err) {
    console.error(err);
    throw new Error(err);
  }
}

const deleteItem = async (activityId) => {
  try {
    await axios.delete(`/activities/${activityId}`);
  } catch (err) {
    console.error(err);
    throw new Error(err);
  }
}

const uploadImage = async (file) => {
  // Split the filename to get the name and type
  let fileParts = file.name.split('.');
  let fileName = fileParts[0];
  let fileType = fileParts[1];
  let headers = new Headers();
  if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
    throw new Error('File type not supported');
  }
  if (file.size >= 1000000) {
    throw new Error('File size too large')
  }
  headers.set('Content-Type', 'application/json');
  let response = await axios.post(`/activities/imageupload`, {
    fileName: fileName,
    fileType: fileType,
    fileSize: file.size
  });
  let returnData = await deserializer.deserialize(response.data);
  response = await axios.put(returnData.signedRequest, file, {
    headers: {
      ...axios.defaults.headers,
      'Content-Type': file.type
    },
  });
  return returnData.filename;
}

const ActivityAPI = {
  fetchList,
  fetchItem,
  createItem,
  updateItem,
  deleteItem,
  uploadImage,
  confirmBooking,
  rejectBooking
};
export {
  ActivityAPI
};